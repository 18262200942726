import { defineMessages } from 'react-intl';

export default defineMessages({
    excessTitle: {
        id: 'quoteandbind.ngh.views.quote.excessTitle',
        defaultMessage: 'Excess'
    },
    removeCoverage: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.removeCoverage',
        defaultMessage: 'Remove cover'
    },
    addCoverage: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.addCoverage',
        defaultMessage: 'Add cover'
    },
    excessContentDescription: {
        id: 'quoteandbind.ngh.views.quote.excessContentDescription',
        defaultMessage: 'Your excess is the amount we subtract from the money paid to you when we pay out on a claim. This is a combination of the minimum compulsory excess which we have set out and a voluntary excess which you choose  in return for a reduction in your premium.'
    },
    comprehensiveDescription: {
        id: 'quoteandbind.ngh.views.quote.comprehensiveDescription',
        defaultMessage: 'For a comprehensive list of our excesses, please refer to your policy documents on the next page before you purchase your policy.'
    },
    compulsaryExcessDescription: {
        id: 'quoteandbind.ngh.views.quote.compulsaryExcessDescription',
        defaultMessage: '*We also have higher compulsory excesses for certain claims. You will still need to pay any voluntary excess you\'ve selected above, in addition to the compulsory excesses listed below:'
    },
    excessContentDescriptionEscape: {
        id: 'quoteandbind.ngh.views.quote.excessContentDescriptionEscape',
        defaultMessage: 'Escape of water: £XXX'
    },
    excessContentDescriptionSubsidence: {
        id: 'quoteandbind.ngh.views.quote.excessContentDescriptionSubsidence',
        defaultMessage: 'Subsidence: £XXXX'
    },
    excessContentDescriptionFlood: {
        id: 'quoteandbind.ngh.views.quote.excessContentDescriptionFlood',
        defaultMessage: 'Flood: £XXXX'
    },
    excessTableTypeColumn: {
        id: 'quoteandbind.ngh.views.quote.excessTableTypeColumn',
        defaultMessage: 'Policy Excess'
    },
    excessTableCompulsoryExcessColumn: {
        id: 'quoteandbind.ngh.views.quote.excessTableCompulsoryExcessColumn',
        defaultMessage: 'Compulsory'
    },
    excessTableVoluntaryExcessColumn: {
        id: 'quoteandbind.ngh.views.quote.excessTableVoluntaryExcessColumn',
        defaultMessage: 'Voluntary'
    },
    excessTableTotalExcessColumn: {
        id: 'quoteandbind.ngh.views.quote.excessTableTotalExcessColumn',
        defaultMessage: 'Total excess*'
    },
    totalBuildingsExcess: {
        id: 'quoteandbind.ngh.views.quote.Total buildings excess',
        defaultMessage: 'Total buildings excess'
    },
    totalContentsExcess: {
        id: 'quoteandbind.ngh.views.quote.Total contents excess',
        defaultMessage: 'Total contents excess'
    },
    buildingsExcess: {
        id: 'quoteandbind.ngh.views.quote.Buildings excess',
        defaultMessage: 'Buildings excess'
    },
    contentsExcess: {
        id: 'quoteandbind.ngh.views.quote.Contents excess',
        defaultMessage: 'Contents excess'
    },
    buildings: {
        id: 'quoteandbind.ngh.views.quote.Buildings',
        defaultMessage: 'Buildings'
    },
    contents: {
        id: 'quoteandbind.ngh.views.quote.Contents',
        defaultMessage: 'Contents'
    },
    highValueItemsTitle: {
        id: 'quoteandbind.ngh.views.quote.highValueItemsTitle',
        defaultMessage: 'High valued items (in and outside the home)'
    },
    highValueItemsCategoryColumn: {
        id: 'quoteandbind.ngh.views.quote.highValueItemsCategoryColumn',
        defaultMessage: 'Category'
    },
    highValueItemsDescription: {
        id: 'quoteandbind.ngh.views.quote.highValueItemsDescription',
        defaultMessage: 'Description'
    },
    highValueItemsValueColumn: {
        id: 'quoteandbind.ngh.views.quote.highValueItemsValueColumn',
        defaultMessage: 'Value'
    },
    highValueItemsInsideOutisdeColumn: {
        id: 'quoteandbind.ngh.views.quote.highValueItemsInsideOutisdeColumn',
        defaultMessage: 'Total excess'
    },
    modifyHighValueItemsButton: {
        id: 'quoteandbind.ngh.views.quote.modifyHighValueItemsButton',
        defaultMessage: 'View and edit your valubales'
    },
    accidentalDamageCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.accidentalDamageCoverTitle',
        defaultMessage: 'Accidental Damage Cover'
    },
    contentsAccidentalCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.contentsAccidentalCoverTitle',
        defaultMessage: 'Contents accidental cover'
    },
    contentsAccidentalCoverText: {
        id: 'quoteandbind.ngh.views.quote.contentsAccidentalCoverText',
        defaultMessage: 'Cover for damage to your contents that occurs suddenly as the result of an accident, such as spilling wine on your sofa. '
    },
    buildingsAccidentalCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.buildingsAccidentalCoverTitle',
        defaultMessage: 'Buildings accidental cover'
    },
    buildingsAccidentalCoverText: {
        id: 'quoteandbind.ngh.views.quote.buildingsAccidentalCoverText',
        defaultMessage: 'Cover for damage to your buildings that occur suddenly as the result of an accident, such as hitting a pipe when hanging a picture frame. '
    },
    legalExpensesCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.legalExpensesCoverTitle',
        defaultMessage: 'Personal Legal Expenses'
    },
    personalLegalExpenseDescription: {
        id: 'quoteandbind.ngh.views.quote.personalLegalExpenseDescription',
        defaultMessage: 'Cover is administered by DAS Legal Expenses Insurance Company Limited.'
    },
    standardLegalExpensesCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.standardLegalExpensesCoverTitle',
        defaultMessage: 'Personal Legal Expenses'
    },
    standardLegalExpensesCoverText: {
        id: 'quoteandbind.ngh.views.quote.standardLegalExpensesCoverText',
        defaultMessage: 'Cover for your legal proceedings that relate to your home, employment, death or personal injury up to £100,000. '
    },
    homeEmergencyDescription: {
        id: 'quoteandbind.ngh.views.quote.homeEmergencyDescription',
        defaultMessage: 'Cover is administered by ARAG Plc'
    },
    enhancedLegalExpensesCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.enhancedLegalExpensesCoverTitle',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    enhancedLegalExpensesCoverText: {
        id: 'quoteandbind.ngh.views.quote.enhancedLegalExpensesCoverText',
        defaultMessage: 'Cover for an even wider range of personal and family related legal disputes up to £100,000. '
    },
    bicycleExtensionCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.bicycleExtensionCoverTitle',
        defaultMessage: 'Cycling Protection'
    },
    bicycleExtensionCoverText: {
        id: 'quoteandbind.ngh.views.quote.bicycleExtensionCoverText',
        defaultMessage: 'Extended bike cover that provides protection for cycling accessories, amateur competitions and more. '
    },
    homeEmergencyCoverTitle: {
        id: 'quoteandbind.ngh.views.quote.homeEmergencyCoverTitle',
        defaultMessage: 'Home Emergency Cover'
    },
    homeEmergencyCoverText: {
        id: 'quoteandbind.ngh.views.quote.homeEmergencyCoverText',
        defaultMessage: 'Cover for the cost of calling out a tradesperson due to sudden incident, such as your boiler breaking down or your pipes bursting. Our Home Emergency Cover includes protection for a wide range of home emergencies. '
    },
    homeEmergencyCoverTextClaimPeriod: {
        id: 'quoteandbind.ngh.views.quote.homeEmergencyCoverTextClaimPeriod',
        defaultMessage: 'We will not pay for any claims under Home Emergency which occur within the first 7 days of the start of the period of cover, as shown on your schedule.'
    },
    coverType: {
        id: 'quoteandbind.ngh.views.quote.Cover type',
        defaultMessage: 'Cover type'
    },
    theMaximumWellPay: {
        id: 'quoteandbind.ngh.views.quote.The maximum well pay',
        defaultMessage: 'The maximum we’ll pay'
    },
    theMaxWellPay: {
        id: 'quoteandbind.ngh.views.quote.The max well pay',
        defaultMessage: 'The max we’ll pay'
    },
    close: {
        id: 'quoteandbind.ngh.views.quote.close',
        defaultMessage: 'Close'
    },
    leakingAndFrozenWater: {
        id: 'quoteandbind.ngh.views.quote.leakingAndFrozenWater',
        defaultMessage: 'Leaking and Frozen Water'
    },
    subsidence: {
        id: 'quoteandbind.ngh.views.quote.subsidence',
        defaultMessage: 'Subsidence'
    },
    flood: {
        id: 'quoteandbind.ngh.views.quote.flood',
        defaultMessage: 'Flood'
    },
    claimType: {
        id: 'quoteandbind.ngh.views.quote.claimType',
        defaultMessage: 'Claim Type'
    },
    excesses: {
        id: 'quoteandbind.ngh.views.quote.excessesTitle',
        defaultMessage: 'Excesses'
    },
    seeAllCoverBenefits: {
        id: 'quoteandbind.ngh.views.quote.seeAllCoverBenefits',
        defaultMessage: 'See all cover benefits.'
    },
    learnMore: {
        id: 'quoteandbind.ngh.views.quote.learnMore',
        defaultMessage: 'Learn more.'
    },
    editPropertyDetails: {
        id: 'quoteandbind.ngh.views.quote.editPropertyDetails',
        defaultMessage: 'Edit property details'
    },
    perMonth: {
        id: 'quoteandbind.ngh.views.quote.perMonth',
        defaultMessage: 'Per Month'
    },
    perYear: {
        id: 'quoteandbind.ngh.views.quote.perYear',
        defaultMessage: 'Per Year'
    },
    includedAsStandard: {
        id: 'quoteandbind.ngh.views.quote.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    personaliseYourCOverWarningBox: {
        id: 'quoteandbind.ngh.views.quote.personaliseYourCOverWarningBox',
        defaultMessage: 'We regularly check our prices and they are subject to change. To get a full quotation from us, please click continue and your information will be saved. You will be able to retrieve this quote at a later date.'
    },
    monthly: {
        id: 'quoteandbind.ngh.views.quote.monthly',
        defaultMessage: 'Monthly'
    },
    yearly: {
        id: 'quoteandbind.ngh.views.quote.yearly',
        defaultMessage: 'Yearly'
    },
    updateQuote: {
        id: 'quoteandbind.ngh.views.quote.updateQuote',
        defaultMessage: 'Update quote'
    },
    insurancePremiumTax: {
        id: 'quoteandbind.ngh.views.quote.insurancePremiumTax',
        defaultMessage: '*Includes Insurance Premium Tax (IPT) where appropriate'
    },
    propertyToBeInsured: {
        id: 'quoteandbind.ngh.views.quote.propertyToBeInsured',
        defaultMessage: 'Property to be insured'
    },
    productHomeownersInfo: {
        id: 'quoteandbind.ngh.views.quote.productHomeownersInfo',
        defaultMessage: 'This product is designed to meet the demands of homeowners in the UK and is provided on a non-advised basis. This means there has been no personal recommendation made on which cover and options you should take. Please take time to read your documents which detail what’s covered, what isn’t covered, the extent of cover and any key limits under each section. These can be found on the Policy Summary page under Your Documents.'
    }
});
