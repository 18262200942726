import { useCallback } from 'react';
import {
    useHistory,
} from 'react-router-dom';
import ErrorHandler from '../services/ErrorHandler';
import { UW_ERROR_CODE, FUNCTIONAL_ERROR_CODE } from '../constants/ErrorCodesConstants';

function useErrorHandler() {
    const history = useHistory();

    const handleFunctionalError = useCallback((error, referencedJobId) => {
        const jobId = error?.referencedJobId || referencedJobId;
        const path = jobId
            ? `/contact-us?jobid=${jobId}`
            : '/contact-us';
        history.push(path);
    }, [history]);

    const handleTechnicalError = useCallback((error, referencedJobId) => {
        const jobId = error?.referencedJobId || referencedJobId;
        const path = jobId
            ? `/service-unavailable?jobid=${jobId}`
            : '/service-unavailable';
        history.push(path);
    }, [history]);

    const logError = useCallback((error, jobId) => {
        console.debug(`Time: ${new Date()}`);
        console.debug(`JobID: ${jobId}`);
        console.debug(error?.baseError);
        console.debug(error);
    }, []);

    const getErrorCode = useCallback((error) => {
        logError(error);
        return error?.appErrorCode;
    }, [logError]);

    const handleError = useCallback((error, referencedJobId) => {
        logError(error);
        if (error?.appErrorCode && (error?.appErrorCode === UW_ERROR_CODE
            || error?.appErrorCode === FUNCTIONAL_ERROR_CODE)) {
            handleFunctionalError(error, referencedJobId);
        } else {
            handleTechnicalError(error, referencedJobId);
        }
    }, [handleFunctionalError, handleTechnicalError, logError]);

    const handleNotBlockingError = useCallback((error) => {
        logError(error);
    }, [logError]);

    const getDisplayableErrorMessage = useCallback((error) => {
        return ErrorHandler.getDisplayableErrorMessage(error);
    }, []);

    return {
        handleError,
        handleUWError: handleFunctionalError,
        handleNotBlockingError,
        getDisplayableErrorMessage,
        getErrorCode
    };
}

export default useErrorHandler;
