import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './HardStopMessage.metadata.json5';
import styles from './HardStopMessage.module.scss';

function HardStopMessage() {
    const overrideProps = {

    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default HardStopMessage;
