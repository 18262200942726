import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import {
    useHistory
} from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import metadata from './RetrieveQuote.metadata.json5';
import styles from './RetrieveQuote.module.scss';
import messages from './RetrieveQuote.messages';

function RetrieveQuote(props) {
    const { onRetrieveQuote, quoteID } = props;
    const history = useHistory();
    const [retrieveQuoteVM, updateRetrieveQuoteVM] = useState(undefined);
    const { onValidate, isComponentValid } = useValidation('RetrieveQuote');
    const viewModelService = useContext(ViewModelServiceContext);
    const [isError, setIsError] = useState(false);
    const MAX_SESSION_RETRIEVE_ATTEMPTS = 10;
    const RETRIEVE_ATTEMPTS_COUNTER = 'retrieveAttemptsCounter';
    /** Added below line to remove session timeout message on click
     * of start new quote
     */
    window.localStorage.setItem('sessionTimeOut', false);
    const { returnToDashboard } = useWizardActions();
    const translator = useContext(TranslatorContext);
    const {
        pushRelativeLinkClickInfo
    } = useCommonTagManager();

    const getAttemptsCount = useCallback(() => {
        return sessionStorage.getItem(RETRIEVE_ATTEMPTS_COUNTER) || '0';
    }, []);

    const addAttemptToStorage = useCallback((attemptsCount) => {
        const newAttemptsCount = _.parseInt(attemptsCount) + 1;
        sessionStorage.setItem(RETRIEVE_ATTEMPTS_COUNTER, newAttemptsCount);
        return newAttemptsCount;
    }, []);

    const redirectToContactUsIfTooManyAttempts = useCallback((attemptsCount) => {
        if (attemptsCount >= MAX_SESSION_RETRIEVE_ATTEMPTS) {
            history.push('/contact-us');
        }
    }, [history]);

    useEffect(() => {
        try {
            const buildNumber = process.env.REACT_APP_GW_VAR_BUILD_NUMBER;
            if (typeof buildNumber !== 'undefined' && buildNumber !== null) {
                console.log('Build Number : ', buildNumber);
            }
        } catch (error) {
            console.error('Error fetching build number:', error);
        }
    }, []);

    useEffect(() => {
        redirectToContactUsIfTooManyAttempts(getAttemptsCount());
        if (viewModelService) {
            const retrieveQuoteViewModel = viewModelService.create(
                { quoteID },
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteRetrievalDTO'
            );
            updateRetrieveQuoteVM(retrieveQuoteViewModel);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const writeValue = useCallback((value, path) => {
        const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
        _.set(newRetrieveQuoteVM, path, value);
        updateRetrieveQuoteVM(newRetrieveQuoteVM);
    }, [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]);

    const retrieveQuote = useCallback(() => {
        onRetrieveQuote(retrieveQuoteVM).then(() => {
            setIsError(false);
        }).catch(() => {
            const newAttemptsCount = addAttemptToStorage(getAttemptsCount());
            redirectToContactUsIfTooManyAttempts(newAttemptsCount);
            setIsError(true);
        });
    }, [
        onRetrieveQuote,
        setIsError,
        retrieveQuoteVM,
        getAttemptsCount,
        addAttemptToStorage,
        redirectToContactUsIfTooManyAttempts
    ]);

    const startNewQuote = useCallback(() => {
        const url = '/quote-ngh';
        pushRelativeLinkClickInfo(translator(messages.startNewQuote), url);
        history.push(url);
    }, [
        history,
        translator,
        pushRelativeLinkClickInfo
    ]);

    const setAsNotErrored = useCallback(() => {
        setIsError(false);
    }, [setIsError]);

    const changeToUpperCase = useCallback((postalCode, path) => {
        const upperCasePostalCode = _.toUpper(postalCode);
        writeValue(upperCasePostalCode, path);
        setIsError(false);
    }, [writeValue, setIsError]);

    const overrideProps = {
        retrieveQuoteButton: {
            disabled: !isComponentValid || isError
        },
        retrieveQuoteSubmissionIDInput: {
            error: isError,
            className: isError && 'erroredInput',
            onBlur: setAsNotErrored
        },
        retrieveQuotePostcodeInput: {
            onBlur: changeToUpperCase,
            error: isError,
            className: isError && 'erroredInput'
        },
        retrieveQuoteDateOfBirthInput: {
            error: isError,
            className: isError && 'erroredInput',
            onBlur: setAsNotErrored,
            maxDate: new Date()
        },
        errorMessagesContainer: {
            visible: isError
        },
        retrieveQuoteStartNewButton: {
            visible: false, // #723677: only OfflineQuoteOnlineBind possible for R1.2
            onClick: startNewQuote
        },
        retrieveQuoteHomepageButton: {
            onClick: () => returnToDashboard(translator(messages.returnToDashboard))
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRetrieveQuote: retrieveQuote
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={retrieveQuoteVM}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default RetrieveQuote;
