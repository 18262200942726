import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.no',
        defaultMessage: 'No'
    },
    notSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.notSelected',
        defaultMessage: 'Not Selected'
    },
    paRelationship: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paRelationship',
        defaultMessage: 'Relationship'
    },
    paDOB: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paDOB',
        defaultMessage: 'Date of birth'
    },
    paLossOfLimbs: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paLossOfLimbs',
        defaultMessage: 'Loss of limbs, sight, speech or hearing'
    },
    paPermanentTotalDiability: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paPermanentTotalDiability',
        defaultMessage: 'Permanent total disability'
    },
    paTemporaryTotalDisability: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paTemporaryTotalDisability',
        defaultMessage: 'Temporary total disability'
    },
    paTemporaryPartialDisability: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paTemporaryPartialDisability',
        defaultMessage: 'Temporary partial disability'
    },
    paTemporaryTotalDisabilityIllness: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paTemporaryTotalDisabilityIllness',
        defaultMessage: 'Temporary total disability due to an illness'
    },
    paDeathCover: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paDeathCover',
        defaultMessage: 'Death cover'
    },
    paDeathBenefit: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paDeathBenefit',
        defaultMessage: 'Death benefit assigned to (%)'
    },
    paBenefitRestricted: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paBenefitRestricted',
        defaultMessage: 'Benefits restricted to occupation'
    },
    paWeeklyBenefitPeriod: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paWeeklyBenefitPeriod',
        defaultMessage: 'Weekly benefit period reduced'
    },
    paBenefitPeriodDeferred: {
        id: 'quoteandbind.ngh.views.policy-summary.pa.paBenefitPeriodDeferred',
        defaultMessage: 'Benefit period deffered'
    },
});
