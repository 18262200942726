import { getProxiedUrl } from 'gw-portals-url-js';

const fetchContent = async (url, headers) => {
    try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
            throw new Error(`${response.status} ${response.url}`);
        }
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        throw error;
    }
};

const useCms = () => {
    const createCmsSiteMapPromise = () => {
        const siteMapUrl = getProxiedUrl('api/menu/sitemap');
        const siteMapUrlUrl = `${siteMapUrl}?siteName=Bridge`;
        return fetchContent(siteMapUrlUrl);
    };

    const createCmsContentPromise = function getCmsContent(contentId) {
        if (!contentId) throw new Error('Content id from siteMap is null');
        const url = getProxiedUrl('api/episerver/v3.0/content');
        const cmsUrl = `${url}/${contentId}?expand=%2A`;
        return fetchContent(cmsUrl, { 'Accept-Language': 'en' });
    };

    return {
        createCmsSiteMapPromise,
        createCmsContentPromise
    };
};

export default useCms;
