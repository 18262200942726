import { defineMessages } from 'react-intl';

export default defineMessages({
    emailQuote: {
        id: 'quoteandbind.directives.templates.QnbWizardButtons.Email Quote',
        defaultMessage: 'Email Quote',
    },
    printQuote: {
        id: 'quoteandbind.directives.templates.QnbWizardButtons.Print Quote',
        defaultMessage: 'Print Quote',
    },
    saveForLater: {
        id: 'quoteandbind.directives.QnbWizardButtons.Save for Later?',
        defaultMessage: 'Save for Later?',
    },
    emailSent: {
        id: 'quoteandbind.directives.QnbWizardButtons.Email Sent',
        defaultMessage: 'Email Sent',
    },
    emailQuoteDescription: {
        id: 'quoteandbind.directives.QnbWizardButtons.We will save your quote and send its number',
        defaultMessage: 'We will save your quote and send its number {quoteID} together with a link to continue from where you left off to {emailAddress}.',
    },
    error: {
        id: 'quoteandbind.directives.QnbWizardButtons.Error',
        defaultMessage: 'Error',
    },
    cantSendQuoteDetailsAtThisTime: {
        id: 'quoteandbind.directives.QnbWizardButtons.Sorry, we were not able to email the quote details at this time.',
        defaultMessage: 'Sorry, we were not able to email the quote details at this time.',
    },
});
