import React, {
    useState, useCallback, useEffect
} from 'react';
import {
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import metadata from './AddOtherOccupation.metadata.json5';
import './AddOtherOccupation.messages';

function AddOtherOccupation(props) {
    const {
        occupation,
        onRemoveOccupation,
        onAddAnotherOccuption,
        onOccupationSelected,
        occupationCodes,
        addAnotherVisible
    } = props;

    const [occupationsModel, setOccupationsModel] = useState({
        chosenOccupation: occupation
    });

    useEffect(() => {
        setOccupationsModel({
            chosenOccupation: occupation
        });
    }, [occupation]);

    const setNewOccupation = useCallback(
        (value, path) => {
            setOccupationsModel({
                [path]: value
            });
            onOccupationSelected(value);
        },
        [onOccupationSelected]
    );

    const overrideProps = {
        occupation: {
            allValues: occupationCodes,
            onValueChange: setNewOccupation
        },
        removeOccupation: {
            onClick: () => onRemoveOccupation(occupationsModel.chosenOccupation)
        },
        addOccupation: {
            onClick: onAddAnotherOccuption,
            visible: addAnotherVisible && !!occupationsModel.chosenOccupation
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={occupationsModel}
            overrideProps={overrideProps}
        />
    );
}

export default AddOtherOccupation;
