import { defineMessages } from 'react-intl';

export default defineMessages({
    addressLine1: {
        id: 'address.addressSearch.editAddressForm.addressLine1',
        defaultMessage: 'Address line 1'
    },
    enterYourHouseNumberNameStreet: {
        id: 'address.addressSearch.editAddressForm.enterYourHouseNumberNameStreet',
        defaultMessage: 'Enter your house number/name & street name'
    },
    addressLine2: {
        id: 'address.addressSearch.editAddressForm.addressLine2',
        defaultMessage: 'Address line 2'
    },
    optional: {
        id: 'address.addressSearch.editAddressForm.optional',
        defaultMessage: 'Optional'
    },
    addressLine3: {
        id: 'address.addressSearch.editAddressForm.addressLine3',
        defaultMessage: 'Address line 3'
    },
    townCity: {
        id: 'address.addressSearch.editAddressForm.townCity',
        defaultMessage: 'Town/city'
    },
    enterYourTownCity: {
        id: 'address.addressSearch.editAddressForm.enterYourTownCity',
        defaultMessage: 'Enter your town/city'
    },
    county: {
        id: 'address.addressSearch.editAddressForm.county',
        defaultMessage: 'County'
    },
    postcode: {
        id: 'address.addressSearch.editAddressForm.postcode',
        defaultMessage: 'Postcode'
    },
    enterYourPostcode: {
        id: 'address.addressSearch.editAddressForm.enterYourPostcode',
        defaultMessage: 'Enter your postcode'
    },
    differentAddressInfo: {
        id: 'address.addressSearch.editAddressForm.differentAddressInfo',
        defaultMessage: 'If you want us to use a different address than the one your want to insure as a correspondence address please '
    },
    contactUs: {
        id: 'address.addressSearch.editAddressForm.contactUs',
        defaultMessage: 'Contact Us'
    },
    submit: {
        id: 'address.addressSearch.editAddressForm.submit',
        defaultMessage: 'Submit'
    },
    searchAgain: {
        id: 'address.addressSearch.editAddressForm.searchAgain',
        defaultMessage: 'Search again'
    },
    errorText: {
        id: 'address.addressSearch.editAddressForm.errorText',
        defaultMessage: 'We could not find this address, please contact us on XXXXX'
    }
});
