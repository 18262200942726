import React, { useCallback, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import metadata from './TermsAndConditions.metadata.json5';
import styles from './TermsAndConditions.module.scss';
import messages from './TermsAndConditions.messages';

function TermsAndConditions(props) {
    const { onWriteValue, submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const onClickOfTermsAndCondition = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/legal-information/our-terms-and-conditions/';
        pushLinkClickInfo(translator(messages.ourTermsAndConditions), url);
        window.open(url, '_blank').focus();
    }, [pushLinkClickInfo, translator]);

    const onClickOfMutualityAndcharitable = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/legal-information/mutual-and-charitable-assignment/';
        pushLinkClickInfo(translator(messages.mutualityAndCharitable), url);
        window.open(url, '_blank').focus();
    }, [pushLinkClickInfo, translator]);

    const writeValue = (value, path) => {
        onWriteValue(value, path);
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onClickOfTermsAndCondition: onClickOfTermsAndCondition,
            onClickOfMutualityAndcharitable: onClickOfMutualityAndcharitable,
        },
    };

    return (
        <ViewModelForm
            onValueChange={writeValue}
            model={submissionVM.value}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default TermsAndConditions;
