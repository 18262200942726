import { defineMessages } from 'react-intl';

export default defineMessages({
    update: {
        id: 'quoteandbind.ngh.views.paymentUnsuccessful.updateTransaction',
        defaultMessage: 'Update payment information'
    },
    cancel: {
        id: 'quoteandbind.ngh.views.paymentUnsuccessful.cancelTransaction',
        defaultMessage: 'Cancel transaction'
    },
    title: {
        id: 'quoteandbind.ngh.views.paymentUnsuccessful.title',
        defaultMessage: 'We couldn\'t process your payment'
    },
    description1: {
        id: 'quoteandbind.ngh.views.paymentUnsuccessful.description1',
        defaultMessage: 'Unfortunately, we coundn\'t collect payment for your purchase. Please take a moment to review your billing information and try again.'
    },
});
