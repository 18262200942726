import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './BuildingsSummary.metadata.json5';
import styles from './BuildingsSummary.module.scss';
import messages from './BuildingsSummary.messages';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import useLobs from '../../../hooks/useLobs';
import { useCurrency } from '../../../../../modules-react/nfum-portals-utils-react';

function BuildingsSummary(props) { /* NOSONAR: pure declarative usage */
    const {
        homeLine,
        onClickSeeDocuments,
        submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const { isBespokeJourney } = useLobs(submissionVM);
    const { formatCurrency } = useCurrency();

    const { getPropertyTitle } = usePropertyTitle();
    const buildingsIds = Object.freeze({
        HOM_BUILDINGS_COV: 'HOMBuildingsCov',
        HOM_BUILDINGS_COV_ACCIDENTAL_DAMAGE: 'HOMBuildingsCovAccidentalDamageRequired',
        HOM_BUILDINGS_COV_VOLUNTARY_EXCESS: 'HOMBuildingsCovVoluntaryExcess',
        HOM_BUILDINGS_COV_ALL_OTHER_CLAIMS_EXCESS: 'HOMBuildingsCovAllOtherClaimsExcess',
        HOM_BUILDINGS_COV_BUILDING_LEVEL_LIMIT: 'HOMBuildingsCovBuildingLevelLimit',
        HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_TOTAL_EXCESS: 'HOMBuildingsCovEscapeOfWaterOrOilTotalExcess',
        HOM_BUILDINGS_COV_SUBSIDENCE_EXCESS: 'HOMBuildingsCovSubsidenceExcess',
        HOM_BUILDINGS_COV_FLOOD_TOTAL_EXCESS: 'HOMBuildingsCovFloodTotalExcess',
        HOM_HOME_EMERGENCY_COV: 'HOMHomeEmergencyCov',
        HOM_HOME_EMERGENCY_COV_LIMIT: 'HOMHomeEmergencyCovLimit',
        HOM_BUILDINGS_COV_FLOOD_EXCESS_ADJUSTMENT: 'HOMBuildingsCovFloodExcessAdjustment',
        HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_EXCESS_ADJUST: 'HOMBuildingsCovEscapeOfWaterOrOilExcessAdjust',
        HOM_BUILDINGS_COV_SUBSIDENCE_HEAVE_EXCESS_ADJUST: 'HOMBuildingsCovSubsidenceHeaveExcessAdjust',
        HOM_BUILDINGS_COV_STORM_EXCESS_ADJUSTMENT: 'HOMBuildingsCovStormExcessAdjustment',
        HOM_BUILDINGS_COV_FIRE_EXCESS_ADJUSTMENT: 'HOMBuildingsCovFireExcessAdjustment',
        HOM_BUILDINGS_COV_THEFT_EXCESS_ADJUSTMENT: 'HOMBuildingsCovTheftExcessAdjustment',
        HOM_BUILDINGS_COV_LEAKING_OIL_EXCESS_ADJUSTMENT: 'HOMBuildingsCovLeakingOilExcessAdjustment',
        HOM_BUILDINGS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ: 'HOMBuildingsCovFallingTreesAndBranchesExcAdj',
        HOM_BUILDINGS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ: 'HOMBuildingsCovFallingAerialsTurbinesExcAdj',
        HOM_BUILDINGS_COV_COLLISION_VEH_ANIMAL_EXCESS_ADJUST: 'HOMBuildingsCovCollisionVehAnimalExcessAdjust',
        HOM_BUILDINGS_COV_MALICIOUS_PEOPLE_EXCESS_ADJUST: 'HOMBuildingsCovMaliciousPeopleExcessAdjust',
        HOM_BUILDINGS_COV_RIOT_EXCESS_ADJUSTMENT: 'HOMBuildingsCovRiotExcessAdjustment',
        HOM_BUILDINGS_COV_AIRCRAFT_EXCESS_ADJUSTMENT: 'HOMBuildingsCovAircraftExcessAdjustment',
        HOM_BUILDINGS_COV_BUILDING_SUM_INSURED: 'HOMBuildingsCovBuildingsSumInsured',
        HOMBuildingsCovFirstLossSumRequired: 'HOMBuildingsCovFirstLossSumRequired',
        HOMBuildingsCovAccidentalLossOrDamageExcess: 'HOMBuildingsCovAccidentalLossOrDamageExcess',
        HOMBuildingsCovOtherVolXs: 'HOMBuildingsCovOtherVolXs',
        HOMBuildingsCovBasisOfSettlement: 'HOMBuildingsCovBasisOfSettlement',
        HOMBuildingsCovSubsidenceHeaveExcessTotal: 'HOMBuildingsCovSubsidenceHeaveExcessTotal',
        HOM_BUILDINGS_COV_RESTRICT_COVER_TO_FLEE: 'HOMBuildingsCovRestrictCoverToFLEE'
    });
    const YES_KEY = 'Yes';
    const UNOCCUPIED_KEY = 'Unoccupied';
    const isBespokeSustainedDmg = submissionVM.lobData.homeLine.value.isBespokeSustainedDmg;

    const isBespokeFloodRisk = submissionVM.lobData.homeLine.value.isBespokeFloodRisk;

    const HomeCoverages = submissionVM.lobData?.homeLine?.lineCoverages.coverages.value;

    const findHomeCov = (covPublicId) => {
        return HomeCoverages.find((cov) => cov.publicID === covPublicId);
    };

    const HomebuildingCov = findHomeCov(buildingsIds.HOM_BUILDINGS_COV);

    const getTermValue = useCallback((cov, termId) => {
        return cov?.terms
            ?.find((term) => term.publicID === termId)
            ?.chosenTermValue;
    }, []);

    const getTermLimitValue = useCallback((cov, termPublicID, currSymbol = true) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        if (chosenTermValue) {
            return currSymbol ? `£${chosenTermValue}` : chosenTermValue;
        }
    }, []);
    const isFirstLossSumInsured = HomebuildingCov.terms.find(cov => cov.publicID === buildingsIds.HOMBuildingsCovBasisOfSettlement).chosenTerm === 'FirstLoss'

    const buildingsCov = homeLine?.lineCoverages?.coverages
        ?.find(((cov) => cov.publicID === buildingsIds.HOM_BUILDINGS_COV));

    const isAnyAdjustomentsCovTermValue = useCallback(() => {
        const arr = [
            buildingsIds.HOM_BUILDINGS_COV_FLOOD_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_SUBSIDENCE_HEAVE_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_STORM_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_FIRE_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_THEFT_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_LEAKING_OIL_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ,
            buildingsIds.HOM_BUILDINGS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ,
            buildingsIds.HOM_BUILDINGS_COV_COLLISION_VEH_ANIMAL_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_MALICIOUS_PEOPLE_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_RIOT_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_AIRCRAFT_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_BUILDING_SUM_INSURED,
            buildingsIds.HOMBuildingsCovOtherVolXs,
            buildingsIds.HOMBuildingsCovBasisOfSettlement,
            buildingsIds.HOMBuildingsCovSubsidenceHeaveExcessTotal
        ];

        return arr.reduce((acc, currentCovTerm) => {
            const result = getTermLimitValue(
                buildingsCov,
                currentCovTerm,
                false
            );
            return result && +result.replace(/,/g, '') > 0 ? true : acc;
        }, false);
    }, [buildingsCov,
        buildingsIds.HOMBuildingsCovBasisOfSettlement,
        buildingsIds.HOMBuildingsCovOtherVolXs,
        buildingsIds.HOMBuildingsCovSubsidenceHeaveExcessTotal,
        buildingsIds.HOM_BUILDINGS_COV_AIRCRAFT_EXCESS_ADJUSTMENT,
        buildingsIds.HOM_BUILDINGS_COV_BUILDING_SUM_INSURED,
        buildingsIds.HOM_BUILDINGS_COV_COLLISION_VEH_ANIMAL_EXCESS_ADJUST,
        buildingsIds.HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_EXCESS_ADJUST,
        buildingsIds.HOM_BUILDINGS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ,
        buildingsIds.HOM_BUILDINGS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ,
        buildingsIds.HOM_BUILDINGS_COV_FIRE_EXCESS_ADJUSTMENT,
        buildingsIds.HOM_BUILDINGS_COV_FLOOD_EXCESS_ADJUSTMENT,
        buildingsIds.HOM_BUILDINGS_COV_LEAKING_OIL_EXCESS_ADJUSTMENT,
        buildingsIds.HOM_BUILDINGS_COV_MALICIOUS_PEOPLE_EXCESS_ADJUST,
        buildingsIds.HOM_BUILDINGS_COV_RIOT_EXCESS_ADJUSTMENT,
        buildingsIds.HOM_BUILDINGS_COV_STORM_EXCESS_ADJUSTMENT,
        buildingsIds.HOM_BUILDINGS_COV_SUBSIDENCE_HEAVE_EXCESS_ADJUST,
        buildingsIds.HOM_BUILDINGS_COV_THEFT_EXCESS_ADJUSTMENT, getTermLimitValue]);

    const homeProperty = homeLine?.coverables?.homhomeProperty;
    const isBuildingsCov = !!buildingsCov;
    const getPropertyOccupancy = homeProperty?.homhomePropertyDetails
        ?.whoOccupiesTheProperty === UNOCCUPIED_KEY;

    const displayConfirmationText = useCallback((confirmationTextNo, isConfirmed) => {
        if (isBespokeJourney) {
            if (isConfirmed) {
                return translator(messages[`${confirmationTextNo}Yes`]);
            }
            return translator(messages[`${confirmationTextNo}No`]);
        }
        return translator(messages[`${confirmationTextNo}No`]);
    }, [isBespokeJourney, translator]);

    const displayAccidentalMsgForNGH = () => {
        return getTermValue(buildingsCov,
            buildingsIds.HOM_BUILDINGS_COV_ACCIDENTAL_DAMAGE) === YES_KEY
            ? translator(messages.added)
            : translator(messages.notSelected);
    };

    const displayVoluntary = () => {
        const termValue = getTermValue(
            buildingsCov,
            buildingsIds.HOM_BUILDINGS_COV_VOLUNTARY_EXCESS
        );
        if (termValue === 'Other') {
            return getTermLimitValue(
                buildingsCov,
                buildingsIds.HOMBuildingsCovOtherVolXs
            );
        } else {
            return getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_VOLUNTARY_EXCESS
            )
       }
    };

    const displayNumberOfBedrooms = () => {
        if (homeProperty.homhomePropertyDetails.numberOfBedrooms === 'MoreThan8') {
            return translator(messages.moreThanEight);
        } else {
            return homeProperty.homhomePropertyDetails.numberOfBedrooms;
        }
    };

    // added value as per the defect fix #670911
    // eslint-disable-next-line max-len
    const tenantsFixturesSI = homeProperty?.homhomePropertyConstruction?.rebuildingCostByHighestPriority || undefined;
    const isTenantFixure = homeProperty?.isTenantFixure;
    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        buildingsHomePropertyTitle: {
            content: getPropertyTitle(homeProperty)
        },
        noOfBedrooms: {
            value: isBespokeJourney ? displayNumberOfBedrooms()
                : homeProperty.homhomePropertyDetails.numberOfBedrooms
        },
        buildingsLevelLimit: {
            visible: !isBespokeJourney,
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_BUILDING_LEVEL_LIMIT
            )
        },
        buildingSumInsured: {
            // eslint-disable-next-line max-len
            visible: isBespokeJourney && (!isFirstLossSumInsured && !isTenantFixure),
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_BUILDING_SUM_INSURED
            )
        },
        buildingFirstLossSumInsured: {
            visible: isBespokeJourney && isFirstLossSumInsured,
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOMBuildingsCovFirstLossSumRequired
            )
        },
        buildingTenantsFixturesSumInsured: {
            visible: isTenantFixure,
            value: formatCurrency(tenantsFixturesSI)
        },
        buildingsAccidentalDamage: {
            value: displayAccidentalMsgForNGH(),
            visible: !isBespokeJourney
        },
        buildingsAccidentalDamageInd: {
            value: ((getPropertyOccupancy && getPropertyOccupancy !== undefined)
        || (getTermValue(buildingsCov,
            buildingsIds.HOM_BUILDINGS_COV_RESTRICT_COVER_TO_FLEE) === YES_KEY))
                ? translator(messages.notSelected)
                : translator(messages.included),
            visible: isBespokeJourney
        },
        confirmedContainer: {
            visible: isBuildingsCov
                && homeLine?.isPropertyNotForBusinessUse
                && homeLine?.isPropertyNotImpactedByFloodAndSubsidence
        },
        leakingAndFrozenWater: {
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_TOTAL_EXCESS
            )
        },
        subsidence: {
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOMBuildingsCovSubsidenceHeaveExcessTotal
            )
        },
        flood: {
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_FLOOD_TOTAL_EXCESS
            )
        },
        voluntary: {
            value: isBespokeJourney ? displayVoluntary() : getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_VOLUNTARY_EXCESS
            )
        },
        compulsaryAllClaims: {
            value: getTermLimitValue(
                buildingsCov,
                buildingsIds.HOM_BUILDINGS_COV_ALL_OTHER_CLAIMS_EXCESS
            ),
            visible: !isAnyAdjustomentsCovTermValue()
        },
        compulsaryAllClaimsWithSeeDocuments: {
            value: translator(messages.seeDocuments),
            visible: isAnyAdjustomentsCovTermValue()
        },
        seeDocuments: {
            onClick: onClickSeeDocuments
        },
        confirmationText2: {
            content: displayConfirmationText('confirmationText2', isBespokeSustainedDmg),
        },
        confirmationText3: {
            content: displayConfirmationText('confirmationText3', isBespokeFloodRisk),
        },
        confirmationText4: {
            visible: !isBespokeJourney,
        },
        confirmationText5: {
            visible: !isBespokeJourney,
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default BuildingsSummary;
