import {
    useCallback, useMemo
} from 'react';
import _ from 'lodash';
import { differenceInDays } from 'date-fns';
import useLobs from './useLobs';
import {
    creditCardPaymentPath,
    directDebitPaymentPath,
    supplimentaryPaymentPath,
    unsuccessfulPaymentPath
} from '../config/ngh-wizard-conditional-paths';
import {
    quotedCreditCardPaymentPath,
    quotedDirectDebitPaymentPath,
    quotedSupplimentaryPaymentPath,
    quotedUnsuccessfulPaymentPath
} from '../config/ngh-quoted-wizard-conditional-paths';

const MAX_DAYS_TO_USE_SUPPLEMENTARY_PATH = 10;

const useNextPaymentPath = (submissionVM) => { /* NOSONAR: pure declarative usage */
    const { isQuotedWizard } = useLobs(submissionVM);

    const daysToPeriodStartDate = useMemo(() => {
        const periodStartDate = _.get(submissionVM, 'baseData.periodStartDate');
        if (periodStartDate?.value) {
            const { day, month, year } = periodStartDate.value;
            return differenceInDays(new Date(year, month, day), new Date());
        }
        return _.noop();
    }, [submissionVM]);

    const shouldUseSupplementaryPaymentPath = daysToPeriodStartDate
        < MAX_DAYS_TO_USE_SUPPLEMENTARY_PATH;

    const selectNextPaymentPath = useCallback((paymentCode) => {
        if (paymentCode === 'cash') {
            return isQuotedWizard ? quotedCreditCardPaymentPath : creditCardPaymentPath;
        }
        if (paymentCode === 'directdebit' && daysToPeriodStartDate < MAX_DAYS_TO_USE_SUPPLEMENTARY_PATH) {
            return isQuotedWizard ? quotedSupplimentaryPaymentPath : supplimentaryPaymentPath;
        }
        return isQuotedWizard ? quotedDirectDebitPaymentPath : directDebitPaymentPath;
    }, [daysToPeriodStartDate, isQuotedWizard]);

    const errorPaymentPath = isQuotedWizard
        ? quotedUnsuccessfulPaymentPath
        : unsuccessfulPaymentPath;

    return { selectNextPaymentPath, shouldUseSupplementaryPaymentPath, errorPaymentPath };
};

export default useNextPaymentPath;
