import AddressLookupComponent from './AddressLookupComponent/AddressLookupComponent';

const AddressLookupComponentMap = {
    AddressLookupComponent: { component: 'AddressLookupComponent' }
};

const AddressLookupComponents = {
    AddressLookupComponent
};

export {
    AddressLookupComponentMap,
    AddressLookupComponents,
    // export single component
    AddressLookupComponent
};
