import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import messages from './QuestionSetComponent.messages';

/**
 * @memberof module:gw-portals-questionsets-ui
 *
 * @property {function(newAnswers, pathToAnswers)} onValueChange - the
 *                                      function invoked when the value is changed
 * @property {function(isComponentValid, id)} onValidationChange - the
 *                                      function when validation is triggered
 * @property {string} labelPosition - the position for the question label
 * @property {string} id - the id for the component
 * @property {Object} title - the translation ready title for the question sets component
 * @property {Object} questionSetsMetadata - the question sets presentation metadata
 * @property {Object} questionSetsViewModel - the question sets view model
 *
 * @returns {ReactElement}
 */
function QuestionSetComponent({
    id,
    title,
    questionSetsMetadata,
    questionSetsViewModel,
    onValueChange,
    onValidationChange,
    labelPosition,
}) {
    const translator = useTranslator();

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: labelPosition
        }
    };

    if (!questionSetsMetadata) {
        return null;
    }

    return (
        <div>
            <h3 id={`${id}_questionSetHeader`} className="wizardPageHeader">
                {translator(title)}
            </h3>
            <ViewModelForm
                uiProps={questionSetsMetadata}
                model={questionSetsViewModel}
                overrideProps={overrideProps}
                onValueChange={onValueChange}
                onValidationChange={onValidationChange}
            />
        </div>
    );
}

QuestionSetComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    onValueChange: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    onValidationChange: PropTypes.func,
    labelPosition: PropTypes.string,
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.string,
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    // eslint-disable-next-line react/require-default-props
    questionSetsMetadata: PropTypes.object,
    // eslint-disable-next-line react/require-default-props
    questionSetsViewModel: PropTypes.object,
};

QuestionSetComponent.defaultProps = {
    labelPosition: 'left',
    title: messages.pageTitle
};

export default QuestionSetComponent;
