import { defineMessages } from 'react-intl';

export default defineMessages({
    editQuoteDetails: {
        id: 'quoteandbind.ngh.views.policy-summary.Edit quote details',
        defaultMessage: 'Edit quote'
    },
    needToMakeChange: {
        id: 'quoteandbind.ngh.views.policy-summary.needToMakeChange',
        defaultMessage: 'Need to make a change?'
    },
    needToMakeChangeInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.needToMakeChangeInfo1',
        defaultMessage: 'A summary of your cover and the information you have provided is available in '
    },
    yourQuoteDocumentation: {
        id: 'quoteandbind.ngh.views.policy-summary.yourQuoteDocumentation',
        defaultMessage: 'your quote documentation'
    },
    needToMakeChangeInfo2: {
        id: 'quoteandbind.ngh.views.policy-summary.needToMakeChangeInfo2',
        defaultMessage: '. Please take the time to read this carefully before proceeding. If you need to make a change please contact us using one of the following options.'
    },
    policyDetails: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails',
        defaultMessage: 'Policy details'
    },
    editPolicyDetails: {
        id: 'quoteandbind.ngh.views.policy-summary.Edit policy details',
        defaultMessage: 'Edit policy details'
    },
    buildings: {
        id: 'quoteandbind.ngh.views.policy-summary.buildings',
        defaultMessage: 'Buildings'
    },
    editBuildingsInsurance: {
        id: 'quoteandbind.ngh.views.policy-summary.Edit buildings insurance',
        defaultMessage: 'Edit buildings details'
    },
    contents: {
        id: 'quoteandbind.ngh.views.policy-summary.contents',
        defaultMessage: 'Contents'
    },
    editContents: {
        id: 'quoteandbind.ngh.views.policy-summary.editContentsInsurance',
        defaultMessage: 'Edit contents'
    },
    additionalCovers: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers',
        defaultMessage: 'Additional Insurances'
    },
    editAdditionalCoverages: {
        id: 'quoteandbind.ngh.views.policy-summary.editAdditionalInsurances',
        defaultMessage: 'Edit additional insurances'
    },
    caravan: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan',
        defaultMessage: 'Caravan'
    },
    caravanInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.caravanInfo1',
        defaultMessage: 'A summary of your Caravan cover and the information you have provided is available in '
    },
    readCarefully: {
        id: 'quoteandbind.ngh.views.policy-summary.readCarefully',
        defaultMessage: '. Please take the time to read this carefully before proceeding.'
    },
    dogAndCat: {
        id: 'quoteandbind.ngh.views.policy-summary.dogAndCatCard',
        defaultMessage: 'Cat/Dog'
    },
    dogAndCatCard: {
        id: 'quoteandbind.ngh.views.policy-summary.dogAndCatCard',
        defaultMessage: 'Cat/Dog'
    },
    dogAndCatInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.dogAndCatInfo1',
        defaultMessage: 'A summary of your Cat/Dog cover and the information you have provided are available '
    },
    travel: {
        id: 'quoteandbind.ngh.views.policy-summary.travel',
        defaultMessage: 'Travel'
    },
    travelInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.travelInfo1',
        defaultMessage: 'A summary of your Travel cover and the information you have provided is available in '
    },
    personalAccident: {
        id: 'quoteandbind.ngh.views.policy-summary.personalAccident',
        defaultMessage: 'Personal Accident'
    },
    paInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.paInfo1',
        defaultMessage: 'A summary of your Personal accident cover and the information you have provided is available in '
    },
    largePrint: {
        id: 'quoteandbind.ngh.views.policy-summary.largePrint',
        defaultMessage: 'large print',
    },
    braille: {
        id: 'quoteandbind.ngh.views.policy-summary.braille',
        defaultMessage: 'braille',
    },
    audio: {
        id: 'quoteandbind.ngh.views.policy-summary.audio',
        defaultMessage: 'audio',
    },
    yourPolicyDocumentsHeading: {
        id: 'quoteandbind.ngh.views.policy-summary.yourPolicyDocumentsHeading',
        defaultMessage: 'Your policy documents'
    },
    yourPolicyDocumentsMessage: {
        id: 'quoteandbind.ngh.views.policy-summary.yourPolicyDocumentsMessage',
        defaultMessage: 'We’ll email you with details of how you can access your policy documents online.\nIf you choose to go paperless for your documents, you\'re helping to reduce the amount of paper we all use.\nIf you’d like to receive a paper copy of your policy documents by post, you can let us know by ticking the box below.'
    },
    receiveDocuments: {
        id: 'quoteandbind.ngh.views.policy-summary.receiveDocuments',
        defaultMessage: 'Please send my policy documents by post'
    },
    yourPolicyDocumentsMessageInAlternateFormat: {
        id: 'quoteandbind.ngh.views.policy-summary.yourPolicyDocumentsMessageInAlternateFormat',
        defaultMessage: 'Digital version of your personal policy document will be available on your online account. Your documents will also be issued shortly in {PreferredFormat}, as requested. If you also require a paper copy of them in a standard format, sent to your at the point of purchase please tick the box below.'
    },
    otherImportantDocuments: {
        id: 'quoteandbind.ngh.views.policy-summary.otherImportantDocuments',
        defaultMessage: 'Other important documents'
    },
    yourDocuments: {
        id: 'quoteandbind.ngh.views.policy-summary.yourDocuments',
        defaultMessage: 'Your documents'
    },
    confirmationPrintedCopyTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.confirmationPrintedCopyTitle',
        defaultMessage: 'Confirmation'
    },
    confirmationPrintedCopyText: {
        id: 'quoteandbind.ngh.views.policy-summary.confirmationPrintedCopyText',
        defaultMessage: 'You\'ve chosen to receive a copy of your policy documents by post.\nYou can change your paperless preferences at any time by getting in touch with us.\nWith an NFU Mutual online account you can also choose to view, download and print your policy documents at any time.'
    },
    emailQuoteSentTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.emailQuoteSentTitle',
        defaultMessage: 'Email sent',
    },
    emailQuoteSentMessage: {
        id: 'quoteandbind.ngh.views.policy-summary.emailQuoteSentMessage',
        defaultMessage: 'Thanks, your quote has been saved and we’ll email you with a link you can use to come back to it later.\nYou may also want to take a note of your Quote Reference number, which you’ll find at the top right corner of this page.',
    },
    emailQuoteAlreadySentMessage: {
        id: 'quoteandbind.ngh.views.policy-summary.emailQuoteAlreadySentMessage',
        defaultMessage: 'We have already emailed you your quote, please check your email inbox'
    },
    cantSendQuoteDetailsAtThisTime: {
        id: 'quoteandbind.ngh.views.policy-summary.Sorry, we were not able to email the quote details at this time.',
        defaultMessage: 'Sorry, we were not able to email the quote details at this time.',
    },
    error: {
        id: 'quoteandbind.ngh.views.policy-summary.Error',
        defaultMessage: 'Error',
    },
    reviewDetails: {
        id: 'quoteandbind.ngh.views.policy-summary.reviewDetails',
        defaultMessage: 'Review details'
    },
    reviewCover: {
        id: 'quoteandbind.ngh.views.policy-summary.reviewCover',
        defaultMessage: 'Review cover'
    },
    insideHome: {
        id: 'quoteandbind.ngh.views.policy-summary.insideHome',
        defaultMessage: 'Inside home'
    },
    insideOutsideHome: {
        id: 'quoteandbind.ngh.views.policy-summary.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)*'
    },
    keptInBank: {
        id: 'quoteandbind.ngh.views.policy-summary.keptInBank',
        defaultMessage: 'Kept in bank?'
    },
    yes: {
        id: 'quoteandbind.ngh.views.policy-summary.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ngh.views.policy-summary.no',
        defaultMessage: 'No'
    },
    noPreference: {
        id: 'quoteandbind.ngh.views.policy-summary.noPreference',
        defaultMessage: 'No preference'
    },
    saveForLater: {
        id: 'quoteandbind.ngh.views.policy-summary.saveForLater',
        defaultMessage: 'Save for later'
    },
    switchToYearlyPremium: {
        id: 'quoteandbind.ngh.views.policy-summary.switchToYearlyPremium',
        defaultMessage: 'Switch to yearly premium'
    },
    switchToMonthlyPremium: {
        id: 'quoteandbind.ngh.views.policy-summary.switchToMonthlyPremium',
        defaultMessage: 'Switch to monthly premium'
    },
    additionalCoversInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCoversInfo',
        defaultMessage: 'A summary of your Additional Insurances and the information you have provided is available in '
    },
    contentsInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.contentsInfo',
        defaultMessage: 'A summary of your Contents cover and the information you have provided are available in '
    },
    buildingsInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsInfo',
        defaultMessage: 'A summary of your Buildings cover and the information you have provided are available in '
    },
    documentsInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.documentsInfo',
        defaultMessage: "Please take the time to read these important documents before you complete your purchase to ensure the policy is right for you. You 'll also find details on how to cancel, your options on how to renew and how to make a claim."
    },
    finalInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.finalInfo',
        defaultMessage: 'NFU Mutual Home Insurance underwritten by NFU Mutual'
    },
    specialConditionsTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.specialConditionsTitle',
        defaultMessage: 'Special conditions'
    },
    specialConditionsBodyText1: {
        id: 'quoteandbind.ngh.views.policy-summary.specialConditionsBodyText1',
        defaultMessage: 'Please note specific conditions apply to your policy. Please check your '
    },
    specialConditionsBodyText4: {
        id: 'quoteandbind.ngh.views.policy-summary.specialConditionsBodyText4',
        defaultMessage: 'schedule'
    },
    specialConditionsBodyText5: {
        id: 'quoteandbind.ngh.views.policy-summary.specialConditionsBodyText5',
        defaultMessage: ' for full details.'
    },
    replacementCost: {
        id: 'quoteandbind.ngh.views.policy-summary.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    category: {
        id: 'quoteandbind.ngh.views.policy-summary.category',
        defaultMessage: 'Category'
    },
    editValuables: {
        id: 'quoteandbind.ngh.views.policy-summary.editValuables',
        defaultMessage: 'Edit valuables'
    },
    valuables: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables',
        defaultMessage: 'Valuables'
    },
    fineArtsCollections: {
        id: 'quoteandbind.ngh.views.policy-summary.fineArtsCollections',
        defaultMessage: 'Fine art & collections '
    },
    bicycles: {
        id: 'quoteandbind.ngh.views.policy-summary.bicycles',
        defaultMessage: 'Bicycles'
    },
    billingAddress: {
        id: 'quoteandbind.ngh.views.policy-summary.billingAddress',
        defaultMessage: 'Billing address'
    },
    confirmMessage: {
        id: 'quoteandbind.ngh.views.property-details.confirmMessage',
        defaultMessage: 'Please confirm the following statements are correct:'
    },
    yourNameAddress: {
        id: 'quoteandbind.ngh.views.property-details.yourNameAddress',
        defaultMessage: 'The account you are paying from is in your name'
    },
    insureBillingAddress: {
        id: 'quoteandbind.ngh.views.property-details.insureBillingAddress',
        defaultMessage: 'The billing address of this account is the address you are looking to insure'
    },
    iConfirm: {
        id: 'quoteandbind.ngh.views.policy-summary.iConfirm',
        defaultMessage: 'I confirm'
    },
    iDoNotConfirm: {
        id: 'quoteandbind.ngh.views.policy-summary.iDoNotConfirm',
        defaultMessage: 'I do not confirm'
    },
    addValidationReferalMessage1: {
        id: 'quoteandbind.ngh.views.policy-summary.addValidationReferalMessage1',
        defaultMessage: 'We can only setup payments online that are both in your name and have the same billing address as the property you are looking to insure.'
    },
    addValidationReferalMessage2: {
        id: 'quoteandbind.ngh.views.policy-summary.addValidationReferalMessage2',
        defaultMessage: 'If you want to use a different method of payment that is in your name and matches the address you want to insure then click \'I confirm\' and proceed. Alternatively, you can get in touch using the options below:'
    },
    valuablesInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.valuablesInfo',
        defaultMessage: 'A summary of your '
    },
    valuablesInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.valuablesInfo1',
        defaultMessage: 'Valuables'
    },
    valuablesInfo2: {
        id: 'quoteandbind.ngh.views.policy-summary.valuablesInfo2',
        defaultMessage: ' cover and the information you have provided are available in '
    },
    policyAcquisitionsCoverHeading: {
        id: 'quoteandbind.ngh.views.policy-summary.policyAcquisitionsCoverHeading',
        defaultMessage: 'Your policy includes New Acquisitions Cover'
    },
    policyAcquisitionsCoverDetailsValuables: {
        id: 'quoteandbind.ngh.views.policy-summary.policyAcquisitionsCoverDetailsValuables',
        defaultMessage: 'This cover is designed to give you peace of mind for Valuables you have just purchased, the most we will pay is up to 25% of your total valuable items replacement cost (please see your Policy Schedule for this amount), for a period of up to 60 days.'
    },
    policyAcquisitionsCoverDetailsFineArt: {
        id: 'quoteandbind.ngh.views.policy-summary.policyAcquisitionsCoverDetailsFineArt',
        defaultMessage: 'This cover is designed to give you peace of mind for Fine Art & Collections you have just purchased, the most we will pay is up to 30% of your total Fine Art & Collections items replacement cost (please see your Policy Schedule for this amount), for a period of up to 60 days.'
    },
    jewellery: {
        id: 'quoteandbind.ngh.views.policy-summary.jewellery',
        defaultMessage: 'jewellery and Gemstones'
    },
    watches: {
        id: 'quoteandbind.ngh.views.policy-summary.watches',
        defaultMessage: 'Watches'
    },
    guns: {
        id: 'quoteandbind.ngh.views.policy-summary.guns',
        defaultMessage: 'Guns'
    },
    furs: {
        id: 'quoteandbind.ngh.views.policy-summary.furs',
        defaultMessage: 'Furs'
    },
    customMessageHeader: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.customMessageHeader',
        defaultMessage: 'About your valuables'
    },
    customMessageInfo: {
        id: 'quoteandbind.ngh.views.policy-summary.customMessageInfo',
        defaultMessage: 'Full details of your valuables are captured in your policy documents. '
    },
    customMessageDocumentation: {
        id: 'quoteandbind.ngh.views.policy-summary.customMessageDocumentation',
        defaultMessage: 'Click here to view them'
    },
    customMessageInfo1: {
        id: 'quoteandbind.ngh.views.policy-summary.customMessageInfo1',
        defaultMessage: 'If you would like to discuss your valuables with us please '
    },
    customMessageDocumentation1: {
        id: 'quoteandbind.ngh.views.policy-summary.customMessageDocumentation1',
        defaultMessage: 'contact your agency'
    },
});
