import React, {
    useCallback, useContext, useEffect
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import {
    useErrorHandler,
    UW_ERROR_CODE,
    ERROR_STEPS,
    QB_STEPS,
    QUOTE_RETRIEVAL_STEPS,
    AppContext
} from 'nfum-portals-utils-react';
import { useValidation } from 'gw-portals-validation-react';
import useCleanPayload from '../../hooks/useCleanPayload';
import metadata from './PaymentStatusPage.metadata.json5';
import styles from './PaymentStatusPage.module.scss';
import NGHContext from '../../NGHContext';
import useNextPaymentPath from '../../hooks/useNextPaymentPath';
import useTagManager from '../../hooks/useTagManager';

const PaymentStatusPage = (props) => {
    const { wizardData: submissionVM, goNext, changeNextSteps } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isPaymentSucceed } = useContext(NGHContext);
    const { handleUWError } = useErrorHandler();
    const { errorPaymentPath } = useNextPaymentPath(submissionVM);
    const { isQuoteRetrieval } = useContext(AppContext);
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    const {
        onValidate,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('PaymentStatusPage');
    const { pushFormStepErrorInfo } = useTagManager();

    const skipWhenRetrieved = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Bound';
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(skipWhenRetrieved);
    }, [skipWhenRetrieved, registerInitialComponentValidation]);

    useEffect(() => { /* NOSONAR: pure declarative usage */
        const resolveNextAction = () => {
            const paymentMethod = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
            if (isPaymentSucceed) {
                if (paymentMethod === 'directdebit') {
                    goNext();
                } else {
                    cleanNotUpdatedCoverages(submissionVM);
                    cleanCostInfos(submissionVM);
                    LoadSaveService
                        .bindSubmission(submissionVM.value, authHeader)
                        .then((boundSubmission) => {
                            submissionVM.value = boundSubmission;
                            goNext();
                        }).catch((error) => {
                            pushFormStepErrorInfo(submissionVM, isQuoteRetrieval
                                ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                                : QB_STEPS.PAYMENT, error);
                            if (error.appErrorCode === UW_ERROR_CODE) {
                                handleUWError(error, submissionVM.value.quoteID);
                            } else {
                                changeNextSteps(errorPaymentPath);
                                goNext();
                            }
                        });
                }
            } else {
                pushFormStepErrorInfo(submissionVM, isQuoteRetrieval
                    ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                    : QB_STEPS.PAYMENT, null, ERROR_STEPS.PAYMENT_ERROR);
                changeNextSteps(errorPaymentPath);
                goNext();
            }
        };
        if (!skipWhenRetrieved()) {
            resolveNextAction();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => {
        return submissionVM;
    }, [submissionVM]);

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <WizardPage
            skipWhen={initialValidation}
            showNext={false}
            showCancel={false}
            onNext={onNext}
            showPrevious={false}
        >
            <ViewModelForm
                onValidationChange={onValidate}
                uiProps={metadata.pageContent}
                model={submissionVM}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
};

PaymentStatusPage.propTypes = wizardProps;
export default PaymentStatusPage;
