import React, { useCallback } from 'react';
import { pageTemplateProps } from 'gw-portals-wizard-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { NfumWizardPageTemplate, WizardHeader, SessionTimeOut } from 'nfum-portals-wizard-react';
import headerStepsConfig from './config/ngh-quoted-wizard-header-config';

function NGHQuotedWizardPageTemplate(props) {
    const {
        children, acknowledgeError, errorsForStep, ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        () => {
            return (
                <WizardHeader
                    stepsConfig={headerStepsConfig}
                />
            );
        },
        []
    );

    const renderSessionTimedOut = useCallback(
        () => {
            return (
                <SessionTimeOut />
            );
        },
        []
    );
    const showSessionTimedOut = window.localStorage.getItem('sessionTimeOut') === 'true';

    return (
        <NfumWizardPageTemplate
            showContextComponent
            renderContextComponent={renderWizardHeader}
            showSessionTimedOut={showSessionTimedOut}
            renderSessionTimedOut={renderSessionTimedOut}
            errorsForStep={errorsForStep}
            {...otherProps}
        >
            <WizardSingleErrorComponent
                acknowledgeError={acknowledgeError}
                issuesList={errorsForStep}
            />
            {children}
        </NfumWizardPageTemplate>
    );
}

NGHQuotedWizardPageTemplate.propTypes = pageTemplateProps;
export default NGHQuotedWizardPageTemplate;
