import { defineMessages } from 'react-intl';

export default defineMessages({
    wineCollection: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.wineCollection',
        defaultMessage: 'Wine'
    },
    stampMedalCoinsCollection: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.stampMedalCoinsCollection',
        defaultMessage: 'Stamps, Medals & Coins'
    },
    otherCollection: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.otherCollection',
        defaultMessage: 'Other'
    },
    type: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.type',
        defaultMessage: 'Type'
    },
    collectionType: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.collectionType',
        defaultMessage: 'Collection type'
    },
    sumInsured: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.sumInsured',
        defaultMessage: 'Sum insured'
    },
    singleItemLimit: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.singleItemLimit',
        defaultMessage: 'Single item limit'
    },
    addedItems: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.noDataExist',
        defaultMessage: 'No data exist'
    },
    policeInsignia: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.policeInsignia',
        defaultMessage: 'Police Insignia'
    },
    tinSoldiers: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.tinSoldiers',
        defaultMessage: 'Tin soldiers'
    },
    vintageFountainPens: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.vintageFountainPens',
        defaultMessage: 'Vintage fountain pens'
    },
    bankNotes: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.bankNotes',
        defaultMessage: 'Bank notes'
    },
    cigaretteCards: {
        id: 'quote.bespoke.components.fineArtsCollectionsItem.cigaretteCards',
        defaultMessage: 'Cigarette cards'
    }
});
