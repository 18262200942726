import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'quoteandbind.pages.retrieveQuotePage.pageTitle',
        defaultMessage: 'Retrieve your quote'
    },
    changeQuoteNumber: {
        id: 'quoteandbind.pages.retrieveQuotePage.changeQuoteNumber',
        defaultMessage: 'Change quote number'
    }
});
