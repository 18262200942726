import { defineMessages } from 'react-intl';

export default defineMessages({
    yourNewPremium: {
        id: 'endorsement.ngh.views.policy-summary.costBreakdownBox.yourNewPremium',
        defaultMessage: 'Your premium'
    },
    increaseOf: {
        id: 'endorsement.ngh.views.policy-summary.costBreakdownBox.increaseOf',
        defaultMessage: 'This is an increase of {amount}'
    },
    decreaseOf: {
        id: 'endorsement.ngh.views.policy-summary.costBreakdownBox.decreaseOf',
        defaultMessage: 'This is a decrease of {amount}'
    },
    noPremiumChange: {
        id: 'endorsement.ngh.views.policy-summary.costBreakdownBox.noPremiumChange',
        defaultMessage: 'There is no change to your premium'
    }
});
