import React, {
    useState, useCallback, useEffect, useReducer, useContext
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    useErrorHandler,
    AppContext,
    QB_STEPS,
    BUILDINGS_ONLY,
    BUILDINGS_AND_CONTENTS,
    CONTENTS_ONLY,
    useCommonTagManager
} from 'nfum-portals-utils-react';
import SystemDownMessage from '../../components/SystemDownMessages/SystemDownMessage';
import styles from './PropertyConstructionPage.module.scss';
import metadata from './PropertyConstructionPage.metadata.json5';
import commonMessages from '../../NGHWizard.messages';
import ReferralMessage from '../../components/ReferralMessageComponent/ReferralMessageComponent';
import SectionComponent from '../../components/SectionComponent/SectionComponent';
import messages from './PropertyConstructionPage.messages';
import useTagManager from '../../hooks/useTagManager';
import useCurrency from '../../hooks/useCurrency';
import useCleanPayload from '../../hooks/useCleanPayload';

const actions = Object.freeze({
    SHOW_CONTENTS_REPLACEMENT_COST_REFERRAL: 'showContentsReplacementCostReferral',
    SHOW_REBUILD_COST_REFERRAL: 'showRebuildCostReferral',
    SHOW_INITIAL_STATE: 'showInitialState'
});

const initialState = {
    showContentsReplacementCostReferral: false,
    showRebuildCostReferral: false,
    showYourContentsContainer: true,
    showYourBuildingsContainer: true,
    showPaymentContainer: true,
    showContentsReplacementCost: true,
    showPropertyRebuildCost: true,
    showYearOfBuildings: true,
    showYearOfContents: true
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SHOW_CONTENTS_REPLACEMENT_COST_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showContentsReplacementCostReferral: true, showRebuildCostReferral: false, showYourContentsContainer: true, showYourBuildingsContainer: false, showPaymentContainer: false, showContentsReplacementCost: true, showValuablesReplacementCost: false, showPropertyRebuildCost: false, showYearOfBuildings: false, showYearOfContents: false
            };
        case actions.SHOW_REBUILD_COST_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showContentsReplacementCostReferral: false, showRebuildCostReferral: true, showYourContentsContainer: false, showYourBuildingsContainer: true, showPaymentContainer: false, showContentsReplacementCost: false, showValuablesReplacementCost: false, showPropertyRebuildCost: true, showYearOfBuildings: false, showYearOfContents: false
            };
        case actions.SHOW_INITIAL_STATE:
            return {
                // eslint-disable-next-line max-len
                ...state, showContentsReplacementCostReferral: false, showRebuildCostReferral: false, showYourContentsContainer: true, showYourBuildingsContainer: true, showPaymentContainer: true, showContentsReplacementCost: true, showValuablesReplacementCost: true, showPropertyRebuildCost: true, showYearOfBuildings: true, showYearOfContents: true
            };
        default:
            return state;
    }
};

function PropertyConstructionPage(props) { /* NOSONAR: pure declarative usage */
    const { wizardData: submissionVM, updateWizardData } = props;
    const translator = useContext(TranslatorContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [providedDetailsConfirmation, updateProvidedDetailsConfirmation] = useState(false);
    const CURRENCY_REGEXP = '^[0-9]{0,12}$|^$';
    const [isLoading, setIsLoading] = useState(false);
    const { cleanNotUpdatedCoverages } = useCleanPayload();
    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('PropertyConstructionPage');
    const [state, dispatch] = useReducer(reducer, initialState);
    const [coverages, setcoverages] = useState({ buildingsInput: true, contentsInput: true });
    const coverableValue = _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables');
    const MAX_REBUILD_COST_VALUE = 1000000;
    const MAX_CONTENTS_REPLACEMENT_COST_VALUE = 150000;
    const PAYMENT_METHOD_PATH = 'baseData.paymentMethod_NFUM';
    const IS_REFERRED_OFFLINE_PATH = 'lobData.homeLine.coverables.homhomeProperty.isReferedOffline';
    const IS_CONTENTS_AWAY_COV_AVAIL_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.isContentsAwayCovAvail';
    const CONTENTS_REPLACEMENT_COST_OVERRID_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid';
    const CONTENTS_REPLACEMENT_COST_OVERRID_FROM_SERVICE_PATH = 'lobData.homeLine.coverables.homhomeProperty.contentsReplacementCostOverridFromService';
    const REBUILDING_COST_CALC_OVERRIDE_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.rebuildingCostCalcOverride';
    const REBUILDING_COST_CALC_OVERRIDE_FROM_SERVICE_PATH = 'lobData.homeLine.coverables.homhomeProperty.rebuildingCostCalcOverrideFromService';
    const VALUABLES_REPLACEMENT_COST_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.valuablesReplacementCost';
    const IS_VALIDATION_NOT_REQUIRED_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.isValidationNotRequired';
    const PROPERTY_STATUS_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.propertyStatus';
    const IS_MORE_THAN_5_BEDROOMS_AND_TIER_1_ERROR_PATH = 'lobData.homeLine.coverables.homhomeProperty.isMoreThan5BedroomsAndContentsSumInsuredTooLowError';
    const { setIsMarketingQuoteLoadingView } = useContext(AppContext);
    const MARKETING_LOADING_VIEW_DISPLAY_TIME_MS = 5000;
    const {
        handleError,
        handleNotBlockingError,
        handleUWError
    } = useErrorHandler();
    const currencyFormatter = useCurrency();
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    const [interiorDecorations, setInteriorDecorations] = useState({
        showCover: false,
        showCostExceededLimit: false,
        showCoverInput: false,
        costExceededLimitValue: null
    });
    const [disableYearOfContents, setDisableYearOfContents] = useState(true);
    const { pushFormStepInfo, pushFormStepErrorInfo } = useTagManager();
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    useEffect(() => {
        const { isSkipping } = props;
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, QB_STEPS.PROPERTY_CONSTRUCTION);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, QB_STEPS.PROPERTY_CONSTRUCTION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipWhenRetrieved = useCallback(() => {
        return (_.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.aspects.subtreeValid') === true
            && providedDetailsConfirmation)
            || _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM, providedDetailsConfirmation]);

    const isPropertyConstructionValid = useCallback(() => {
        const propertyConstructionVMWithContext = viewModelService.create({
            ..._.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction'),
            isValidationNotRequired: false
        },
        'pc',
        'nfum.edge.capabilities.policyjob.lob.homeline.coverables.dto.HOMHomePropertyConstructionDTO_NFUM', {
            ChosenCoverables: _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables'),
            IsReferredOffline: false
        });
        return propertyConstructionVMWithContext.aspects.valid
            && propertyConstructionVMWithContext.aspects.subtreeValid;
    }, [submissionVM, viewModelService]);

    useEffect(() => {
        registerInitialComponentValidation(skipWhenRetrieved);
        registerComponentValidation(isPropertyConstructionValid);
    }, [
        skipWhenRetrieved,
        registerInitialComponentValidation,
        registerComponentValidation,
        isPropertyConstructionValid
    ]);

    const referOffline = useCallback(async (updatedSubmissionVM) => {
        try {
            setIsLoading(true);
            const submissionToSend = _.cloneDeep(updatedSubmissionVM.value);
            _.set(submissionToSend, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails', undefined);
            _.set(submissionToSend, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails', undefined);
            _.set(submissionToSend, 'lobData.homeLine.lineCoverages', undefined);
            _.set(submissionToSend, 'lobData.homeLine.baseData', undefined);

            await LoadSaveService.referOfflineNFUM(
                submissionToSend,
                authHeader
            );
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            return handleNotBlockingError(error);
        }
    }, [LoadSaveService, authHeader, submissionVM, handleNotBlockingError]);

    const showReferral = useCallback((type) => {
        dispatch({ type });
        _.set(submissionVM, IS_REFERRED_OFFLINE_PATH, true);
        updateWizardData(submissionVM);
        referOffline(submissionVM);
    }, [submissionVM, updateWizardData, referOffline]);

    const hideReferral = useCallback(() => {
        dispatch({ type: actions.SHOW_INITIAL_STATE });
        _.set(submissionVM, IS_REFERRED_OFFLINE_PATH, false);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const handleCoverageTypeBasedInputs = useCallback(() => {
        switch (coverableValue) {
            case BUILDINGS_AND_CONTENTS:
                setcoverages({ buildingsInput: true, contentsInput: true });
                break;
            case CONTENTS_ONLY:
                setcoverages({ buildingsInput: false, contentsInput: true });
                break;
            case BUILDINGS_ONLY:
                setcoverages({ buildingsInput: true, contentsInput: false });
                break;
            default:
                break;
        }
    }, [coverableValue]);

    const showReferralForRebuildCostIfNeeded = useCallback((rebuildCost) => {
        if (rebuildCost && rebuildCost > MAX_REBUILD_COST_VALUE) {
            showReferral(actions.SHOW_REBUILD_COST_REFERRAL);
        } else {
            hideReferral();
        }
    }, [showReferral, hideReferral]);

    const showReferralForContentsReplacementCostIfNeeded = useCallback((contentsCost) => {
        if (contentsCost && contentsCost > MAX_CONTENTS_REPLACEMENT_COST_VALUE) {
            showReferral(actions.SHOW_CONTENTS_REPLACEMENT_COST_REFERRAL);
            return true;
        }
        hideReferral();
        return false;
    }, [showReferral, hideReferral]);

    const setDefaultValueForValuablesReplacementCostIfNeeded = useCallback(() => {
        const contentsReplacementCost = _.get(submissionVM.value,
            CONTENTS_REPLACEMENT_COST_OVERRID_PATH);
        if (contentsReplacementCost
            && !_.get(submissionVM.value, VALUABLES_REPLACEMENT_COST_PATH)) {
            _.set(submissionVM.value, VALUABLES_REPLACEMENT_COST_PATH, contentsReplacementCost / 2);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, updateWizardData]);

    const rebuildingCostFromService = _.get(submissionVM.value,
        REBUILDING_COST_CALC_OVERRIDE_FROM_SERVICE_PATH);
    const contentsCostFromService = _.get(submissionVM.value,
        CONTENTS_REPLACEMENT_COST_OVERRID_FROM_SERVICE_PATH);
    const isRicsServiceForRebuildCostAvailable = rebuildingCostFromService !== undefined
        && rebuildingCostFromService !== 0;
    const isRicsServiceForContentsCostAvailable = contentsCostFromService !== undefined
        && contentsCostFromService !== 0;

    useEffect(() => {
        if (_.isEmpty(_.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction'))) {
            _.set(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyConstruction', {});
            updateWizardData(submissionVM);
        }
        handleCoverageTypeBasedInputs();
        showReferralForRebuildCostIfNeeded(
            _.get(submissionVM.value, REBUILDING_COST_CALC_OVERRIDE_PATH)
        );
        showReferralForContentsReplacementCostIfNeeded(
            _.get(submissionVM.value, CONTENTS_REPLACEMENT_COST_OVERRID_PATH)
        );
        setDefaultValueForValuablesReplacementCostIfNeeded();
        if (_.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted' || isRicsServiceForContentsCostAvailable) {
            setDisableYearOfContents(false);
        }
        _.set(submissionVM, 'baseData.automaticRenewalOoption_NFUM', _.get(submissionVM.value, PAYMENT_METHOD_PATH) !== 'cash');
        if (_.isEmpty(submissionVM.lobData.lexLine.value)) {
            submissionVM.lobData.lexLine = {};
            if (_.isEmpty(submissionVM.lobData.lexLine.lineCoverages.value)) {
                submissionVM.lobData.lexLine.lineCoverages = {};
            }
        }
        const isContentsAwayCovAvailValue = _.get(submissionVM.value,
            IS_CONTENTS_AWAY_COV_AVAIL_PATH);
        if (isContentsAwayCovAvailValue === undefined) {
            _.set(submissionVM.value, IS_CONTENTS_AWAY_COV_AVAIL_PATH, false);
        }
        updateWizardData(submissionVM);
        setPageInitialized(true);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTenantsInteriorDecorationData = useCallback(async () => {
        const propertyStatus = _.get(submissionVM.value, PROPERTY_STATUS_PATH);
        if (propertyStatus !== 'OwnedOutrightByYou' && propertyStatus !== 'OwnedWithAMortgageByYou') {
            setInteriorDecorations((prevState) => {
                return { ...prevState, showCover: true };
            });
            if (_.get(submissionVM, 'lobData.value.homeLine.lineCoverages.tenantsInteriorSumInsured') === 0) {
                setInteriorDecorations((prevState) => {
                    return { ...prevState, showCostExceededLimit: true };
                });
            } else if (_.get(submissionVM, 'lobData.value.homeLine.lineCoverages.tenantsInteriorSumInsured')) {
                setInteriorDecorations((prevState) => {
                    return { ...prevState, showCostExceededLimit: true, showCoverInput: true };
                });
            }
        }
    }, [
        submissionVM
    ]);

    useEffect(() => {
        if (skipWhenRetrieved()) return;
        fetchTenantsInteriorDecorationData();
    }, []);

    const handleContentsReplacementCostChange = useCallback(async (cost, path) => {
        if (cost) {
            _.set(submissionVM, path, cost);
            _.set(submissionVM, VALUABLES_REPLACEMENT_COST_PATH, Math.trunc(cost / 2));
        } else {
            _.set(submissionVM, path, undefined);
            _.set(submissionVM, VALUABLES_REPLACEMENT_COST_PATH, undefined);
        }
        updateWizardData(submissionVM);
        showReferralForContentsReplacementCostIfNeeded(cost);
    }, [
        submissionVM,
        showReferralForContentsReplacementCostIfNeeded,
        updateWizardData,
    ]);

    const handleInteriorDecorationsCoverChange = useCallback((value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
        setInteriorDecorations({ ...interiorDecorations, showCostExceededLimit: true });
    }, [
        viewModelService,
        submissionVM,
        updateWizardData,
        interiorDecorations]);

    const handleInteriorDecorationsSumInsuredChange = useCallback((value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [
        viewModelService,
        submissionVM,
        updateWizardData]);

    const handleInteriorDecorationExceededCostChange = useCallback((value) => {
        setInteriorDecorations({
            ...interiorDecorations,
            showCoverInput: value,
            costExceededLimitValue: value
        });
    }, [interiorDecorations]);

    const handlePropertyRebuildCostChange = useCallback((cost, path) => {
        _.set(submissionVM, path, cost);
        showReferralForRebuildCostIfNeeded(cost);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferralForRebuildCostIfNeeded]);

    const handlePaymentOptionsChange = useCallback((paymentOption, path) => {
        _.set(submissionVM, path, paymentOption);
        _.set(submissionVM, 'baseData.automaticRenewalOoption_NFUM', _.get(submissionVM.value, PAYMENT_METHOD_PATH) !== 'cash');
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const isCurrencyFormat = useCallback((value) => {
        const regex = new RegExp(CURRENCY_REGEXP);
        return regex.test(value);
    }, []);

    const handleCurrencyChange = useCallback((value, path) => {
        if (!isCurrencyFormat(value)) return;
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [isCurrencyFormat, submissionVM, updateWizardData]);

    const handleConfirmProvidedDetailsChange = useCallback((value) => {
        updateProvidedDetailsConfirmation(value);
    }, []);

    const onPrevious = useCallback(() => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (state.showContentsReplacementCostReferral === true) {
            _.set(newSubmissionVM.value, CONTENTS_REPLACEMENT_COST_OVERRID_PATH, null);
            _.set(newSubmissionVM.value, VALUABLES_REPLACEMENT_COST_PATH, null);
        } else if (state.showRebuildCostReferral === true) {
            _.set(newSubmissionVM.value, REBUILDING_COST_CALC_OVERRIDE_PATH, null);
        }
        _.set(newSubmissionVM.value, IS_REFERRED_OFFLINE_PATH, false);
        updateWizardData(newSubmissionVM);
        return newSubmissionVM;
    }, [
        viewModelService,
        submissionVM,
        updateWizardData,
        state
    ]);

    const getRicsPropertyBuildValue = currencyFormatter
        .formatCurrency(rebuildingCostFromService);
    const getRicsContentValue = currencyFormatter
        .formatCurrency(contentsCostFromService);

    const isPriorClaimsValid = useCallback(() => {
        const cover = _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables');
        const priorClaims = _.get(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM');
        if (priorClaims.length > 0 && cover === BUILDINGS_ONLY) {
            return false;
        }
        return true;
    }, [submissionVM]);

    const onNext = useCallback(async () => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        try {
            _.set(newSubmissionVM, IS_VALIDATION_NOT_REQUIRED_PATH, false);
            const homhomePropertyConstruction = _.get(newSubmissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction');
            _.set(newSubmissionVM, 'lobData.value.homeLine.coverables.homhomeProperty', { homhomePropertyConstruction });
            cleanNotUpdatedCoverages(newSubmissionVM);
            if (coverableValue === BUILDINGS_ONLY) {
                setIsMarketingQuoteLoadingView(true);
                const updatedDraftResponse = await LoadSaveService.updateDraftSubmission(
                    newSubmissionVM.value,
                    authHeader
                );
                if (_.get(updatedDraftResponse,
                    IS_MORE_THAN_5_BEDROOMS_AND_TIER_1_ERROR_PATH) === true) {
                    handleUWError({}, newSubmissionVM.value.quoteID);
                    return false;
                }
                const saveAndQuotePromise = await LoadSaveService.saveAndQuoteSubmission(
                    updatedDraftResponse,
                    authHeader
                );
                const someSecondsDelayPromise = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, MARKETING_LOADING_VIEW_DISPLAY_TIME_MS);
                });
                const results = await Promise.all([saveAndQuotePromise, someSecondsDelayPromise]);
                const [submissionVMResult] = results;
                newSubmissionVM.value = submissionVMResult;
                setIsMarketingQuoteLoadingView(false);
            } else {
                newSubmissionVM.value = await LoadSaveService.updateDraftSubmission(
                    newSubmissionVM.value,
                    authHeader
                );
            }
            if (isPriorClaimsValid() === false) {
                handleUWError({}, newSubmissionVM.value.quoteID);
            }
            if (_.get(newSubmissionVM.value,
                IS_MORE_THAN_5_BEDROOMS_AND_TIER_1_ERROR_PATH) === true) {
                handleUWError({}, newSubmissionVM.value.quoteID);
            }
        } catch (error) {
            pushFormStepErrorInfo(submissionVM, QB_STEPS.PROPERTY_CONSTRUCTION, error);
            setIsMarketingQuoteLoadingView(false);
            handleError(error, submissionVM.value.quoteID);
            return false;
        }
        return newSubmissionVM;
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        viewModelService,
        coverableValue,
        setIsMarketingQuoteLoadingView,
        handleError,
        pushFormStepErrorInfo,
        handleUWError,
        isPriorClaimsValid,
        cleanNotUpdatedCoverages
    ]);

    const onHelpGuideClick = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/help-and-advice/calculating-home-contents/';
        pushLinkClickInfo(translator(messages.helpfulGuide), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        yourContentsContainer: {
            visible: coverages.contentsInput && state.showYourContentsContainer
        },
        yourBuildingsContainer: {
            visible: coverages.buildingsInput && state.showYourBuildingsContainer
        },
        paymentOptionsContainer: {
            visible: state.showPaymentContainer
        },
        contentsCostContainer: {
            visible: state.showContentsReplacementCost && coverages.contentsInput
        },
        confirmingProvidedDetailsContainer: {
            visible: state.showPaymentContainer
        },
        contentsReplacementCostInput: {
            onBlur: handleContentsReplacementCostChange,
            onValueChange: handleCurrencyChange,
            placeholder: translator(messages.enterValuePlaceholder)
        },
        contentsReplacementCostInfoRicsUnavailableContainer: {
            visible: !isRicsServiceForContentsCostAvailable
        },
        valuablesReplacementCostContainer: {
            visible: state.showValuablesReplacementCost && coverages.contentsInput
        },
        yearOfContentsContainer: {
            visible: state.showYearOfContents,
            disabled: disableYearOfContents
        },
        propertyRebuildCostContainer: {
            visible: state.showPropertyRebuildCost && coverages.buildingsInput
        },
        propertyRebuildCostInput: {
            onBlur: handlePropertyRebuildCostChange,
            onValueChange: handleCurrencyChange,
            placeholder: translator(messages.enterValuePlaceholder)
        },
        propertyRebuildCostInfoContainer: {
            visible: isRicsServiceForRebuildCostAvailable
        },
        propertyRebuildCostCalculationLink: {
            visible: isRicsServiceForRebuildCostAvailable
        },
        contentsReplacementCostInfoRicsContainer: {
            visible: isRicsServiceForContentsCostAvailable
        },
        propertyRebuildCostInfoRicsContainer: {
            visible: isRicsServiceForRebuildCostAvailable
        },
        propertyRebuildCostInfoRicsUnavailableContainer: {
            visible: !isRicsServiceForRebuildCostAvailable
        },
        estimateContentsCostRicsUnavailableInfo5: {
            visible: isRicsServiceForRebuildCostAvailable
        },
        estimateContentsCostRicsUnavailableInfo6: {
            visible: isRicsServiceForRebuildCostAvailable
        },
        propertyRebuildCostRicsavailableContainerInfo: {
            visible: isRicsServiceForRebuildCostAvailable
        },
        propertyRebuildCostRicsUnavailableContainerInfo: {
            visible: !isRicsServiceForRebuildCostAvailable
        },
        yearOfBuildingsContainer: {
            visible: state.showYearOfBuildings
        },
        paymentOptions: {
            value: _.get(submissionVM.value, PAYMENT_METHOD_PATH),
            onValueChange: handlePaymentOptionsChange
        },
        referralMessageContainer: {
            visible: (state.showContentsReplacementCostReferral || state.showRebuildCostReferral)
        },
        referralMessage: {
            title: translator(messages.referralTitle),
            description: translator(messages.referralDescription),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        confirmingProvidedDetailsCheckbox: {
            value: providedDetailsConfirmation,
            onValueChange: handleConfirmProvidedDetailsChange
        },
        tenantsInteriorDecorationsCoverContainer: {
            visible: interiorDecorations.showCover
        },
        interiorDecorationCoverInput: {
            onValueChange: handleInteriorDecorationsCoverChange
        },
        interiorDecorationsLimitExceeded: {
            visible: interiorDecorations.showCostExceededLimit,
            onValueChange: handleInteriorDecorationExceededCostChange,
            value: interiorDecorations.showCoverInput
        },
        costOfInteriorDecorationsInput: {
            onValueChange: handleInteriorDecorationsSumInsuredChange,
            visible: interiorDecorations.showCoverInput
        },
        propertyRebuildCostInfo2: {
            content: getRicsPropertyBuildValue
        },
        estimateContentsCostRicsUnavailableInfo2: {
            content: getRicsContentValue
        },
        estimateContentsCostRicsUnavailableDescInfo1: {
            onClick: onHelpGuideClick
        },
        helpguidelink1: {
            onClick: onHelpGuideClick
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            sectionComponent: SectionComponent,
            referralMessage: ReferralMessage,
            systemDownErrorMessage: SystemDownMessage,
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid || !providedDetailsConfirmation
                || _.get(submissionVM.value, IS_REFERRED_OFFLINE_PATH) === true || isLoading}
            skipWhen={initialValidation}
            showCancel={false}
            nextLabel={commonMessages.continue}
            previousLabel={commonMessages.back}
            showPrevious
            onPrevious={onPrevious}
            showNext
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PropertyConstructionPage.propTypes = wizardProps;
export default PropertyConstructionPage;
