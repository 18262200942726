import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
    ViewModelForm,
    ViewModelServiceContext
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { EntityUtil } from 'gw-portals-util-js';
import { AddressSearch } from 'nfum-capability-address-react';
import metadata from './ClaimsHistory.metadata.json5';
import styles from './ClaimsHistory.module.scss';
import './ClaimsHistory.messages';

function ClaimsHistory(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM,
        updateWizardData
    } = props;
    const MAX_PRIOR_CLAIMS_LENGTH = 10;
    const MAX_PRIOR_CLAIM_YEAR_DATE = 5;
    const maxDate = moment();
    const minDate = moment().add(-MAX_PRIOR_CLAIM_YEAR_DATE, 'year');

    const writeValue = useCallback(
        () => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onIsClaimAndInsuredLocationTheSameChange = useCallback((isTheSame, index) => {
        if (isTheSame) {
            _.set(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].claimLocation`, undefined);
        } else {
            _.set(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].claimLocation`, {
                country: 'GB'
            });
        }
        _.set(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].isClaimAndInsuredLocationTheSame`, isTheSame);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const removePriorClaim = useCallback((index) => {
        const priorClaims = _.get(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM');
        priorClaims.splice(index, 1);
        _.set(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM', priorClaims);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const addAnotherPriorClaim = useCallback(() => {
        const priorClaims = _.get(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM');
        priorClaims.push({
            tempID: EntityUtil.nextId(),
            claimLocation: {
                country: 'GB'
            }
        });
        _.set(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM', priorClaims);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const generateOverrides = useCallback(() => {
        const priorClaims = _.get(submissionVM, 'lobData.homeLine.priorClaims_NFUM.value', []);
        const overrides = priorClaims.map((occupation, index) => {
            return {
                [`lossDate${index}`]: {
                    minDate: minDate.toDate(),
                    maxDate: maxDate.toDate()
                },
                [`typeOfClaim${index}`]: {
                    value: _.get(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].typeOfClaim`)
                },
                [`status${index}`]: {
                    value: _.get(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].status`)
                },
                [`isClaimAndInsuredLocationTheSame${index}`]: {
                    value: _.get(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].isClaimAndInsuredLocationTheSame`),
                    onValueChange: (value) => onIsClaimAndInsuredLocationTheSameChange(value, index)
                },
                [`addressContainer${index}`]: {
                    visible: _.get(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].isClaimAndInsuredLocationTheSame`) === false,
                    value: _.get(submissionVM, `lobData.homeLine.priorClaims_NFUM.children[${index}].claimLocation`),
                    onAddressChanged: (newAddressVM) => {
                        _.set(submissionVM, `lobData.value.homeLine.priorClaims_NFUM[${index}].claimLocation`, newAddressVM.value);
                        updateWizardData(submissionVM);
                    }
                },
                [`removeButton${index}`]: {
                    onClick: () => removePriorClaim(index)
                },
                [`addAnotherButton${index}`]: {
                    visible: index === priorClaims.length - 1
                        && priorClaims.length < MAX_PRIOR_CLAIMS_LENGTH,
                    disabled: !_.get(submissionVM, `lobData.homeLine.priorClaims_NFUM.children[${index}]`).aspects.subtreeValid,
                    onClick: () => addAnotherPriorClaim()
                },
            };
        });

        return Object.assign({}, ...overrides);
    }, [
        submissionVM,
        onIsClaimAndInsuredLocationTheSameChange,
        removePriorClaim,
        addAnotherPriorClaim,
        updateWizardData,
        MAX_PRIOR_CLAIMS_LENGTH,
        maxDate,
        minDate
    ]);

    const onIsPriorClaimChange = useCallback((isPriorClaim) => {
        if (isPriorClaim) {
            _.set(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM', [
                {
                    tempID: EntityUtil.nextId(),
                    claimLocation: {
                        country: 'GB'
                    }
                }
            ]);
        } else {
            _.set(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM', []);
        }
        _.set(submissionVM, 'lobData.value.homeLine.isPriorClaim_NFUM', isPriorClaim);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const overrideProps = {
        isAnyClaim: {
            onValueChange: onIsPriorClaimChange,
            disabled: _.get(submissionVM, 'lobData.homeLine.priorClaims_NFUM.value', []).length > 0
                && submissionVM.lobData.homeLine.priorClaims_NFUM.children
                    ?.find((priorClaim) => priorClaim.aspects.subtreeValid === true) !== undefined
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            addressSearch: AddressSearch
        }
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ClaimsHistory.propTypes = wizardProps;
export default ClaimsHistory;
