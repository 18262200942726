import { GoogleExperiment as GoogleExperimentJS } from 'gw-portals-segmentation-js';
import { service } from '@jutro/services';

export const GOOGLE_EXPERIMENT_SERVICE_ID = 'GoogleExperiment';

export default class GoogleExperimentImpl {
    constructor() {
        this.GoogleExperiment = new GoogleExperimentJS();
    }

    getExperimentValue(experimentID) {
        return this.GoogleExperiment.getExperimentValue(experimentID);
    }
}

export const GoogleExperiment = (
    service(GOOGLE_EXPERIMENT_SERVICE_ID)(GoogleExperimentImpl)
);
