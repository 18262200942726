export default [
    {
        stepId: 0,
        title: {
            id: 'portal.wizardStep.Policy summary',
            defaultMessage: 'Policy summary',
        },
        relatedWizardPaths: ['/policy-summary']
    },
    {
        stepId: 1,
        title: {
            id: 'portal.wizardStep.Payment',
            defaultMessage: 'Payment',
        },
        relatedWizardPaths: ['/payment', '/payment-status', '/payment-unsuccessful']
    },
    {
        stepId: 2,
        title: {
            id: 'portal.wizardStep.Direct debit',
            defaultMessage: 'Direct Debit',
        },
        relatedWizardPaths: ['/direct-debit']
    },
    {
        stepId: 3,
        title: {
            id: 'portal.wizardStep.Payment confirmation',
            defaultMessage: 'Payment confirmation',
        },
        relatedWizardPaths: ['/payment-status', '/success']
    }
];
