import React, {
    useEffect, useState, useContext, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { NfumLoader } from 'nfum-components-platform-react';
import { Wizard } from 'gw-portals-wizard-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { QuoteInfoComponentContext } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from 'gw-portals-util-js';
import { messages as commonMessages } from 'nfum-platform-translations';
import { AppContext } from 'nfum-portals-utils-react';
import { useTranslator } from '@jutro/locale';
import { useQuoteCms } from 'nfum-cms-react';
import NghQuoteInfo from './components/NghQuoteInfo/NghQuoteInfo';
import wizardConfig from './config/ngh-quoted-wizard-config.json5';
import OnlineSupport from './components/OnlineSupport/OnlineSupport';
import NGHContext from './NGHContext';
import RouterConfirmationModal from './components/RouterConfirmationModal/RouterConfirmationModal';

const PATH_TO_MOCK_DATA = 'quote.ngh';

const MOCK_DATA_TO_REMOVE = [
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
];

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        ...MOCK_DATA_TO_REMOVE
    );
}
function NGHQuotedWizard(props) { /* NOSONAR: pure declarative usage */
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;
    const [isPaymentSucceed, setIsPaymentSucceed] = useState(false);
    const [isExistingCustomer] = useState(true);
    const { setIsQuoteRetrieval } = useContext(AppContext);
    setIsQuoteRetrieval(true);
    const [isQuoteEmailSent, setIsQuoteEmailSent] = useState(false);
    const [costInLastEmailSent, setCostInLastEmailSent] = useState(0);
    const { showAlert, showModal } = useModal();
    const [windowResized, setWindowResized] = useState(false);
    const translator = useTranslator();
    const cancelAction = useMemo(() => ({
        title: translator(commonMessages.wantToCancel),
        message: translator(commonMessages.navigateAwayMessage),
        status: 'warning',
        icon: 'gw-error-outline',
        confirmButtonText: translator(commonMessages.yes),
        cancelButtonText: translator(commonMessages.close)
    }), [translator]);
    const {
        match: { params: { quoteNumber } }
    } = props;
    const { marketingInfo, setMarketingInfo } = useContext(AppContext);
    const { getCmsContentForQuoteJourney } = useQuoteCms(marketingInfo, setMarketingInfo);
    const productCodes = Object.freeze({
        BESPOKE: 'bespoke',
        NGH: 'ngh',
        COMMON: 'common'
    });

    const getAndSaveCmsData = useCallback((modelData) => {
        const isBespokeJourney = modelData
            .isBespokeHomeInsurance_NFUM;
        getCmsContentForQuoteJourney(isBespokeJourney
            ? productCodes.BESPOKE
            : productCodes.NGH);
    }, [getCmsContentForQuoteJourney, productCodes]);

    const handleResize = () => {
        setWindowResized(true);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (!windowResized && viewModelService) {
            if (_.has(location, 'state.submission')) {
                const viewModelContext = {
                    AccountEmailRequired: true,
                    AccountDOBRequired: true
                };
                setShouldSkipValidSteps(true);
                const { submission } = location.state;
                Promise.resolve(submission).then((response) => {
                    getAndSaveCmsData(response);
                    // eslint-disable-next-line react/destructuring-assignment
                    const submissionVM = viewModelService.create(
                        removeMockData(response),
                        'pc',
                        'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                        viewModelContext
                    );
                    setInitialSubmission(submissionVM);
                    setIsLoading(false);
                });
            } else {
                history.push(`/quote/${quoteNumber}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCancel = useCallback(
        (wizardProps) => {
            const { wizardSnapshot, wizardData, param } = wizardProps;
            if (param?.includes('/contact-us') || param?.includes('/service-unavailable') || param?.includes('/successful-exit')) {
                return true;
            }
            const latestSnapshot = wizardData.aspects.subtreeValid && wizardData.aspects.valid
                ? wizardData
                : wizardSnapshot;

            const periodStatus = _.get(latestSnapshot, 'baseData.periodStatus.value.code');
            if (periodStatus === 'Bound') {
                // allow transition once the submission is bound
                return true;
            }
            return showModal(<RouterConfirmationModal {...cancelAction} />).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                let serverCall = LoadSaveService.updateDraftSubmission;

                if (periodStatus === 'Quoted') {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const wizardDataFromSnapshot = _.get(latestSnapshot, 'value');
                const dataToSave = MockUpUtil.setMockData(
                    wizardDataFromSnapshot,
                    PATH_TO_MOCK_DATA
                );
                setIsLoading(true);

                serverCall(dataToSave)
                    .then(() => {
                        history.push('/');
                    })
                    .catch(() => {
                        if (history.location.pathname !== '/quote-ngh/prequalification') {
                            showAlert({
                                title: commonMessages.unableToSaveQuote,
                                message: commonMessages.unableToSaveQuoteMessage,
                                status: 'error',
                                icon: 'gw-error-outline',
                                confirmButtonText: commonMessages.ok
                            }).catch(_.noop);
                        }
                        history.push('/');
                    });
                return true;
            }, _.noop);
        },
        [cancelAction, history, showAlert, showModal]
    );

    if (isLoading) {
        return <NfumLoader loaded={!isLoading} />; /* NOSONAR: pure declarative usage */
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <NGHContext.Provider
            value={{
                isPaymentSucceed,
                setIsPaymentSucceed,
                isExistingCustomer,
                isQuoteEmailSent,
                setIsQuoteEmailSent,
                costInLastEmailSent,
                setCostInLastEmailSent
            }}
        >
            <QuoteInfoComponentContext.Provider value={NghQuoteInfo}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialSubmission}
                    onCancel={handleCancel}
                    skipCompletedSteps={shouldSkipValidSteps}
                />
                <OnlineSupport />
            </QuoteInfoComponentContext.Provider>
        </NGHContext.Provider>
    );
}

NGHQuotedWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({})
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default NGHQuotedWizard;
