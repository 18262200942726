import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteReference: {
        id: 'quoteandbind.wizardStep.quoteReference',
        defaultMessage: 'Quote Reference: '
    },
    step: {
        id: 'quoteandbind.wizardStep.step',
        defaultMessage: 'step '
    },
    stepOf: {
        id: 'quoteandbind.wizardStep.stepOf',
        defaultMessage: ' of '
    },
    beforeYouStartInfo: {
        id: 'quoteandbind.wizardStep.additionalInfo.beforeYouStart',
        defaultMessage: 'It only takes around'
    },
    beforeYouStart5to10MinutesInfo: {
        id: 'quoteandbind.wizardStep.additionalInfo.beforeYouStart5to10MinutesInfo',
        defaultMessage: ' 5-10 minutes '
    },
    beforeYouStartLetsStartedInfo: {
        id: 'quoteandbind.wizardStep.additionalInfo.beforeYouStartLetsStartedInfo',
        defaultMessage: 'to get an insurance quote for cover that’s right for you. Let’s get started.'
    }
});
