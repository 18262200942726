/* eslint-disable */
import React from 'react';
import { RadioButtonCardField } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import styles from './NfumRadioButtonCardField.module.scss';

function NfumRadioButtonCardField(props) {
    const {
        description
    } = props

    return (
        <div>
            <NfumFieldLabel
                {...props}
            />
            { description != null &&
                <div className={styles.description}>{description}
                </div>
            }
            <RadioButtonCardField
                {...props}
                className={styles.radioButtonCard}
                hideLabel="true"
                layout="landscape"
            />
        </div>
    );
}

export default NfumRadioButtonCardField;
