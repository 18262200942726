import React, {
    useCallback
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import styles from './SecurityCheckPage.module.scss';
import metadata from './SecurityCheckPage.metadata.json5';
import './SecurityCheckPage.messages';

const SecurityCheckPage = () => {
    const history = useHistory();

    const onClose = useCallback(() => {
        history.push('/');
    }, [history]);

    const overrideProps = {
        homepageButton: {
            onClick: onClose
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default SecurityCheckPage;
