import { useContext } from 'react';
import {
    TagManagerBase,
    FORM_REQUEST_TYPE,
    AppContext
} from 'nfum-portals-utils-react';
import NGHContext from '../NGHContext';

function useTagManager() {
    const { isQuoteRetrieval } = useContext(AppContext);
    const { isExistingCustomer } = useContext(NGHContext);

    const pushFormStepInfo = (submissionVM, step) => {
        const journeyContext = isQuoteRetrieval
            ? FORM_REQUEST_TYPE.quoteRetrieval
            : FORM_REQUEST_TYPE.quote;
        TagManagerBase.pushFormStepInfo(submissionVM, isExistingCustomer, journeyContext, step);
    };

    const pushFormConfirmationInfo = (submissionVM, step) => {
        const journeyContext = isQuoteRetrieval
            ? FORM_REQUEST_TYPE.quoteRetrieval
            : FORM_REQUEST_TYPE.quote;
        TagManagerBase.pushFormConfirmationInfo(
            submissionVM,
            isExistingCustomer,
            journeyContext,
            step
        );
    };

    const pushFormStepErrorInfo = (submissionVM, step, error, errorInfo = null) => {
        const journeyContext = isQuoteRetrieval
            ? FORM_REQUEST_TYPE.quoteRetrieval
            : FORM_REQUEST_TYPE.quote;
        TagManagerBase.pushFormStepErrorInfo(
            submissionVM,
            isExistingCustomer,
            journeyContext,
            step,
            error,
            errorInfo
        );
    };

    return {
        pushFormStepInfo,
        pushFormConfirmationInfo,
        pushFormStepErrorInfo,
        pushAccordionOpenInfo: TagManagerBase.pushAccordionOpenInfo,
        pushLinkClickInfo: TagManagerBase.pushLinkClickInfo,
        pushRelativeLinkClickInfo: TagManagerBase.pushRelativeLinkClickInfo,
        pushFileDownloadInfo: TagManagerBase.pushFileDownloadInfo
    };
}

export default useTagManager;
