import { defineMessages } from 'react-intl';

export default defineMessages({
    highValueBicycleTitle: {
        id: 'quoteandbind.ngh.views.highValueBicycle.highValueBicycleTitle',
        defaultMessage: 'Bicycles'
    },
    highValueBicycleCtaDescription: {
        id: 'quoteandbind.ngh.views.highValueBicycle.highValueBicycleCtaDescription',
        defaultMessage: 'High valued bicycles outside the home worth '
    },
    addItem: {
        id: 'quoteandbind.ngh.views.highValueBicycle.addItem',
        defaultMessage: 'Add item'
    },
    highValueBicycleCategoryColumn: {
        id: 'quoteandbind.ngh.views.highValueBicycle.highValueBicycleCategoryColumn',
        defaultMessage: 'Category'
    },
    highValueBicycleDescriptionColumn: {
        id: 'quoteandbind.ngh.views.highValueBicycle.highValueBicycleDescriptionColumn',
        defaultMessage: 'Description'
    },
    highValueBicycleValueColumn: {
        id: 'quoteandbind.ngh.views.highValueBicycle.highValueBicycleValueColumn',
        defaultMessage: 'Value'
    },
    delete: {
        id: 'quoteandbind.ngh.views.highValueBicycle.delete',
        defaultMessage: 'Delete'
    },
    highValueBicycleReplacementColumn: {
        id: 'quoteandbind.ngh.views.highValueBicycle.highValueBicycleReplacementColumn',
        defaultMessage: 'Replacement cost'
    },
    bicycleDescription: {
        id: 'quoteandbind.ngh.views.highValueBicycle.bicycleDescription',
        defaultMessage: ' each. (Note: Bicycles worth up to '
    },
    includedInPolicy: {
        id: 'quoteandbind.ngh.views.highValueBicycle.includedInPolicy',
        defaultMessage: ' are included in your policy)'
    },
    moreThan: {
        id: 'quoteandbind.ngh.views.highValueBicycle.moreThan',
        defaultMessage: 'more than '
    },
    saveChanges: {
        id: 'quoteandbind.ngh.views.highValueBicycle.saveChanges',
        defaultMessage: 'Save Changes'
    },
    edit: {
        id: 'quoteandbind.ngh.views.highValueBicycle.edit',
        defaultMessage: 'Edit '
    },
    ebikes: {
        id: 'quoteandbind.ngh.views.highValueBicycle.ebikes',
        defaultMessage: 'This includes electronic bicycles (e-bikes)'
    },
    bicycle: {
        id: 'quoteandbind.ngh.views.highValueBicycle.bicycle',
        defaultMessage: 'Bicycle'
    },
    pedalSecurityDescription: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityDescription',
        defaultMessage: 'Theft of a bike is only covered if it is stolen from a locked building, or it is locked to a secure object.  For bikes up to £1,000 this can be any lock.  For bikes over £1,000 a Bronze, Silver or Gold Sold Secure lock is required, depending on the value of your bike:'
    },
    pedalSecurityLockPriceForBronze: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityLockPriceForBronze',
        defaultMessage: '£1,000 - £1,999'
    },
    pedalSecurityLockBronze: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityLockBronze',
        defaultMessage: ' (BRONZE), '
    },
    pedalSecurityLockPriceForsSilver: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityLockPriceForsSilver',
        defaultMessage: '£2,000 - £2,999'
    },
    pedalSecurityLockSilver: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityLockSilver',
        defaultMessage: ' (SILVER), '
    },
    pedalSecurityLockPriceForGold: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityLockPriceForGold',
        defaultMessage: '£3,000+'
    },
    pedalSecurityLockGold: {
        id: 'endorsement.ngh.views.highValueBicycle.pedalSecurityLockGold',
        defaultMessage: ' (GOLD)'
    }
});
