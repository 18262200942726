import TagManagerBase from '../services/TagManagerBase';

function useCommonTagManager() {
    return {
        pushAccordionOpenInfo: TagManagerBase.pushAccordionOpenInfo,
        pushLinkClickInfo: TagManagerBase.pushLinkClickInfo,
        pushRelativeLinkClickInfo: TagManagerBase.pushRelativeLinkClickInfo,
        pushFileDownloadInfo: TagManagerBase.pushFileDownloadInfo
    };
}

export default useCommonTagManager;
