import React, { useCallback, useContext } from 'react';
import { pageTemplateProps } from 'gw-portals-wizard-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import {
    NfumWizardPageTemplate,
    ProgressBar,
    SessionTimeOut
} from 'nfum-portals-wizard-react';
import NGHContext from './NGHContext';

function NGHWizardPageTemplate(props) {
    const {
        children, acknowledgeError, errorsForStep, ...otherProps
    } = props;
    const { isNavigationDisabled } = useContext(NGHContext);

    const renderWizardHeader = useCallback(
        () => {
            return (
                <ProgressBar isNavigationDisabled={isNavigationDisabled} />
            );
        },
        [isNavigationDisabled]
    );

    const renderSessionTimedOut = useCallback(
        () => {
            return (
                <SessionTimeOut />
            );
        },
        []
    );
    const showSessionTimedOut = window.localStorage.getItem('sessionTimeOut') === 'true';

    return (
        <NfumWizardPageTemplate
            showContextComponent
            renderContextComponent={renderWizardHeader}
            showSessionTimedOut={showSessionTimedOut}
            renderSessionTimedOut={renderSessionTimedOut}
            errorsForStep={errorsForStep}
            {...otherProps}
        >
            <WizardSingleErrorComponent
                acknowledgeError={acknowledgeError}
                issuesList={errorsForStep}
            />
            {children}
        </NfumWizardPageTemplate>
    );
}

NGHWizardPageTemplate.propTypes = pageTemplateProps;
export default NGHWizardPageTemplate;
