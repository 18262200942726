import React, { useCallback, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import useCurrency from '../../../nfum-capability-quoteandbind-ngh-react/hooks/useCurrency';
import metadata from './FineArtsSummaryItem.metadata.json5';
import messages from './FineArtsSummaryItem.messages';
import styles from './FineArtsSummaryItem.module.scss';

function FineArtsSummaryItem(props) {
    const {
        fineArt
    } = props;
    const translator = useContext(TranslatorContext);
    const currencyFormatter = useCurrency();

    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });

    const getItemFineArtType = useCallback((type) => {
        return translator({
            id: `typekey.FineArtType_NFUM.${type}`,
            defaultMessage: type
        });
    }, [translator]);

    const getHeaderDescription = useCallback((fineArtItem) => {
        let description = '';
        const itemType = _.get(fineArtItem, 'itemType');
        if (itemType === category.FINE_ART) {
            description = `${getItemFineArtType(fineArtItem.fineArtType)}, ${fineArtItem.description}`;
        } else if (itemType === category.WINES) {
            description = translator(messages.wineCollection);
        } else {
            description = `${fineArt?.collectionType}`;
        }
        return description;
    }, [fineArt, category, getItemFineArtType, translator]);

    const generateOverrides = useCallback(() => {
        if (!fineArt) return Object.assign({});
        const fineArtsAddedOverrides = fineArt.valuables
            .map((item, ind) => {
                return {
                    [`fineArtsItem${ind}`]: {
                        fineArt: item,
                        index: ind
                    },
                    [`headerDescription${ind}`]: {
                        content: getHeaderDescription(item)
                    },
                    [`agreedValue${ind}`]: {
                        visible: [category.FINE_ART].includes(_.get(item, 'itemType')),
                        value: currencyFormatter.formatCurrency(`${_.get(item, 'agreedValue')}`, true),
                    }
                };
            });

        return Object.assign({},
            ...fineArtsAddedOverrides);
    }, [fineArt, getHeaderDescription, category, currencyFormatter]);

    const overrideProps = {
        addedItems: {
            data: fineArt.valuables
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={fineArt}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FineArtsSummaryItem.propTypes = wizardProps;
export default FineArtsSummaryItem;
