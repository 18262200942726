import React, { useState, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import cx from 'classnames';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { Button } from '@jutro/components';
import PropTypes from 'prop-types';
import styles from './InfoPanel.module.scss';
import messages from './InfoPanel.messages';

function InfoPanel(props) {
    const {
        isCollapsible,
        collapsibleButtonText,
        notCollapsibleButtonText,
        contentMetadata,
        contentJsx,
        type
    } = props;
    const translator = useContext(TranslatorContext);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const overrideProps = {

    };
    const resolvers = {
        resolveClassNameMap: styles,
    };

    const showInfo = () => {
        setIsInfoVisible(true);
    };

    const hideInfo = () => {
        setIsInfoVisible(false);
    };

    const infoPanelStyle = cx(styles.infoPanel, {
        [styles.infoPanelExtended]: type === 'extended'
    });

    return (
        <div>
            { isCollapsible && !isInfoVisible && (
                <Button
                    type="text"
                    className={styles.collapsibleButtonCls}
                    onClick={showInfo}
                >
                    {collapsibleButtonText}
                </Button>
            )}
            { !isCollapsible || isInfoVisible && (
                <Button
                    type="text"
                    className={styles.collapsibleButtonCls}
                    onClick={hideInfo}
                >
                    {notCollapsibleButtonText}
                </Button>
            )}
            { (isInfoVisible || !isCollapsible) && (
                <div className={infoPanelStyle}>
                    { contentJsx && (
                        <div>{contentJsx}</div>
                    )}
                    { contentMetadata?.componentContent && (
                        <ViewModelForm
                            uiProps={contentMetadata.componentContent}
                            overrideProps={overrideProps}
                            classNameMap={resolvers.resolveClassNameMap}
                        />
                    )}
                    { isCollapsible && (
                        <Button
                            type="text"
                            className={styles.collapsibleButton}
                            onClick={hideInfo}
                            icon="gw-close"
                            iconPosition="right"
                        >
                            {translator(messages.close)}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

InfoPanel.propTypes = {
    isCollapsible: PropTypes.bool.isRequired,
    collapsibleButtonText: PropTypes.string.isRequired,
};

export default InfoPanel;
