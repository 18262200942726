import {
    Button, Icon, ModalNext
} from '@jutro/components';
import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import styles from './RouterConfirmationModal.module.scss';

const RouterConfirmationModal = ({
    isOpen,
    onResolve,
    title,
    message,
    confirmButtonText,
    cancelButtonText,
    icon
}) => {
    const [overlay, setOverLay] = useState();
    const ref = useRef();
    const handleOverlayClick = useCallback((event) => {
        if ([...event.target.classList].includes('exclude-confirm-btn')) {
            return;
        }
        onResolve('cancel');
    }, [onResolve]);

    useEffect(() => {
        setOverLay(document.getElementsByClassName('overlayCancel'));
        return () => {
            if (overlay) {
                overlay[0].removeEventListener('click', handleOverlayClick);
            }
        };
    }, [handleOverlayClick, overlay]);

    useEffect(() => {
        if (overlay) {
            overlay[0].addEventListener('click', handleOverlayClick);
        }
    }, [handleOverlayClick, onResolve, overlay]);

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={() => onResolve('cancel')}
            className={`${styles.modalNext} overlayCancel`}
        >
            <div className={styles.modal}>

                <div className={`${styles.modalHeader} ${styles.modalHeaderBorderBottom}`}>
                    <div className={styles.modalIconContainer}>
                        <Icon icon={icon} className={styles.modalIcon} />
                    </div>
                    <h5 className={styles.modalTitle}>
                        {title}
                    </h5>
                </div>
                <div className={styles.modalContent}>
                    <span>{message}</span>
                </div>
                <div className={styles.modalFooter}>
                    <Button className={styles.button} icon="gw-close" iconPosition="right" onClick={() => onResolve('cancel')}>{cancelButtonText}</Button>
                    <Button ref={ref} className="exclude-confirm-btn" icon="gw-keyboard-arrow-right" iconPosition="right" onClick={() => onResolve('confirm')} iconClassName="exclude-confirm-btn">
                        <span className="exclude-confirm-btn">{confirmButtonText}</span>
                    </Button>
                </div>
            </div>
        </ModalNext>
    );
};

export default RouterConfirmationModal;
