/* eslint-disable prefer-promise-reject-errors */
import _ from 'lodash';
import { getProxiedUrl, encodeFormData } from 'gw-portals-url-js';
import { OAuthService } from './OAuthServiceFactory';
import ERRORS from './AuthErrors';

class XCenterUserAuthenticationService extends OAuthService {
    constructor(authConfig) {
        // singleton
        if (XCenterUserAuthenticationService.instance) {
            if (!_.isEqual(authConfig, XCenterUserAuthenticationService.instance.authConfig)) {
                throw new Error('Attempt to create another XCenterUserAuthenticationService with a different configuration');
            }
            // eslint-disable-next-line no-constructor-return
            return XCenterUserAuthenticationService.instance;
        }
        super(authConfig);
        XCenterUserAuthenticationService.instance = this;
    }

    logout = async () => {
        const { url, endpoints } = this.authConfig;
        const xCenterSSOLogoutUrl = getProxiedUrl('/sso/logout');
        const authSolutionLogoutUrl = getProxiedUrl(`${url}${endpoints.logout}?redirect=${encodeURIComponent(xCenterSSOLogoutUrl)}`);
        const res = await fetch(authSolutionLogoutUrl, {
            mode: this.authConfig.logoutMode,
            credentials: 'same-origin'// add cookies
        });

        this.oAuthUtil.removeTokens();
        this.oAuthUtil.emitLogoutEvent();
        return { res };
    };

    login = async ({ username, password }) => {
        const oAuthState = this.oAuthUtil.getOAuthStateCode();
        if (!oAuthState) {
            await this.prepareLogin(true);
        }
        const formData = encodeFormData({
            username: username,
            password: password,
            client_id: 'uaa',
            state: this.oAuthUtil.getOAuthStateCode()
        });

        try {
            const res = await fetch(getProxiedUrl('sso/oauth/authorize'), {
                method: 'POST',
                credentials: 'same-origin', // add cookies
                headers: {
                    Accept: 'text/html',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData
            });

            if (res.status === 403 && res.statusText.includes('locked')) {
                throw new Error({
                    error: ERRORS.account_locked
                });
            }
            await this.loginWithCurrentCookies();
        } catch (err) {
            throw new Error({
                error: (err.error === 'account_locked' && ERRORS.account_locked) || ERRORS.login_failure
            });
        }
    };
}

// EXPORT
export default (oAuthConf) => {
    return new XCenterUserAuthenticationService(oAuthConf);
};
