import { useCallback } from 'react';
import { useIntl } from 'react-intl';

function useCurrency() {
    const intl = useIntl();
    const CHARACTERS_TECHNICAL_LIMIT = 17;

    const formatCurrency = useCallback((cost, isDecimalPart = false, isSymbol = true) => {
        const config = {
            style: 'currency',
            currency: 'GBP'
        };
        if (!isDecimalPart) {
            config.minimumFractionDigits = 0;
            config.maximumFractionDigits = 0;
        }

        const formattedCost = intl.formatNumber(cost, config);
        if (isSymbol === false) {
            return formattedCost.replace('£', '');
        }
        return formattedCost;
    }, [intl]);

    const isValidFormat = useCallback((inputCost) => {
        if (!inputCost || inputCost.length > CHARACTERS_TECHNICAL_LIMIT) return true;
        const costWithoutCommas = inputCost.replaceAll(',', '');
        const isDecimalPart = costWithoutCommas.includes('.');
        const config = {
            style: 'currency',
            currency: 'GBP'
        };
        if (!isDecimalPart) {
            config.minimumFractionDigits = 0;
            config.maximumFractionDigits = 0;
        }
        const validFormat = intl.formatNumber(costWithoutCommas, config).replace('£', '');
        return inputCost === validFormat || inputCost === validFormat.replaceAll(',', '');
    }, [intl]);

    return {
        formatCurrency,
        isValidFormat
    };
}

export default useCurrency;
