import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'portal.pages.securityCheckPage.title',
        defaultMessage: 'We might not be able to give you a quote'
    },
    description: {
        id: 'portal.pages.securityCheckPage.description',
        defaultMessage: 'As a specialist insurance provider we don’t provide cover for all UK households.'
    },
    additionalText1: {
        id: 'portal.pages.securityCheckPage.additionalText1',
        defaultMessage: 'You might also like to browse the Association of British Insurers (ABI) '
    },
    listOfMembers: {
        id: 'portal.pages.securityCheckPage.listOfMembers',
        defaultMessage: 'list of members'
    },
    additionalText2: {
        id: 'portal.pages.securityCheckPage.additionalText2',
        defaultMessage: ', or use the British Insurance Brokers Association '
    },
    findBroker: {
        id: 'portal.pages.securityCheckPage.findBroker',
        defaultMessage: 'find a broker'
    },
    additionalText3: {
        id: 'portal.pages.securityCheckPage.additionalText3',
        defaultMessage: ' service. However, if you would still like to talk to us about getting a quote please feel free to call us on '
    },
    phoneNumber: {
        id: 'portal.pages.securityCheckPage.phoneNumber',
        defaultMessage: '0800 316 4661'
    },
    additionalText4: {
        id: 'portal.pages.securityCheckPage.additionalText4',
        defaultMessage: '.'
    },
    homepage: {
        id: 'portal.pages.securityCheckPage.homepage',
        defaultMessage: 'Homepage'
    },
});
