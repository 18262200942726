import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import metadata from './AddressInfo.metadata.json5';
import styles from './AddressInfo.module.scss';

const AddressInfo = (props) => {
    const {
        value: addressVM
    } = props;

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        addressLine1: {
            visible: !!addressVM?.addressLine1?.value
        },
        addressLine2: {
            visible: !!addressVM?.addressLine2?.value
        },
        addressLine3: {
            visible: !!addressVM?.addressLine3?.value
        },
        addressLine4: {
            visible: !!addressVM?.addressLine4_NFUM?.value
        },
        city: {
            visible: !!addressVM?.city?.value
        },
        county: {
            visible: !!addressVM?.county?.value
        },
        postalCode: {
            visible: !!addressVM?.postalCode?.value
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={addressVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

AddressInfo.propTypes = {
    value: PropTypes.shape({}).isRequired
};
export default AddressInfo;
