import { defineMessages } from 'react-intl';

export default defineMessages({
    homeOwnersQuote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ngh.Homeowners Insurance Quote1',
        defaultMessage: 'Homeowners Insurance Quote'
    },
    location: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ngh.Location1',
        defaultMessage: 'Location'
    }
});
