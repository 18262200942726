import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './DirectDebitGuarantee.metadata.json5';
import styles from './DirectDebitGuarantee.module.scss';

function DirectDebitGuarantee() {
    const baseUrl = window.location.origin;
    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        ddLogo: {
            src: `${baseUrl}/internal/ddLogo.png`
        }
    };

    return (
        <ViewModelForm
            overrideProps={overrideProps}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default DirectDebitGuarantee;
