import { defineMessages } from 'react-intl';

export default defineMessages({
    gatheringQuoteDetails: {
        id: 'quoteLoadingView.gatheringQuoteDetails',
        defaultMessage: 'Gathering your quote details',
    },
    updatingPrices: {
        id: 'quoteLoadingView.updatingPrices',
        defaultMessage: 'We are updating your prices.',
    },
});
