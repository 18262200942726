import { defineMessages } from 'react-intl';

export default defineMessages({
    enterAddress: {
        id: 'quoteandbind.views.contact-details.Begin entering your address',
        defaultMessage: 'Begin entering your address',
    },
    search: {
        id: 'quoteandbind.views.contact-details.Search',
        defaultMessage: 'Search',
    },
    enterManualAddress: {
        id: 'platform.widgets.findaddress.components.FindAddress.Enter your address manually',
        defaultMessage: 'Enter your address manually',
    },
    backToAddressLookup: {
        id: 'platform.widgets.findaddress.components.FindAddress.Back to Address Search',
        defaultMessage: 'Back to Address Search',
    }
});
