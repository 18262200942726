import { setComponentMapOverrides } from '@jutro/uiconfig';

import RetrieveQuote from './components/RetrieveQuote/RetrieveQuote';
import ExpiredQuote from './components/ExpiredQuote/ExpiredQuote';
import SaveNewQuote from './components/SaveNewQuote/SaveNewQuote';

import PreQualificationsPage from './pages/PreQualifications/PreQualificationsPage';
import YourDetailsPage from './pages/YourDetails/YourDetailsPage';
import PropertyDetailsPage from './pages/PropertyDetails/PropertyDetailsPage';
import PropertyConstructionPage from './pages/PropertyConstruction/PropertyConstructionPage';
import QuotePage from './pages/Quote/QuotePage';
import ValuablesPage from './pages/Valuables/ValuablesPage';
import DirectDebitPage from './pages/DirectDebit/DirectDebitPage';
import SuccessPage from './pages/Success/SuccessPage';
import PolicySummaryPage from './pages/PolicySummary/PolicySummaryPage';
import PaymentPage from './pages/Payment/PaymentPage';
import PaymentStatusPage from './pages/PaymentStatus/PaymentStatusPage';
import PaymentUnsuccessfulPage from './pages/PaymentUnsuccessful/PaymentUnsuccessfulPage';
import ReferralMessage from './components/ReferralMessageComponent/ReferralMessageComponent';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';

import NGHWizardPageTemplate from './NGHWizardPageTemplate';
import NGHQuotedWizardPageTemplate from './NGHQuotedWizardPageTemplate';
import CallOrContactInfo from './components/CallOrContactInfo/CallOrContactInfo';

setComponentMapOverrides(
    {
        PreQualificationsPage: { component: 'PreQualificationsPage' },
        YourDetailsPage: { component: 'YourDetailsPage' },
        PropertyDetailsPage: { component: 'PropertyDetailsPage' },
        PropertyConstructionPage: { component: 'PropertyConstructionPage' },
        QuotePage: { component: 'QuotePage' },
        PolicySummaryPage: { component: 'PolicySummaryPage' },
        NGHRetrieveQuote: { component: 'NGHRetrieveQuote' },
        NGHExpiredQuote: { component: 'NGHExpiredQuote' },
        NGHSaveNewQuote: { component: 'NGHSaveNewQuote' },
        ValuablesPage: { component: 'ValuablesPage' },
        NGHDirectDebitPage: { component: 'DirectDebitPage' },
        NGHTermsAndConditions: { component: 'TermsAndConditions' },
        NGHSuccessPage: { component: 'SuccessPage' },
        NGHPaymentPage: { component: 'PaymentPage' },
        NGHUnsuccessfulPaymentPage: { component: 'PaymentUnsuccessfulPage' },
        NHGPaymentStatusPage: { component: 'PaymentStatusPage' },
        NGHWizardPageTemplate: { component: 'NGHWizardPageTemplate' },
        NGHQuotedWizardPageTemplate: { component: 'NGHQuotedWizardPageTemplate' },
        ReferralMessage: { component: 'ReferralMessage' },
        referralMessage: { component: 'referralMessage' },
        callOrContactInfo: { component: 'callOrContactInfo' },
        CallOrContactInfo: { component: 'CallOrContactInfo' },
    },
    {
        PreQualificationsPage,
        YourDetailsPage,
        PropertyDetailsPage,
        PropertyConstructionPage,
        ValuablesPage,
        DirectDebitPage,
        TermsAndConditions,
        SuccessPage,
        PaymentPage,
        QuotePage,
        PolicySummaryPage,
        PaymentUnsuccessfulPage,
        PaymentStatusPage,
        NGHRetrieveQuote: RetrieveQuote,
        NGHExpiredQuote: ExpiredQuote,
        NGHSaveNewQuote: SaveNewQuote,
        NGHWizardPageTemplate,
        NGHQuotedWizardPageTemplate,
        ReferralMessage,
        referralMessage: ReferralMessage,
        callOrContactInfo: CallOrContactInfo,
        CallOrContactInfo
    }
);

export { default as NGHWizard } from './NGHWizard';
export { default as NGHQuotedWizard } from './NGHQuotedWizard';
export { default as useLobs } from './hooks/useLobs';
export { default as useTagManager } from './hooks/useTagManager';
