import { defineMessages } from 'react-intl';

export default defineMessages({
    loading: {
        id: 'quoteandbind.ngh.NfumTypeheadMultiSelectField.loading',
        defaultMessage: 'Loading...',
    },
    noOptions: {
        id: 'quoteandbind.ngh.NfumTypeheadMultiSelectField.noOptions',
        defaultMessage: 'No options',
    },
    typeMessage: {
        id: 'quoteandbind.ngh.NfumTypeheadMultiSelectField.typeMessage',
        defaultMessage: 'Type in the first two letters to start a search',
    },
   
});
