import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import metadata from './ExpiredQuote.metadata.json5';
import styles from './ExpiredQuote.module.scss';
import useTagManager from '../../hooks/useTagManager';
import messages from './ExpiredQuote.messages';

function ExpiredQuote({ expiredQuoteMessage }) {
    const { pushLinkClickInfo } = useTagManager();
    const translator = useContext(TranslatorContext);

    const onRequestCallback = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/contact-us/call-back/';
        pushLinkClickInfo(translator(messages.callbackCTA), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        expiredQuoteContentContainerTitle: {
            content: expiredQuoteMessage,
        },
        expiredQuoteContentContactContainerCallbackButton: {
            onClick: onRequestCallback
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ExpiredQuote.propTypes = {
    expiredQuoteMessage: PropTypes.string.isRequired,
};

export default ExpiredQuote;
