import React, {
    useEffect,
    useCallback
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { ModalNext } from '@jutro/components';
import metadata from './InactiveModal.metadata.json5';
import styles from './InactiveModal.module.scss';
import './InactiveModal.messages';
import Timer from '../Timer/Timer';

function InactiveModal(props) {
    const {
        isOpen,
        onResolve,
        logoutConfirmationInterval
    } = props;

    const overrideProps = {
        continueSessionButton: {
            onClick: () => onResolve({ type: 'choice-continue' })
        },
        inactiveModalTimer: {
            seconds: logoutConfirmationInterval * 60,
            onTimeEnd: () => onResolve({ type: 'choice-timeout' })
        }
    };
    const timedOut = useCallback(() => {
        onResolve({ type: 'choice-timeout' });
    }, [onResolve]);

    /** Added the timeout to force user logout when the brouwser
     *  is minimized and jstimer is stuck
     */
    useEffect(() => {
        const closeModalTimeoutId = setTimeout(timedOut, logoutConfirmationInterval * 60 * 1000);
        return () => window.clearTimeout(closeModalTimeoutId);
    }, [logoutConfirmationInterval, onResolve, timedOut]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            timer: Timer
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ViewModelForm
                className={styles.modalWrapper}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </ModalNext>
    );
}

export default InactiveModal;
