import { defineMessages } from 'react-intl';

export default defineMessages({
    startQuote: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Start Quote',
        defaultMessage: 'Start Quote',
    },
    retrieveQuote: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Retrieve Quote',
        defaultMessage: 'Retrieve Quote',
    },
    zipCodePlaceholder: {
        id: 'quoteandbind.directives.Enter ZIP Code',
        defaultMessage: 'Enter ZIP Code',
    },
});
