import React, { useCallback, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import appConfig from 'app-config';
import metadata from './OnlineAccountStatusRegistered.metadata.json5';
import styles from './OnlineAccountStatusRegistered.module.scss';
import AccessListItems from '../AccessListItems/AccessListItems';
import messages from './OnlineAccountStatusRegistered.messages';

function OnlineAccountStatusRegistered() {
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();
    const { env } = appConfig;

    const loginToDCSAccount = useCallback(() => {
        const backToDashboardURL = env.JUTRO_BACK_TO_DASHBOARD_PATH || '/';
        pushLinkClickInfo(translator(messages.loginButton), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    }, [pushLinkClickInfo, translator, env]);

    const overrideProps = {
        loginButton: {
            onClick: loginToDCSAccount
        }

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            accessListItems: AccessListItems
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default OnlineAccountStatusRegistered;
