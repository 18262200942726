import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { QuestionSetsParser } from 'gw-portals-questionsets-js';
import { QuestionSetUtils } from 'gw-portals-util-js';
import { useValidation } from 'gw-portals-validation-react';
import messages from './QuestionSetComponent.messages';
import QuestionSetComponent from './QuestionSetComponent';
import generateQuestionSetsPresentationMetadata from './generateQuestionSetsPresentationMetadata';

/**
 * @ignore
 * @memberof module:gw-portals-questionsets-ui
 *
 * @property {Object} value - value of the property being rendered
 * @property {string} path - the path where this component is attached
 * @property {function(newAnswers, pathToAnswers)} onValueChange - the
 *                                      function invoked when the value is changed
 * @property {function(isComponentValid, id)} onValidate - the
 *                                      function when validation is triggered
 * @property {string} labelPosition - the position for the question label
 * @property {string} id - the id for the component
 * @property {Object} title - the translation ready title for the question sets component
 *
 * @returns {ReactElement}
 */
function QuestionSetContainer({
    id,
    onValidate,
    onValueChange,
    value: aQuestionSetAnswersValue,
    path: pathToAnswers,
    labelPosition,
    title
}) {
    const translator = useTranslator();
    const {
        isComponentValid,
        onValidate: onValidationChange,
        registerComponentValidation
    } = useValidation(id);
    const [questionSetsMetadata, setQuestionSetsMetadata] = useState(null);

    useEffect(() => {
        import(
            /* webpackChunkName: "question-sets-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'question-sets-metadata'
        ).then((data) => {
            const { default: result } = data;
            setQuestionSetsMetadata(result);
        });
    }, []);

    const checkComponentValidation = useCallback(() => {
        return questionSetsMetadata ? true : null;
    }, [questionSetsMetadata]);

    useEffect(() => {
        registerComponentValidation(checkComponentValidation);
    }, [checkComponentValidation, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, path) => {
            if (value === undefined) {
                return;
            }

            const { code: questionSetCode, answers: questionSetAnswer } = aQuestionSetAnswersValue;
            const qsOrderedQuestions = questionSetsMetadata[questionSetCode].orderedQuestions;

            QuestionSetUtils.cleanDependantQuestions(
                qsOrderedQuestions,
                value,
                path,
                questionSetAnswer
            );

            let val = value;

            if (value.code) {
                val = value.code;
            }
            if (val.length === 0) {
                val = null;
            }
            _.set(aQuestionSetAnswersValue, `answers.${path}`, val);

            if (onValueChange) {
                onValueChange(aQuestionSetAnswersValue, pathToAnswers);
            }
        },
        [aQuestionSetAnswersValue, onValueChange, pathToAnswers, questionSetsMetadata]
    );

    if (!questionSetsMetadata) {
        return null;
    }

    const { viewModel: questionSetsViewModel } = new QuestionSetsParser(
        aQuestionSetAnswersValue,
        questionSetsMetadata,
        translator
    );
    const generatedQuestionSetsMetadata = generateQuestionSetsPresentationMetadata(
        questionSetsViewModel
    );

    return (
        <QuestionSetComponent
            id={id}
            title={title}
            questionSetsMetadata={generatedQuestionSetsMetadata}
            questionSetsViewModel={questionSetsViewModel}
            onValueChange={handleValueChange}
            onValidationChange={onValidationChange}
            labelPosition={labelPosition}
        />
    );
}

QuestionSetContainer.propTypes = {
    value: PropTypes.shape({
        code: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    onValueChange: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    onValidate: PropTypes.func,
    labelPosition: PropTypes.string,
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.string,
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    })
};
QuestionSetContainer.defaultProps = {
    labelPosition: 'left',
    title: messages.pageTitle
};

export default QuestionSetContainer;
