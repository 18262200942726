import { useCallback } from 'react';
import _ from 'lodash';

function useCleanPayload() {
    const cleanNotUpdatedCoverages = useCallback((submissionVM) => {
        let newCovs = _.get(submissionVM.value, 'lobData.homeLine.lineCoverages.coverages');
        if (!newCovs) {
            return;
        }
        newCovs = newCovs.filter((cov) => cov.updated === true ||
            cov.terms.some((term) => term.updated === true));
        newCovs = newCovs.map((cov) => {
            const newCov = Object.assign({}, cov);
            newCov.terms = cov.terms.filter((term) => term.updated === true);
            return newCov;
        });
        if (newCovs.length === 0) {
            newCovs = undefined;
        }
        _.set(submissionVM.value, 'lobData.homeLine.lineCoverages.coverages', newCovs);
    }, []);

    const cleanCostInfos = useCallback((submissionVM) => {
        _.unset(submissionVM.value, 'costInfos_NFUM');
    }, []);

    return {
        cleanNotUpdatedCoverages,
        cleanCostInfos
    };
}

export default useCleanPayload;
