import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import appConfig from 'app-config';
import { QB_STEPS, QUOTE_RETRIEVAL_STEPS, AppContext } from 'nfum-portals-utils-react';
import metadata from './SuccessPage.metadata.json5';
import messages from './SuccessPage.messages';
import styles from './SuccessPage.module.scss';
import useNextPaymentPath from '../../hooks/useNextPaymentPath';
import useLobs from '../../hooks/useLobs';
import OnlineAccountStatusRegistered from './OnlineAccountStatusRegistered/OnlineAccountStatusRegistered';
import OnlineAccountStatusCanRegister from './OnlineAccountStatusCanRegister/OnlineAccountStatusCanRegister';
import OnlineAccountStatusRegisterEligible from './OnlineAccountStatusRegisterEligible/OnlineAccountStatusRegisterEligible';
import OnlineAccountStatusRegisterInComplete from './OnlineAccountStatusRegisterInComplete/OnlineAccountStatusRegisterInComplete';
import OnlineAccountStatusUnKnown from './OnlineAccountStatusUnKnown/OnlineAccountStatusUnKnown';
import useTagManager from '../../hooks/useTagManager';

function SuccessPage(props) {
    const { wizardData: submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const { shouldUseSupplementaryPaymentPath } = useNextPaymentPath(submissionVM);
    const { isLifestyleJourney } = useLobs(submissionVM);
    const [registeredStatus, setRegisteredStatus] = useState(undefined);
    const registerStatusOptions = Object.freeze({
        IN_ELIGIBLE: 'ineligible',
        ELIGIBLE: 'eligible',
        ELIGIBLE_READY: 'eligibleready',
        INVITED: 'invited',
        IN_COMPLETE: 'incomplete',
        REGISTERED: 'registered',
        DE_REGISTERED: 'deregistered',
        UNKNOWN: 'unknown'
    });
    const { pushFormConfirmationInfo, pushLinkClickInfo } = useTagManager();
    const { isQuoteRetrieval } = useContext(AppContext);
    const { env } = appConfig;

    useEffect(() => {
        pushFormConfirmationInfo(submissionVM, isQuoteRetrieval
            ? QUOTE_RETRIEVAL_STEPS.SUCCESS
            : QB_STEPS.SUCCESS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function popstate() {
            window.history.go(1);
        };
    }, []);

    const returnToDashboard = useCallback(() => {
        const backToDashboardURL = env.JUTRO_BACK_TO_DASHBOARD_PATH || '/';
        pushLinkClickInfo(translator(messages.returnToWebsite), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    }, [pushLinkClickInfo, translator, env]);

    useEffect(() => {
        const regStatusValue = _.get(submissionVM.value, 'baseData.accountHolder.dcsregStatus_NFUM');
        setRegisteredStatus(regStatusValue);
    }, [submissionVM]);

    const getEffectiveDate = useCallback(() => {
        const periodStartDate = _.get(submissionVM.value, 'baseData.periodStartDate');
        if (periodStartDate) {
            const { year, month, day } = periodStartDate;
            return moment(new Date(year, month, day)).format('Do MMMM YYYY');
        }
        return '';
    }, [submissionVM]);

    const getThankYouText = useCallback(() => {
        if (submissionVM.baseData.isPaperMethodSelected_NFUM.value) {
            if (isLifestyleJourney) {
                return translator(messages.thankYouWithPaperDocumentsForLifestyle);
            }
            return translator(messages.thankYouWithPaperDocuments);
        }
        if (isLifestyleJourney) {
            return translator(messages.thankYouWithoutPaperDocumentsForLifestyle);
        }
        return translator(messages.thankYouWithoutPaperDocuments);
    }, [submissionVM.baseData.isPaperMethodSelected_NFUM, translator, isLifestyleJourney]);

    const getTitleText = useCallback(() => {
        if (submissionVM.baseData.paymentMethod_NFUM.value.code === 'directdebit') {
            if (shouldUseSupplementaryPaymentPath) {
                return translator(messages.transactionSupplimentaryTitle);
            }
            return translator(messages.transactionDDTitle);
        }
        return translator(messages.transactionTitle);
    }, [submissionVM.baseData.paymentMethod_NFUM, translator, shouldUseSupplementaryPaymentPath]);

    const overrideProps = {
        successPageTransactionTitle: {
            content: getTitleText()
        },
        successPageTransactionText: {
            content: getThankYouText()
        },
        successPageTransactionPolicyNumber: {
            content: _.get(submissionVM, 'bindData.policyNumber.value')
        },
        successPageTransactionPolicyStartDate: {
            content: getEffectiveDate()
        },
        onlineAccountStatusRegistered: {
            visible: registeredStatus === registerStatusOptions.REGISTERED
        },
        onlineAccountStatusRegisterInComplete: {
            visible: registeredStatus === registerStatusOptions.IN_COMPLETE
                    || registeredStatus === registerStatusOptions.INVITED
                    || registeredStatus === registerStatusOptions.IN_ELIGIBLE,
            submissionVM,
        },
        onlineAccountStatusCanRegister: {
            visible: registeredStatus === registerStatusOptions.ELIGIBLE_READY
        },
        onlineAccountStatusRegisterEligible: {
            visible: registeredStatus === registerStatusOptions.ELIGIBLE,
            submissionVM,
        },
        onlineAccountStatusUnKnown: {
            visible: registeredStatus === undefined
                    || registeredStatus === registerStatusOptions.UNKNOWN
        },
        returnButton: {
            onClick: returnToDashboard
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            onlineAccountStatusRegistered: OnlineAccountStatusRegistered,
            onlineAccountStatusCanRegister: OnlineAccountStatusCanRegister,
            onlineAccountStatusRegisterEligible: OnlineAccountStatusRegisterEligible,
            onlineAccountStatusRegisterInComplete: OnlineAccountStatusRegisterInComplete,
            onlineAccountStatusUnKnown: OnlineAccountStatusUnKnown,
        }
    };

    return (
        <WizardPage
            showCancel={false}
            showPrevious={false}
            showNext={false}
        >
            <ViewModelForm
                overrideProps={overrideProps}
                uiProps={metadata.pageContent}
                componentMap={resolvers.resolveComponentMap}
                model={submissionVM}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

SuccessPage.propTypes = wizardProps;
export default SuccessPage;
