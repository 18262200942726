import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.no',
        defaultMessage: 'No'
    },
    insideHome: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.insideHome',
        defaultMessage: 'Inside the home'
    },
    insideOutsideHome: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)'
    },
    keptInBank: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.keptInBank',
        defaultMessage: 'Kept in bank?'
    },
    coverType: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.coverType',
        defaultMessage: 'Kept in bank?'
    },
    replacementCost: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    category: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.category',
        defaultMessage: 'Category'
    },
    valuables: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.valuables',
        defaultMessage: 'Valuables'
    },
    notSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.valuables.notSelected',
        defaultMessage: 'Not Selected'
    },
});
