import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import BespokeFineArtsItems from '../FineArtsSummaryItem/FineArtsSummaryItem';
import CollectionsItems from '../FineArtsCollectionsItem/FineArtsCollectionsItem';
import metadata from './FineArtsCollectionsSummary.metadata.json5';
import styles from './FineArtsCollectionsSummary.module.scss';
import messages from './FineArtsCollectionsSummary.messages';
import usePropertyTitle from '../../../nfum-capability-quoteandbind-ngh-react/hooks/usePropertyTitle';

function FineArtsCollectionsSummary(props) {
    const {
        fineArtsItems,
        homeProperty,
        submissionVM,
        onClickSeeDocuments
    } = props;
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const { getPropertyTitle } = usePropertyTitle();
    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });

    const fineArtsIds = {
        HOMFineArtCollectionsCov: 'HOMFineArtCollectionsCov',
        UnspecSingleItemLimit: 'HOMFineArtCollectionsCovUnspecSingleItemLimit',
        UnspAntqCltFurnSI: 'HOMFineArtCollectionsCovUnspAntqCltFurnSI',
        UnspecPicPntgsDetails: 'HOMFineArtCollectionsCovUnspecPicPntgsDetails',
        UnspecBooksAndManucriptsSI: 'HOMFineArtCollectionsCovUnspecBooksAndManucriptsSI',
        UnspStaSclCrosArtSI: 'HOMFineArtCollectionsCovUnspStaSclCrosArtSI',
        UnspecPrclnRareGlsCrmicSI: 'HOMFineArtCollectionsCovUnspecPrclnRareGlsCrmicSI',
        UnspecClckBrMtrMechArtSI: 'HOMFineArtCollectionsCovUnspecClckBrMtrMechArtSI',
        UnspecPreciousMetalSI: 'HOMFineArtCollectionsCovUnspecPreciousMetalSI',
        UnspecTepsAndRugsSI: 'HOMFineArtCollectionsCovUnspecTepsAndRugsSI',
        SpecifiedFineArtType: 'HOMFineArtCollectionsCovFineArtType',
        SpecItemIndvslExcess: 'HOMFineArtCollectionsCovSpecItemIndvslExcess',
    };

    const getTermLimitValue = useCallback((cov, termPublicID, currSymbol = true) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        return currSymbol && chosenTermValue ? `£${chosenTermValue}` : undefined;
    }, []);

    const coverages = submissionVM.lobData.homeLine.lineCoverages.coverages.value;
    const HOMFineArtCollectionsCov = coverages
        .find((cov) => cov.publicID === fineArtsIds.HOMFineArtCollectionsCov);
    const unspecSingleItemLimit = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecSingleItemLimit);
    const unspAntqCltFurnSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspAntqCltFurnSI);
    const unspecPicPntgsDetails = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecPicPntgsDetails);
    const unspecBooksAndManucriptsSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecBooksAndManucriptsSI);
    const unspStaSclCrosArtSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspStaSclCrosArtSI);
    const unspecPrclnRareGlsCrmicSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecPrclnRareGlsCrmicSI);
    const unspecClckBrMtrMechArtSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecClckBrMtrMechArtSI);
    const unspecPreciousMetalSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecPreciousMetalSI);
    const unspecTepsAndRugsSI = getTermLimitValue(HOMFineArtCollectionsCov,
        fineArtsIds.UnspecTepsAndRugsSI);

    const getAddedFineArts = useCallback((categoryVal) => {
        const fineArts = [];
        fineArtsItems.forEach((item) => {
            if (item.itemType === categoryVal) {
                fineArts.push(item);
            }
        });
        return fineArts;
    }, [fineArtsItems]);

    const fineArtsCollections = useCallback(() => {
        return {
            collections: [
                {
                    name: 'FineArt',
                    valuables: getAddedFineArts(category.FINE_ART)
                },
                {
                    name: 'StampsMedalsCoins',
                    valuables: getAddedFineArts(category.STAMPS_MEDALS_COINS)
                },
                {
                    name: 'WineCollections',
                    valuables: getAddedFineArts(category.WINES)
                },
                {
                    name: 'OtherCollections',
                    valuables: getAddedFineArts(category.OTHER_COLLECTIONS)
                }
            ],
        };
    }, [category, getAddedFineArts]);
    const specifiedFineArts = fineArtsCollections().collections.filter((x) => x.name === 'FineArt' && x.valuables.length > 0);
    const collections = fineArtsCollections().collections.filter((x) => x.name !== 'FineArt' && x.valuables.length > 0);

    const generateOverrides = useCallback(() => {
        if (!fineArtsCollections) return Object.assign({});

        const fineArtsAddedOverrides = fineArtsCollections().collections
            .filter((x) => x.name === 'FineArt' && x.valuables.length > 0)
            .map((item, index) => {
                return {
                    [`bespokeFineArtsItems${index}`]: {
                        fineArt: item,
                        index: index
                    }
                };
            });

        const collectionsAddedOverrides = fineArtsCollections().collections
            .filter((x) => x.name !== 'FineArt' && x.valuables.length > 0)
            .map((item, index) => {
                return {
                    [`collectionsItems${index}`]: {
                        fineArtCollections: item,
                        index: index
                    }
                };
            });

        return Object.assign({},
            ...fineArtsAddedOverrides,
            ...collectionsAddedOverrides);
    }, [fineArtsCollections
    ]);

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.fineArtsMessageContent212), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        fineArtsMessageContainer: {
            visible: fineArtsItems.length > 20
        },
        fineArtsMessageContent112: {
            onClick: onClickSeeDocuments
        },
        fineArtsLocationDetailsContainer: {
            visible: fineArtsItems.length < 21
        },
        fineArtsCollectionsHomePropertyTitle: {
            content: getPropertyTitle(homeProperty)
        },
        unspecifiedSingleItemLimit: {
            value: unspecSingleItemLimit,
            labelClassName: 'riskObjectPropertyLabel boldFonts'
        },
        unspecifiedFineArtListsHeader: {
            visible: !!unspecPicPntgsDetails
                || !!unspecTepsAndRugsSI
                || !!unspStaSclCrosArtSI
                || !!unspecClckBrMtrMechArtSI
                || !!unspecPreciousMetalSI
                || !!unspecBooksAndManucriptsSI
                || !!unspecPrclnRareGlsCrmicSI
                || !!unspAntqCltFurnSI,
            className: 'unspecifiedFineArtListsHeader',
            labelClassName: 'unspecifiedFineArtListsHeaderLabel',
            value: translator(messages.replacementCost)
        },
        unspecifiedFineArtPaintings: {
            visible: !!unspecPicPntgsDetails,
            value: unspecPicPntgsDetails
        },
        unspecifiedFineArtTapsteries: {
            visible: !!unspecTepsAndRugsSI,
            value: unspecTepsAndRugsSI
        },
        unspecifiedFineArtStatues: {
            visible: !!unspStaSclCrosArtSI,
            value: unspStaSclCrosArtSI,
        },
        unspecifiedFineArtClocks: {
            visible: !!unspecClckBrMtrMechArtSI,
            value: unspecClckBrMtrMechArtSI,
        },
        unspecifiedFineArtPreciousmetals: {
            visible: !!unspecPreciousMetalSI,
            value: unspecPreciousMetalSI,
        },
        unspecifiedFineArtBooks: {
            visible: !!unspecBooksAndManucriptsSI,
            value: unspecBooksAndManucriptsSI,
        },
        unspecifiedFineArtPorcelain: {
            visible: !!unspecPrclnRareGlsCrmicSI,
            value: unspecPrclnRareGlsCrmicSI,
        },
        unspecifiedFineArtAntique: {
            visible: !!unspAntqCltFurnSI,
            value: unspAntqCltFurnSI,
        },
        specifiedFineArtsContainer: {
            visible: specifiedFineArts.length > 0
        },
        specifiedFineArtsItems: {
            visible: fineArtsItems.length > 0,
            data: specifiedFineArts
        },
        collectionsContainer: {
            visible: collections.length > 0
        },
        collectionsItems: {
            visible: fineArtsItems.length > 0,
            data: collections
        },
        fineArtsMessageContent212: {
            onClick: onFindAgencyClicked
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            bespokeFineArtsItems: BespokeFineArtsItems,
            collectionsItems: CollectionsItems
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
        />
    );
}

export default FineArtsCollectionsSummary;
