import { defineMessages } from 'react-intl';

export default defineMessages({
    yourHome: {
        id: 'quoteandbind.ngh.wizard.step.Your Home',
        defaultMessage: 'Home'
    },
    anErrorOccurred: {
        id: 'quoteandbind.ngh.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.ngh.wizard.error.title',
        defaultMessage: 'Error'
    },
    ok: {
        id: 'quoteandbind.ngh.wizard.ok',
        defaultMessage: 'Ok'
    },
    continue: {
        id: 'quoteandbind.ngh.wizard.Continue',
        defaultMessage: 'Continue'
    },
    back: {
        id: 'quoteandbind.ngh.wizard.Back',
        defaultMessage: 'Back'
    },
    saveForLater: {
        id: 'quoteandbind.ngh.wizard.saveForLater',
        defaultMessage: 'Save for later'
    },
    wantToJumpMessage: {
        id: 'quoteandbind.ngh.wizard.wantToJumpMessage',
        defaultMessage: 'You must complete manditory questions before navigating away from this section'
    },
    purchase: {
        id: 'quoteandbind.ngh.wizard.Purchase',
        defaultMessage: 'Purchase'
    },
});
