import { defineMessages } from 'react-intl';

export default defineMessages({
    thisIsStrapline: {
        id: 'segmentation.directives.landing-page-1.header.strapline',
        defaultMessage: 'This is the Strapline',
    },
    thisIsTheSublineAboutInsurance: {
        id: 'segmentation.directives.landing-page-1.header.subline',
        defaultMessage: 'This is the subline about insurance.',
    },
    getStarted: {
        id: 'segmentation.directives.landing-page-1.header.buttonText',
        defaultMessage: 'Get Started',
    },
    sectionTitleHere: {
        id: 'segmentation.directives.landing-page-1.section-1.heading',
        defaultMessage: 'Section Title Here',
    },
    sectionText: {
        id: 'segmentation.directives.landing-page-1.section-1.text',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere temporibus omnis illum, officia. Architecto voluptatibus commodi voluptatem perspiciatis eos possimus, eius at molestias quaerat magnam? Odio qui quos ipsam natus.',
    },
    blockTitle1: {
        id: 'segmentation.directives.landing-page-1.section-2.blockTitle1',
        defaultMessage: 'Block Title Here',
    },
    blockTitle2: {
        id: 'segmentation.directives.landing-page-1.section-2.blockTitle2',
        defaultMessage: 'Block Title Here',
    },
    blockTitle3: {
        id: 'segmentation.directives.landing-page-1.section-2.blockTitle3',
        defaultMessage: 'Block Title Here',
    },
    quote: {
        id: 'segmentation.directives.landing-page-1.section-4.heading',
        defaultMessage: 'Some words about things',
    },
    quoteCite: {
        id: 'segmentation.directives.landing-page-1.section-4.text',
        defaultMessage: 'Satisfied Customer',
    },
    anotherCallToAction: {
        id: 'segmentation.directives.landing-page-1.section-6.heading',
        defaultMessage: 'Another call to action',
    },
    abSegmentationOption0: {
        id: 'segmentation.directives.landing-page-1.footer.AB Test experimentA: option 0',
        defaultMessage: 'Running A/B Test #1',
    },
    abSegmentationOption1: {
        id: 'segmentation.directives.landing-page-1.footer.AB Test experimentA: option 1',
        defaultMessage: 'Running A/B Test #2',
    },
    locationSegmentationOptionDefault: {
        id: 'segmentation.directives.landing-page-1.footer.Location Segmentation: option default',
        defaultMessage: 'Location Segmentation Test (countryCode): default',
    },
    locationSegmentationOptionIE: {
        id: 'segmentation.directives.landing-page-1.footer.Location Segmentation: option Ireland',
        defaultMessage: 'Location Segmentation Test (countryCode): Ireland',
    },
    locationSegmentationOptionUS: {
        id: 'segmentation.directives.landing-page-1.footer.Location Segmentation: option USA',
        defaultMessage: 'Location Segmentation Test (countryCode): USA',
    },
});
