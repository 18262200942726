import { defineMessages } from 'react-intl';

export default defineMessages({
    buildingsInsurance: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.buildingsInsurance',
        defaultMessage: 'Buildings insurance'
    },
    noOfBedrooms: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.noOfBedrooms',
        defaultMessage: 'Number of bedrooms'
    },
    buildingsLevelLimit: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.buildingsLevelLimit',
        defaultMessage: 'Buildings level limit'
    },
    buildingsAccidentalDamage: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.buildingsAccidentalDamage',
        defaultMessage: 'Buildings accidental damage'
    },
    buildingsAccidentalDamageInd: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.buildingsAccidentalDamageInd',
        defaultMessage: 'Buildings accidental damage'
    },
    confirmationText1: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText1',
        defaultMessage: 'You have confirmed that:'
    },
    confirmationText2Yes: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText2Yes',
        defaultMessage: 'The buildings you want to insure have sustained loss of damage by flood,subsidence, heave or landslip'
    },
    confirmationText2No: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText2No',
        defaultMessage: 'The buildings you want to insure have never sustained loss of damage by flood,subsidence, heave or landslip'
    },
    confirmationText3Yes: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText3Yes',
        defaultMessage: 'The buildings you want to insure are at an immediate risk of flooding'
    },
    confirmationText3No: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText3No',
        defaultMessage: 'The buildings you want to insure are not at an immediate risk of flooding'
    },
    confirmationText4: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText4',
        defaultMessage: 'You don\'t use your home for business purposes other than as a home office'
    },
    confirmationText5: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.confirmationText5',
        defaultMessage: 'No customers or clients visit your home for business purposes'
    },
    excess: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.excesses',
        defaultMessage: 'Excesses'
    },
    leakingAndFrozenWater: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.leakingAndFrozenWater',
        defaultMessage: 'Leaking and Frozen Water'
    },
    subsidence: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.subsidence',
        defaultMessage: 'Subsidence'
    },
    flood: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.flood',
        defaultMessage: 'Flood'
    },
    voluntary: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.voluntary',
        defaultMessage: 'Voluntary'
    },
    compulsaryAllClaims: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.compulsaryAllClaims',
        defaultMessage: 'Compulsory (All claims)'
    },
    seeDocuments: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.seeDocuments',
        defaultMessage: 'See documents'
    },
    notSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.notSelected',
        defaultMessage: 'Not selected'
    },
    added: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.added',
        defaultMessage: 'Added'
    },
    included: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.included',
        defaultMessage: 'Included'
    },
    moreThanEight: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.moreThanEight',
        defaultMessage: 'More than 8'
    },
    buildingTenantsFixturesSumInsured: {
        id: 'quoteandbind.ngh.views.policy-summary.buildingsSummary.buildingTenantsFixturesSumInsured',
        defaultMessage: 'Tenants fixtures sum insured'
    }
});
