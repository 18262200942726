/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon, TypeaheadMultiSelectField } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';
import styles from './NfumTypeaheadMultiSelectField.module.scss';
import messages from './NfumTypeaheadMultiSelectField.messages';
import { TranslatorContext } from '@jutro/locale';


function NfumTypeaheadMultiSelectField(props) { /* NOSONAR: pure declarative usage */
    const {
        value,
        success,
        error,
        showEdit,
        onValidationChange,
        allValues
    } = props;

    const translator = useContext(TranslatorContext)

    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [filterValue, setFilterValue] = useState(null);
    const [isMounted, setIsMounted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isIntialValue, setIsInitialValue] = useState(true)


    useEffect(() => {
        if (!isMounted) return setIsMounted(true)
        setIsInitialValue(false)
        let handler;
        if (filterValue) {
            if (filterValue.length === 1) return setIsInitialValue(true)
            setIsLoading(true)
            handler = setTimeout(() => {
                setSearch(filterValue);
            }, 400);
            setIsLoading(false)
        }
        return () => {
            handler && clearTimeout(handler);
        };

    }, [filterValue]);

    const handleChange = (newValue) => {
        if (newValue) {
            const newData = allValues.filter(value => value.code.toLowerCase().includes(search.toLowerCase())) /* NOSONAR */
            setFilterValue(newValue)
            setData(newData)
        }
    }

    const handleNoOptionMessage = (e) => {
        if (isLoading) {
            return translator(messages.loading)
        }
        if (isIntialValue) {
            return translator(messages.typeMessage)
        }
        return translator(messages.noOptions)
    }

    const showError = value !== undefined && (error || !currentValidation);
    let icon;
    
    if (showError) {
        icon = "gw-close";
    } else if (success || value) {
        icon = "gw-check";
    } else {
        icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });

    const iconComponent = <Icon icon={icon} className={iconClass} />;

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const onBlur = () => {
        updateShowState(true);
    };

    return (
        <div className={styles.nfumInputContainer}>
            <NfumFieldLabel
                {...props}
            />
            <div className={styles.nfumInput}>
                <TypeaheadMultiSelectField /* TODO: upgrade 11.5.2 - Need to update props(onInputChange) in TypeaheadMultiSelectField in every upgrade*/
                    {...props}
                    availableValues={isIntialValue ? [] : data}
                    onInputChange={handleChange}
                    defaultOptions={true}
                    noOptionsMessage={handleNoOptionMessage()}
                    icon={showEdit && "gw-edit"}
                    iconPosition="right"
                    className={styles.nfumInputField}
                    onValidationChange={onValidate}
                    onBlur={onBlur}
                    hideLabel="true"
                />
                {(showState) && iconComponent}
            </div>
        </div>
    );
}

NfumTypeaheadMultiSelectField.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
    showEdit: PropTypes.bool,
};

NfumTypeaheadMultiSelectField.defaultProps = {
    error: false,
    success: false,
    showEdit: false
};

export default NfumTypeaheadMultiSelectField;
