import { defineMessages } from 'react-intl';

export default defineMessages({
    RefusedInsuranceText: {
        id: 'quoteandbind.ngh.views.prequalifications.refusedInsuranceText',
        defaultMessage: 'Ever been refused insurance, had insurance cancelled, declared void (as though it never existed), had renewal declined or any special terms or conditions imposed by an insurer?',
    },
    DelinedInsuranceText: {
        id: 'quoteandbind.ngh.views.prequalifications.declinedInsuranceText',
        defaultMessage: 'Been subject to any form of bankruptcy proceedings, individual voluntary agreements (IVA), debt relief orders (or equivalent in Scottish law) or been subject to any other statutory insolvency proceedings e.g. company voluntary arrangements (CVA) in the last 6 years whether discharged or not?',
    },
    BankruptcyProceedingsText: {
        id: 'quoteandbind.ngh.views.prequalifications.bankruptcyProceedingsText',
        defaultMessage: 'Been subject to any Financial Court Judgments, Orders or Decrees in the last 6 years, such as County Court Judgements (CCJs), whether satisfied or not?',
    },
    FinancialCourtJudgementText: {
        id: 'quoteandbind.ngh.views.prequalifications.financialCourtJudgementText',
        defaultMessage: 'Been refused any financial services product due to fraud or misrepresentation within the last 6 years?',
    },
    ProsecutionsPendingText: {
        id: 'quoteandbind.ngh.views.prequalifications.prosecutionsPendingText',
        defaultMessage: 'Been convicted of, or charged with any non-motoring offence, or have any prosecutions pending? Excluding any convictions regarded as ‘spent’ under the Rehabilitation of Offenders legislation.',
    },
    riskOfFloodingText: {
        id: 'quoteandbind.ngh.views.prequalifications.riskOfFloodingText',
        defaultMessage: 'No part of the property to be insured, including its land, is currently flooded or at risk of flooding',
    },
    FinancialServicesText: {
        id: 'quoteandbind.ngh.views.prequalifications.financialServicesText',
        defaultMessage: 'Have not been refused any financial services products due to fraud or misrepresentation',
    },
    DamagesInsuredText: {
        id: 'quoteandbind.ngh.views.prequalifications.damagesInsuredText',
        defaultMessage: 'The buildings you want to insure have never sustained loss or damage by flood, subsidence, heave or landslip and are not at immediate risk of flooding',
    },
    HomeInsuredText: {
        id: 'quoteandbind.ngh.views.prequalifications.homeInsuredText',
        defaultMessage: 'You are insuring your main home or additional home, occupied by you and your family',
    },
    HomeOfficeText: {
        id: 'quoteandbind.ngh.views.prequalifications.homeOfficeText',
        defaultMessage: 'You don’t use your home for business purposes other than as a home office',
    },
    PropertyInsuredText: {
        id: 'quoteandbind.ngh.views.prequalifications.propertyInsuredText',
        defaultMessage: 'No customers or clients visit your home for business purposes',
    },
    ContentsSumInsuredText: {
        id: 'quoteandbind.ngh.views.prequalifications.contentsSumInsuredText',
        defaultMessage: 'The total cost to replace your contents is less than £150,000',
    },
    ValuablesBelow75kText: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcText',
        defaultMessage: 'The total cost to replace your valuables is not greater than 50% of the total cost to replace your contents',
    },
    ContentsAwayFromHomeText: {
        id: 'quoteandbind.ngh.views.prequalifications.contentsAwayFromHomeText',
        defaultMessage: 'The contents that you would take outside of the home are worth less than £30,000',
    },
    PropertyLeftEmptyText: {
        id: 'quoteandbind.ngh.views.prequalifications.propertyLeftEmptyText',
        defaultMessage: 'You do not expect your home to be left unoccupied for more than 30 days in a row',
    },
    DwellingRepairText: {
        id: 'quoteandbind.ngh.views.prequalifications.dwellingRepairText',
        defaultMessage: 'Your home is in a reasonable state of repair and is not undergoing or requiring any structural alterations',
    },
    ongoingProjects: {
        id: 'quoteandbind.ngh.views.prequalifications.ongoingProjects',
        defaultMessage: 'There are no ongoing building works or projects about to start',
    },
    AboutYouTitle: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutYouTitle',
        defaultMessage: 'About you',
    },
    AboutYouSubTitle: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutYouSubTitle',
        defaultMessage: "So that we can make sure you're able to buy our Home Insurance we ask that you confirm neither you or anyone else covered on the policy has:",
    },
    AboutHomeTitle: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutHomeTitle',
        defaultMessage: 'About your home',
    },
    AboutHomeSubTitleFinally: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutHomeSubTitle',
        defaultMessage: 'Now, we need you to confirm some things about your home and contents. ',
    },
    AboutHomeSubTitlePutTogether: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutHomeSubTitlePutTogether',
        defaultMessage: 'We’ve put together some ',
    },
    AboutHomeSubTitleGuidance: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutHomeSubTitleGuidance',
        defaultMessage: 'guidance',
    },
    AboutHomeSubTitleTerms: {
        id: 'quoteandbind.ngh.views.prequalifications.aboutHomeSubTitleTerms',
        defaultMessage: ' to help your understanding of the terms we have used:',
    },
    EditIcon: {
        id: 'quoteandbind.ngh.views.prequalifications.edit',
        defaultMessage: 'Edit',
    },
    ContinueButton: {
        id: 'quoteandbind.ngh.views.prequalifications.continue',
        defaultMessage: 'Continue',
    },
    SampleToolTip: {
        id: 'quoteandbind.ngh.views.prequalifications.sampleTooltip',
        defaultMessage: 'This is an informational tooltip',
    },
    byClicking: {
        id: 'quoteandbind.ngh.views.prequalifications.byClicking',
        defaultMessage: 'By clicking',
    },
    byClickingContinue: {
        id: 'quoteandbind.ngh.views.prequalifications.byClickingContinue',
        defaultMessage: '\'Continue\''
    },
    youAreConfirming: {
        id: 'quoteandbind.ngh.views.prequalifications.youAreConfirming',
        defaultMessage: 'you are confirming the answers you have provided above are correct and that you have read and agree to this information.'
    },
    referralTitle: {
        id: 'quoteandbind.ngh.views.prequalifications.referralTitle',
        defaultMessage: 'We think you would benefit from something more tailored',
    },
    referralDescription: {
        id: 'quoteandbind.ngh.views.prequalifications.referralDescription',
        defaultMessage: 'Sorry, based on the information you have given us, we are unable to provide a quote online. Please contact us so we can support you with your Home Insurance quote.',
    },
    homeOfficeTooltipText: {
        id: 'quoteandbind.ngh.views.prequalifications.homeOfficeTooltipText',
        defaultMessage: 'By this we mean only clerical and administrative tasks that you undertake from home and where no goods are produced'
    },
    valuablesBelow50pcTooltipText1: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText1',
        defaultMessage: 'We class jewellery, medals, coins, works of art and technology items as valuables. '
    },
    valuablesBelow50pcTooltipText2: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText2',
        defaultMessage: 'Full list here'
    },
    valuablesBelow50pcTooltipText3: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText3',
        defaultMessage: '. For example if the total cost to replace all your contents is '
    },
    valuablesBelow50pcTooltipText4: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText4',
        defaultMessage: '£50,000'
    },
    valuablesBelow50pcTooltipText5: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText5',
        defaultMessage: ', you are able to have up to '
    },
    valuablesBelow50pcTooltipText6: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText6',
        defaultMessage: '£25,000'
    },
    valuablesBelow50pcTooltipText7: {
        id: 'quoteandbind.ngh.views.prequalifications.valuablesBelow50pcTooltipText7',
        defaultMessage: ' in valuables items'
    },
    contentsAwayFromHomeTooltipText1: {
        id: 'quoteandbind.ngh.views.prequalifications.contentsAwayFromHomeTooltipText1',
        defaultMessage: 'We call this '
    },
    contentsAwayFromHomeTooltipText2: {
        id: 'quoteandbind.ngh.views.prequalifications.contentsAwayFromHomeTooltipText2',
        defaultMessage: 'Contents Away From the Home'
    },
    contentsAwayFromHomeTooltipText3: {
        id: 'quoteandbind.ngh.views.prequalifications.contentsAwayFromHomeTooltipText3',
        defaultMessage: ' and would include items like your mobile phone, portable tech and jewellery that you wear when away from your home. '
    },
    noCustomersTooltip: {
        id: 'quoteandbind.ngh.views.prequalifications.noCustomersTooltip',
        defaultMessage: 'By this we mean any person who would visit your home in relation to your occupation.'
    },
    contentsAwayFromHomeTooltipText: {
        id: 'quoteandbind.ngh.views.prequalifications.contentsAwayFromHomeTooltipText',
        defaultMessage: 'View full list'
    },
    dwellingRepairTextTooltipText1: {
        id: 'quoteandbind.ngh.views.prequalifications.dwellingRepairTextTooltipText1',
        defaultMessage: 'Don\'t worry you are fine to hang a picture, but we are unable to insure you if you are doing major construction, renovation or any extension to your home costing over '
    },
    dwellingRepairTooltipText2: {
        id: 'quoteandbind.ngh.views.prequalifications.dwellingRepairTextTooltipText2',
        defaultMessage: '£50,000'
    }
});
