import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class PolicyService {
    static getDocuments(params, additionalHeaders, additionalParams) {
        return JsonRPCService.send(getProxiedServiceUrl('docs'), 'getDocuments_NFUM', params, additionalHeaders, additionalParams);
    }

    static getDocumentUrl(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('docs'), 'getDocumentRedirectURL_NFUM', params, additionalHeaders);
    }
    /**
     * Added US516289 added to pass assigned agency details to frontend.
     * Retrieves producer details.
     * for NFUM
     * @param {Object} params the retrieval payload (job no or policy no and transaction type)
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */

    static retrieveProducerDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'fetchProducerDetails_NFUM', params, additionalHeaders);
    }
}
