import React, {
    useState, useContext, useCallback, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import {
    ViewModelServiceContext,
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import { GuidanceService } from 'gw-capability-guidance';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import ReferralMessage from '../ReferralMessageComponent/ReferralMessageComponent';
import metadata from './OccupationLookup.metadata.json5';
import messages from './OccupationLookup.messages';
import styles from './OccupationLookup.module.scss';
import AddOtherOccupation from './AddOtherOccupation/AddOtherOccupation';

function formatOccupationCodes(data) {
    return data.map((occupation) => ({
        ...occupation,
        name: occupation.classification,
    })).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
    });
}

function OccupationLookup(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const [occupationCodes, updateOccupationCodes] = useState([]);
    const [showReferralMessage, setShowReferralMessage] = useState(false);
    const basePath = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails';
    const paths = useMemo(() => {
        return {
            employmentStatus: `${basePath}.employmentStatus`,
            isEmployInFarmingIndustry: `${basePath}.isEmployInFarmingIndustry`,
            isMemberOfFarmingUnion: `${basePath}.isMemberOfFarmingUnion`,
            unionMembershipId: `${basePath}.unionMembershipId`,
            primaryOccupation: `${basePath}.primaryOccupation`,
            otherOccupations: `${basePath}.otherOccupations`
        };
    }, []);
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    useEffect(() => {
        const fetchOccupations = async () => {
            const occupations = await GuidanceService.getOccupationsNFUM();
            updateOccupationCodes(formatOccupationCodes(occupations));
        };
        fetchOccupations();
    }, []);


    const handleLOBPath = useCallback(
        (newSubmissionVM, path, value) => {
            if (path === paths.employmentStatus && value !== 'employed') {
                _.set(newSubmissionVM, paths.isEmployInFarmingIndustry, null);
                _.set(newSubmissionVM, paths.isMemberOfFarmingUnion, null);
                _.set(newSubmissionVM, paths.primaryOccupation, null);
                _.set(newSubmissionVM, paths.otherOccupations, []);
            }

            if (path === paths.isEmployInFarmingIndustry && !value) {
                _.set(newSubmissionVM, paths.isMemberOfFarmingUnion, null);
                _.set(newSubmissionVM, paths.primaryOccupation, null);
                _.set(newSubmissionVM, paths.otherOccupations, []);
            } else if (path === paths.isEmployInFarmingIndustry && value) {
                _.set(newSubmissionVM, paths.otherOccupations, []);
            }

            if (path === paths.isMemberOfFarmingUnion && !value) {
                _.set(newSubmissionVM, paths.primaryOccupation, null);
                _.set(newSubmissionVM, paths.otherOccupations, []);
            }

            _.set(newSubmissionVM, path, value);
            return newSubmissionVM;
        },
        [paths]
    );

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            updateWizardData(handleLOBPath(newSubmissionVM, path, value));
        },
        [handleLOBPath, submissionVM, updateWizardData, viewModelService]
    );

    const shouldShow = (path, valueToMatch) => {
        const value = _.get(submissionVM, path);
        return _.isNil(valueToMatch) ? !!value : value === valueToMatch;
    };

    const isEmpty = (path) => {
        const value = _.get(submissionVM, path);
        return _.isNil(value);
    };

    const addOccupation = useCallback(() => {
        const otherOccupations = _.get(submissionVM, `${paths.otherOccupations}.value`, []);
        if (otherOccupations.length === 4) {
            setShowReferralMessage(true);
            return;
        }
        otherOccupations.push('');
        _.set(submissionVM, `${paths.otherOccupations}.value`, otherOccupations);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, paths]);

    const changeOccupation = useCallback((newOccupation, index) => {
        const otherOccupations = _.get(submissionVM, `${paths.otherOccupations}.value`, []);
        otherOccupations[index] = newOccupation;
        _.set(submissionVM, `${paths.otherOccupations}.value`, otherOccupations);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, paths]);

    const removeOccupation = useCallback((index) => {
        const otherOccupations = _.get(submissionVM, `${paths.otherOccupations}.value`, []);
        otherOccupations.splice(index, 1);
        _.set(submissionVM, `${paths.otherOccupations}.value`, otherOccupations);
        if (otherOccupations.length < 4) {
            setShowReferralMessage(false);
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, paths]);

    const generateOverrides = useCallback(() => {
        const otherOccupations = _.get(submissionVM, `${paths.otherOccupations}.value`, []);
        const noOfOtherOccupations = otherOccupations.length;
        const overrides = otherOccupations.map((occupation, index) => {
            return {
                [`addOtherOccupationItem${index}`]: {
                    occupation: otherOccupations[index],
                    occupationCodes,
                    onAddAnotherOccuption: () => addOccupation(),
                    onRemoveOccupation: () => removeOccupation(index),
                    onOccupationSelected: (value) => changeOccupation(value, index),
                    addAnotherVisible: index === noOfOtherOccupations - 1 && !showReferralMessage
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [
        submissionVM,
        occupationCodes,
        addOccupation,
        changeOccupation,
        removeOccupation,
        showReferralMessage,
        paths
    ]);

    const isPrimaryOccupationVisible = () => shouldShow(`${paths.isEmployInFarmingIndustry}.value`, false)
        || !isEmpty(`${paths.isMemberOfFarmingUnion}.value`);

    const isAddAnotherVisible = () => (!isEmpty(`${paths.isMemberOfFarmingUnion}.value`)
        || shouldShow(`${paths.isEmployInFarmingIndustry}.value`, false))
        && !isEmpty(`${paths.primaryOccupation}.value`)
        && _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomeYourDetails.otherOccupations', []).length === 0;

    const overrideProps = {
        employmentStatus: {
            value: _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.employmentStatus')
        },
        isEmployInFarmingIndustry: {
            visible: shouldShow('lobData.value.homeLine.coverables.homhomeProperty.homhomeYourDetails.employmentStatus', 'employed')
        },
        isMemberOfFarmingUnion: {
            visible: shouldShow(`${paths.isEmployInFarmingIndustry}.value`),
        },
        unionMembershipId: {
            visible: shouldShow(`${paths.isMemberOfFarmingUnion}.value`),
            showOptional: true
        },
        showList: {
            visible: shouldShow(`${paths.isEmployInFarmingIndustry}.value`),
        },
        primaryOccupation: { /* TODO: upgrade 11.5.2 - nfum component should be fixed and used */
            allValues: occupationCodes,
            visible: isPrimaryOccupationVisible(),
        },
        primaryOccupationHelperTextContainer: {
            visible: isPrimaryOccupationVisible()
        },
        addAnotherOccupationButton: {
            onClick: () => addOccupation(),
            visible: isAddAnotherVisible()
        },
        otherOccupationsContainer: {
            visible: _.get(submissionVM, `${paths.otherOccupations}.value`, []).length > 0
        },
        referralMessageContainer: {
            visible: showReferralMessage,
            resolveComponentMap: { referralMessage: ReferralMessage }
        },
        referralMessage: {
            title: translator(messages.referralTitle),
            description: translator(messages.referralDescription),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        ...generateOverrides(),


    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            addotheroccupation: AddOtherOccupation,
            referralMessage: ReferralMessage
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

OccupationLookup.propTypes = wizardProps;
export default OccupationLookup;
