import { defineMessages } from 'react-intl';

export default defineMessages({
    directDebitTermsContainer: {
        id: 'quoteandbind.ngh.views.termsAndCondition.termsAndConditions',
        defaultMessage: 'Terms and conditions'
    },
    termsAndConditionsContainerInfo1: {
        id: 'quoteandbind.ngh.views.termsAndCondition.TermsContainerInfo1',
        defaultMessage: 'Please read our '
    },
    ourTermsAndConditions: {
        id: 'quoteandbind.ngh.views.termsAndCondition.ourTermsAndConditions',
        defaultMessage: 'Terms and conditions'
    },
    termsAndConditionsContainerInfo2: {
        id: 'quoteandbind.ngh.views.termsAndCondition.TermsContainerInfo2',
        defaultMessage: ' and our '
    },
    mutualityAndCharitable: {
        id: 'quoteandbind.ngh.views.termsAndCondition.mutualityAndCharitable',
        defaultMessage: 'Mutuality and Charitable assignment'
    },
    termsAndConditionsContainerInfo3: {
        id: 'quoteandbind.ngh.views.termsAndCondition.TermsContainerInfo3',
        defaultMessage: ' and tick the boxes below if you agree. In common with other insurers, your details will be exchanged with them and fraud prevention agencies. If fraud is detected, you could be refused certain services, finance or employment.'
    },
    termsAndConditionsCheckbox: {
        id: 'quoteandbind.ngh.views.termsAndCondition.termAndConditionsCheckbox',
        defaultMessage: 'Terms and conditions'
    },
    mutualityAndcharitableCheckbox: {
        id: 'quoteandbind.ngh.views.termsAndCondition.mutualityAndcharitableCheckbox',
        defaultMessage: 'Mutuality and charitable assignment'
    },
});
