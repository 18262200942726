import { defineMessages } from 'react-intl';

export default defineMessages({
    valuablesAdditionalDescription1: {
        id: 'quoteandbind.ngh.views.valuablesInfoCard.valuablesAdditionalDescription1',
        defaultMessage: 'We use the term valuables to cover watches, jewellery, medals, coins and works of art as well as high value laptops and portable electronics. Take a look at the '
    },
    fullList: {
        id: 'quoteandbind.ngh.views.valuablesInfoCard.fullList',
        defaultMessage: 'full list'
    },
    valuablesAdditionalDescription2: {
        id: 'quoteandbind.ngh.views.valuablesInfoCard.valuablesAdditionalDescription3',
        defaultMessage: ' of what we consider a valuable.'
    },
    valuablesMaxCoverDescription1: {
        id: 'quoteandbind.ngh.views.valuablesInfoCard.valuablesMaxCoverDescription1',
        defaultMessage: 'The maximum cover we will provide for all specified and unspecified valuables combined is '
    },
    valuablesMaxCoverDescription2: {
        id: 'quoteandbind.ngh.views.valuablesInfoCard.valuablesMaxCoverDescription2',
        defaultMessage: ' If you have more than this amount please '
    },
    contactUs: {
        id: 'quoteandbind.ngh.views.valuablesInfoCard.contactUs',
        defaultMessage: 'contact us.'
    }
});
