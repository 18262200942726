import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';

import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';

import messages from './NghQuoteInfo.messages';

function NghQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const quoteId = _.get(submissionVM, 'quoteID.value');

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.homeOwnersQuote)}
                </div>
                <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                    {quoteId}
                </div>
            </div>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.location)}
                </div>
            </div>
        </div>
    );
}

NghQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired
};

export default NghQuoteInfo;
