import { defineMessages } from 'react-intl';

export default defineMessages({
    getInTouch: {
        id: 'quoteandbind.ngh.views.valuables.getInTouch',
        defaultMessage: 'We need you to get in touch'
    },
    limitsValidationErrorTextParagraph1: {
        id: 'quoteandbind.ngh.views.valuables.limitsValidationErrorTextParagraph1',
        defaultMessage: 'Looks like you’ve added an item of higher value than we would want to quote online or your total valuables exceeds our maximum limit.'
    },
    limitsValidationErrorTextParagraph2: {
        id: 'quoteandbind.ngh.views.valuables.limitsValidationErrorTextParagraph2',
        defaultMessage: 'Don’t worry, we have several products that may be suitable for you and we’d like to speak to you about them. Please contact us using the option below:'
    },
    ratingServiceTimedOut: {
        id: 'quoteandbind.ngh.views.valuables.ratingServiceTimedOut',
        defaultMessage: 'Rating service is timed out'
    }
});
