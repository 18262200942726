export default [
    {
        stepId: 0,
        title: {
            id: 'portal.wizardStep.Before you start',
            defaultMessage: 'Before you start',
        },
        relatedWizardStepIds: [0],
        progressInfoId: 'beforeYouStart'
    },
    {
        stepId: 1,
        title: {
            id: 'portal.wizardStep.You and your policy',
            defaultMessage: 'You and your policy',
        },
        relatedWizardStepIds: [1]
    },
    {
        stepId: 2,
        title: {
            id: 'portal.wizardStep.About your home',
            defaultMessage: 'About your home',
        },
        relatedWizardStepIds: [2]
    },
    {
        stepId: 3,
        title: {
            id: 'portal.wizardStep.More about your home',
            defaultMessage: 'More about your home',
        },
        relatedWizardStepIds: [3]
    },
    {
        stepId: 4,
        title: {
            id: 'portal.wizardStep.Your valuables',
            defaultMessage: 'Your valuables',
        },
        relatedWizardStepIds: [4]
    },
    {
        stepId: 5,
        title: {
            id: 'portal.wizardStep.Personalize your cover',
            defaultMessage: 'Personalise your cover',
        },
        relatedWizardStepIds: [5]
    },
    {
        stepId: 6,
        title: {
            id: 'portal.wizardStep.Policy summary',
            defaultMessage: 'Policy summary',
        },
        relatedWizardStepIds: [6]
    },
    {
        stepId: 7,
        title: {
            id: 'portal.wizardStep.Pay and finish',
            defaultMessage: 'Pay and finish',
        },
        relatedWizardStepIds: [7, 8, 9, 10]
    }
];
