import { defineMessages } from 'react-intl';

export default defineMessages({
    wineCollection: {
        id: 'quote.bespoke.components.fineArtsSummaryItem.wineCollection',
        defaultMessage: 'Wine collection'
    },
    type: {
        id: 'quote.bespoke.components.fineArtsSummaryItem.type',
        defaultMessage: 'Type'
    },
    agreedValue: {
        id: 'quote.bespoke.components.fineArtsSummaryItem.agreedValue',
        defaultMessage: 'Agreed value'
    },
});
