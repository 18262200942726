import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import messages from './CostBreakdownBox.messages';
import metadata from './CostBreakdownBox.metadata.json5';
import styles from './CostBreakdownBox.module.scss';

function CostBreakdownBox(props) {
    const {
        submissionVM, previousStepAvailable, steps, jumpTo, changeNextSteps, currentStepIndex
    } = props;
    const translator = useContext(TranslatorContext);
    const isCash = _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash';
    const offeredQuotes = _.get(submissionVM, 'quoteData.value.offeredQuotes');
    const chosenQuoteId = _.get(submissionVM, 'bindData.value.chosenQuote');

    const getNewTotalCost = useCallback(() => {
        const selectedVersion = offeredQuotes?.find((quote) => quote.publicID === chosenQuoteId);
        const premium = isCash ? `£${selectedVersion.premium?.total?.amount?.toFixed(2)}`
            : `£${selectedVersion.premium?.monthlyPremium?.amount?.toFixed(2)}`;

        return premium;
    }, [offeredQuotes, chosenQuoteId, isCash]);

    const overrideProps = {
        costInfoBox: {
            modelVM: submissionVM,
            isPerYear: isCash,
            titleText: translator(messages.yourNewPremium),
            premiumText: getNewTotalCost(),
            policyNumber: _.get(submissionVM.value, 'quoteID'),
            policyStartDate: _.get(submissionVM.value, 'baseData.periodStartDate'),
            previousStepAvailable: previousStepAvailable,
            steps: steps,
            jumpTo: jumpTo,
            changeNextSteps: changeNextSteps,
            currentStepIndex: currentStepIndex
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default CostBreakdownBox;
