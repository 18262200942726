import React, {
    useState, useCallback, useContext, useEffect
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import metadata from './SaveNewQuote.metadata.json5';
import styles from './SaveNewQuote.module.scss';
import './SaveNewQuote.messages';

function SaveNewQuote(props) {
    const { onSaveNewQuote } = props;
    const [newSubmissionVM, updateNewSubmissionVM] = useState(null);
    const viewModelService = useContext(ViewModelServiceContext);
    const { returnToDashboard } = useWizardActions();
    const MAX_PERIOD_START_DATE_DAYS_SHIFT = 30;
    const minDate = moment();
    const maxDate = moment().add(MAX_PERIOD_START_DATE_DAYS_SHIFT, 'days');

    useEffect(() => {
        if (viewModelService) {
            const saveNewQuoteViewModel = viewModelService.create(
                {},
                'pc',
                'edge.capabilities.quote.submission.base.dto.QuoteBaseDataDTO'
            );
            updateNewSubmissionVM(saveNewQuoteViewModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const saveNewQuote = () => {
        onSaveNewQuote(newSubmissionVM.value);
    };

    const writeValue = useCallback(
        (value, path) => {
            const newSaveNewQuoteVM = viewModelService.clone(newSubmissionVM);
            _.set(newSaveNewQuoteVM, path, value);
            updateNewSubmissionVM(newSaveNewQuoteVM);
        },
        [newSubmissionVM, viewModelService]
    );

    const getMidnightFromMomentDate = useCallback((date) => {
        return date.toDate().setHours(0, 0, 0, 0);
    }, []);

    const overrideProps = {
        saveNewQuoteHomepageButton: {
            onClick: returnToDashboard
        },
        policyStartDate: {
            minDate: minDate.toDate(),
            maxDate: getMidnightFromMomentDate(maxDate)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSaveNewQuote: saveNewQuote
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={newSubmissionVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

SaveNewQuote.propTypes = {
    onSaveNewQuote: PropTypes.func.isRequired,
};

export default SaveNewQuote;
