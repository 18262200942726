import React, {
    useCallback,
    useContext
} from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import useTagManager from '../../../hooks/useTagManager';
import metadata from './ValuablesInfoCard.metadata.json5';
import styles from './ValuablesInfoCard.module.scss';
import messages from './ValuablesInfoCard.messages';

function ValuablesInfoCard(props) {
    const {
        data: submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const { pushLinkClickInfo } = useTagManager();

    const getValuablesReplacementLimit = useCallback(() => {
        const contentsCov = submissionVM?.value?.lobData?.homeLine?.lineCoverages?.coverages?.find((cov) => cov.publicID === 'HOMContentsCov');
        const HomContentsCovLimit = contentsCov?.terms?.find((term) => term.publicID === 'HOMContentsCovValuablesReplacmntCostLvlLimit');
        const HomContentsCovCost = contentsCov?.terms?.find((term) => term.publicID === 'HOMContentsCovValuablesReplacmntCostCustomer');
        if (HomContentsCovLimit) {
            if (HomContentsCovLimit.chosenTermValue.indexOf('.') === -1) return HomContentsCovLimit.chosenTermValue.concat('.00');
            return HomContentsCovLimit.chosenTermValue;
        }
        if (HomContentsCovCost) {
            if (HomContentsCovCost.chosenTermValue.indexOf('.') === -1) return HomContentsCovCost.chosenTermValue.concat('.00');
        }
        return '';
    }, [submissionVM]);

    const openFullList = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/help-and-advice/calculating-home-contents/';
        pushLinkClickInfo(translator(messages.fullList), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        maxCover: {
            content: `£${getValuablesReplacementLimit()}`
        },
        fullList: {
            onClick: openFullList
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default ValuablesInfoCard;
