import { defineMessages } from 'react-intl';

export default defineMessages({
    houseNumberOrName: {
        id: 'address.addressSearch.houseNumberOrName',
        defaultMessage: 'House number or name of your home'
    },
    enterYourHouseNumberOrName: {
        id: 'address.addressSearch.enterYourHouseNumberOrName',
        defaultMessage: 'Enter your house number or name'
    },
    postcode: {
        id: 'address.addressSearch.postcode',
        defaultMessage: 'Postcode'
    },
    enterYourPostcode: {
        id: 'address.addressSearch.enterYourPostcode',
        defaultMessage: 'Enter your postcode'
    },
    findAddress: {
        id: 'address.addressSearch.findAddress',
        defaultMessage: 'Find address'
    },
    selectAddressFromTheList: {
        id: 'address.addressSearch.selectAddressFromTheList',
        defaultMessage: 'Please select your address from the list'
    },
    manuallyEnterAddress: {
        id: 'address.addressSearch.manuallyEnterAddress',
        defaultMessage: 'Manually enter address'
    },
    editAddress: {
        id: 'address.addressSearch.editAddress',
        defaultMessage: 'Edit address'
    },
    searchAgain: {
        id: 'address.addressSearch.searchAgain',
        defaultMessage: 'Search again'
    },
    addressNotFound: {
        id: 'address.addressSearch.addressNotFound',
        defaultMessage: 'Address not found - Please try again or enter address manually'
    }
});
