import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import metadata from './BespokeValuables.metadata.json5';
import styles from './BespokeValuables.module.scss';
import messages from './BespokeValuables.messages';
import BespokeValuableItems from '../BespokeValuableItems/BespokeValuableItems';
import useCurrency from '../../../nfum-capability-quoteandbind-ngh-react/hooks/useCurrency';

function BespokeValuables(props) {
    const { scheduleItems, submissionVM } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);
    const category = Object.freeze({
        Jewellery: 'JewelleryAndGemstones',
        Watches: 'Watches',
        Guns: 'Guns',
        Furs: 'Furs'
    });

    const homeLineCoverages = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages');
    const valuablesCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMValuablesCov');
    const jewelleryRepalcementCost = valuablesCov?.terms?.find((term) => term.publicID === 'HOMValuablesCovUnspecJewelGem');
    const fursRepalcementCost = valuablesCov?.terms?.find((term) => term.publicID === 'HOMValuablesCovUnspecFurs');
    const gunsRepalcementCost = valuablesCov?.terms?.find((term) => term.publicID === 'HOMValuablesCovUnspecGuns');

    const getAddedValuable = useCallback((scheduledItems, categoryVal) => {
        const valuableItems = [];
        scheduledItems.forEach((item) => {
            if (item.itemData?.ArticleType?.typeCodeValue === categoryVal) {
                valuableItems.push(item);
            }
        });
        return valuableItems;
    }, []);

    const valuablesCollections = useCallback(() => {
        return {
            collections: [
                {
                    name: 'Jewellery and Gemstones',
                    valuables: getAddedValuable(scheduleItems, category.Jewellery)
                },
                {
                    name: 'Watches',
                    valuables: getAddedValuable(scheduleItems, category.Watches)
                },
                {
                    name: 'Guns',
                    valuables: getAddedValuable(scheduleItems, category.Guns)
                },
                {
                    name: 'Furs',
                    valuables: getAddedValuable(scheduleItems, category.Furs)
                }
            ],
        };
    }, [category,
        scheduleItems,
        getAddedValuable]);

    const generateOverrides = useCallback(() => {
        if (!valuablesCollections) return Object.assign({});

        const valuablesAddedOverrides = valuablesCollections().collections
            .filter((x) => x.valuables.length > 0)
            .map((item, index) => {
                return {
                    [`bespokeValuableItems${index}`]: {
                        scheduleItems: item,
                        index: index
                    }
                };
            });

        return Object.assign({},
            ...valuablesAddedOverrides);
    }, [valuablesCollections
    ]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        specifiedValuablesItems: {
            visible: scheduleItems.length > 0,
            data: valuablesCollections().collections.filter((x) => x.valuables.length > 0)
        },
        jewelleryReplacementCost: {
            value: currencyFormatter
                .formatCurrency(jewelleryRepalcementCost?.chosenTerm,
                    true)
        },
        fursReplacementCost: {
            value: currencyFormatter
                .formatCurrency(fursRepalcementCost?.chosenTerm,
                    true)
        },
        gunsReplacementCost: {
            value: currencyFormatter
                .formatCurrency(gunsRepalcementCost?.chosenTerm,
                    true)
        },
        unspecifiedValuablesListsHeader: {
            className: 'unspecifiedValuablesListsHeader',
            labelClassName: 'unspecifiedValubalesListsHeaderLabel',
            value: translator(messages.replacementCost)
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            bespokeValuableItems: BespokeValuableItems
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
        />
    );
}

export default BespokeValuables;
