import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './AdditionalCoversSummary.messages';
import metadata from './AdditionalCoversSummary.metadata.json5';
import styles from './AdditionalCoversSummary.module.scss';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import useLobs from '../../../hooks/useLobs';

function AdditionalCoversSummary(props) {
    const {
        submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const { getPropertyTitle } = usePropertyTitle();
    const { isBespokeJourney } = useLobs(submissionVM);

    const coversIds = Object.freeze({
        HOM_HOME_EMERGENCY_COV: 'HOMHomeEmergencyCov',
        LEX_PERSONAL_LEGAL_EXPENSES_COV: 'LEXPersonalLegalExpensesCov',
        CONTENTS_AWAY_COV: 'HOMContentsAwayCov',
        HOM_CONTENTS_AWAY_BICYCLE_EXTENSION: 'HOMContentsAwayCovPedalCycleExtension',
        HOM_PERSONAL_CYBER_COV: 'HOMPersonalCyberCov',
    });
    const LEVEL_OF_COVER_NAME = 'Level of Cover';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const YES_CODE = 'Yes';
    const lexLineCoverages = _.get(submissionVM, 'lobData.value.lexLine.lineCoverages.coverages');
    const legalExpensesCover = lexLineCoverages
        ?.find((cov) => cov.publicID === coversIds.LEX_PERSONAL_LEGAL_EXPENSES_COV);
    const isLegalExpensesCover = legalExpensesCover?.selected;
    const legalExpensesLevelOfCover = legalExpensesCover?.terms
        ?.find((term) => term.name === LEVEL_OF_COVER_NAME)?.chosenTermValue;
    const legalExpensesLevelOfCoverText = legalExpensesLevelOfCover === LEGAL_EXPENSES_EXTRA_KEY
        ? translator(messages.personalLegalExpensesExtra)
        : translator(messages.personalLegalExpenses);
    const homeLineCoverages = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages');
    const isHomeEmergencyCov = homeLineCoverages?.find((cov) => cov.publicID === 'HOMHomeEmergencyCov')?.selected;
    const contentsAwayCov = homeLineCoverages
        ?.find(((cov) => cov.publicID === coversIds.CONTENTS_AWAY_COV));
    const isBicycleExtensionCoverTerm = contentsAwayCov?.terms
        ?.find((term) => term.publicID === coversIds.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION)
        ?.chosenTermValue === YES_CODE;
    const homeProperty = _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty');

    const handleSelectMessage = () => {
        if (isBespokeJourney) return translator(messages.included);
        else return translator(messages.added);
    };

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        additionalCoversHomePropertyTitle: {
            content: getPropertyTitle(homeProperty, false)
        },
        bicycleExtensionCover: {
            visible: !isBespokeJourney && !!contentsAwayCov,
            value: isBicycleExtensionCoverTerm
                ? translator(messages.added)
                : translator(messages.notSelected)
        },
        personalCyberCover: {
            visible: isBespokeJourney,
            value: translator(messages.included)
        },
        homeEmergencyCover: {
            value: isHomeEmergencyCov
                ? handleSelectMessage()
                : translator(messages.notSelected)
        },
        lineCoveragesTitle: {
            content: translator(messages.nonPropertySpecificCovers)
        },
        legalExpensesCover: {
            value: isLegalExpensesCover
                ? legalExpensesLevelOfCoverText
                : translator(messages.notSelected)
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default AdditionalCoversSummary;
