import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import {
    useErrorHandler,
    QB_STEPS,
    QUOTE_RETRIEVAL_STEPS,
    ERROR_STEPS,
    AppContext
} from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { NfumLoader } from 'nfum-components-platform-react';
import NGHContext from '../../NGHContext';
import metadata from './PaymentPage.metadata.json5';
import messages from './PaymentPage.messages';
import styles from './PaymentPage.module.scss';
import SectionComponent from '../../components/SectionComponent/SectionComponent';
import PaymentPremiumInfoBox from '../../components/PaymentPremiumInfoBox/PaymentPremiumInfoBox';
import useTagManager from '../../hooks/useTagManager';
import useCleanPayload from '../../hooks/useCleanPayload';

const PaymentPage = ({
    wizardData: submissionVM, updateWizardData, goNext, steps, jumpTo, isSkipping
}) => { /* NOSONAR: pure declarative usage */
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useContext(TranslatorContext);
    const [redirectUrl, setRedirectUrl] = useState('');
    const { handleError } = useErrorHandler();
    const { setIsPaymentSucceed, isPaymentSucceed: isAlreadyPaid } = useContext(NGHContext);
    const { isQuoteRetrieval } = useContext(AppContext);
    const { pushFormStepInfo, pushFormStepErrorInfo, pushRelativeLinkClickInfo } = useTagManager();
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    const {
        onValidate,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('PaymentPage');

    const isPaymentMockEnabled = _.get(submissionVM.value, 'digitalPaymentMock_NFUM', false);
    const [showTermsAndCondition, setShowTermsAndCondition] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, isQuoteRetrieval
                        ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                        : QB_STEPS.PAYMENT);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, isQuoteRetrieval
                ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                : QB_STEPS.PAYMENT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipWhenRetrieved = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Bound';
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(skipWhenRetrieved);
    }, [skipWhenRetrieved, registerInitialComponentValidation]);

    const handleCancelTransaction = useCallback(() => {
        const url = '/policy-summary';
        pushRelativeLinkClickInfo(translator(messages.cancelTransaction), url);
        const indexOfQuoteSummary = _.findIndex(
            steps,
            ({ path }) => path === url
        );
        jumpTo(indexOfQuoteSummary);
    }, [steps, pushRelativeLinkClickInfo, jumpTo, translator]);

    useEffect(() => {
        window.addEventListener('message', async (event) => {
            if (event.origin === process.env.REACT_APP_NFUM_PAYMENT_STATUS_ORIGIN) {
                const quoteNumber = _.get(submissionVM.value, 'quoteID');
                const paymentStatus = await LoadSaveService.checkPaymentStatus_NFUM(
                    quoteNumber,
                    authHeader
                );
                if (paymentStatus === 'CAPTURED') {
                    setIsPaymentSucceed(true);
                    goNext();
                    return;
                }
                handleCancelTransaction();
            } else {
                handleError(`Payment status origin different from configured host: ${event.origin}`);
            }
        }, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRedirectUrl = useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await LoadSaveService.getRedirectURL(
                [submissionVM.value.quoteID],
                authHeader
            );
            if (!_.isEmpty(res)) {
                setRedirectUrl(res);
                setShowTermsAndCondition(false);
                setIsLoading(false);
            } else {
                pushFormStepErrorInfo(submissionVM, isQuoteRetrieval
                    ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                    : QB_STEPS.PAYMENT, null, ERROR_STEPS.PAYMENT_ERROR);
                handleError(null, submissionVM.value.quoteID);
                setIsLoading(false);
            }
        } catch (error) {
            pushFormStepErrorInfo(submissionVM, isQuoteRetrieval
                ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                : QB_STEPS.PAYMENT, null, ERROR_STEPS.PAYMENT_ERROR);
            handleError(error, submissionVM.value.quoteID);
            setIsLoading(false);
        }
    }, [
        LoadSaveService,
        authHeader,
        handleError,
        submissionVM,
        pushFormStepErrorInfo,
        isQuoteRetrieval
    ]);

    useEffect(() => {
        if (isAlreadyPaid) {
            goNext();
        } else if (_.get(submissionVM, 'baseData.value.paymentMethod_NFUM') !== 'cash') {
            if (isPaymentMockEnabled) setRedirectUrl('');
            else fetchRedirectUrl();
        }
    }, []);

    const handleTermsSelector = (value, path) => {
        const newSubmissionVm = _.clone(submissionVM);
        _.set(newSubmissionVm, path, value);
        updateWizardData(newSubmissionVm);
        const isTermsAndConditionChecked = submissionVM?.bindData?.termsAndConditions_NFUM?.value;
        const isMutualAndCharitable = submissionVM?.bindData?.mutualityAndcharitable_NFUM?.value;
        if (isTermsAndConditionChecked && isMutualAndCharitable) {
            if (isPaymentMockEnabled) setRedirectUrl('');
            else fetchRedirectUrl();
        }
    };
    const isCreditCardPayment = _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash';
    const selectedPaymentPlanId = _.get(submissionVM, 'bindData.value.selectedPaymentPlan');
    const paymentPlans = _.get(submissionVM, 'bindData.value.paymentPlans');
    const selectedPaymentPlan = paymentPlans
        .find((plan) => plan.billingId === selectedPaymentPlanId);
    const installments = selectedPaymentPlan.installmentDetails_NFUM;
    const depositAmount = selectedPaymentPlan.depositAmount_NFUM;
    let initialAmountForSupplementary = null;
    let nextAmount = null;
    if (installments !== undefined) {
        initialAmountForSupplementary = depositAmount
            ? depositAmount.amount.toFixed(2) : null;
        nextAmount = installments.length > 1
            ? installments[1].installmentAmount.amount.toFixed(2) : null;
    }
    const totalPremiumCost = _.get(submissionVM, 'quoteData.offeredQuotes.value[0].premium.total.amount')?.toFixed(2);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            sectionComponent: SectionComponent,
            paymentPremiumInfoBox: PaymentPremiumInfoBox
        }
    };

    const onMockPay = async () => {
        const newSubmissionVM = _.clone(submissionVM);
        _.set(newSubmissionVM, 'bindData.value.paymentStatusDTO_NFUM.status', 'paid');
        cleanNotUpdatedCoverages(newSubmissionVM);
        cleanCostInfos(newSubmissionVM);
        await LoadSaveService.updateQuotedSubmission(newSubmissionVM.value, authHeader);
        updateWizardData(newSubmissionVM);
        setIsPaymentSucceed(true);
        goNext();
    };

    const modifyEmailAddress = useCallback((value, path) => {
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const overrideProps = {
        paymentPagePayApp: {
            src: !isPaymentMockEnabled ? redirectUrl : '',
            visible: !isPaymentMockEnabled
        },
        paymentPagePayAppMock: {
            visible: isPaymentMockEnabled,
            onClick: onMockPay
        },
        creditCardPaymentPremiumBox: {
            visible: isCreditCardPayment,
            titleText: translator(messages.creditCardPaymentTitle),
            premiumText: `£${totalPremiumCost}`,
            isPerYear: true,
            extraText: translator(messages.noExtraCharge)
        },
        supplementaryPaymentPremiumBox: {
            visible: !isCreditCardPayment,
            titleText: translator(messages.supplementaryPaymentTitle),
            premiumText: `£${initialAmountForSupplementary}*`,
            costForRestMonthsText: `£${nextAmount}*`,
            topText: translator(messages.supplementaryPaymentTopText)
        },
        TermsContainer: {
            visible: isCreditCardPayment && showTermsAndCondition,
            onWriteValue: handleTermsSelector,
            submissionVM
        },
        paymentPageSetupDDFormEmail: {
            onValueChange: modifyEmailAddress
        }
    };

    if (isLoading) {
        return <NfumLoader loaded={!isLoading} />; /* NOSONAR: pure declarative usage */
    }

    return (
        <WizardPage
            skipWhen={initialValidation}
            showCancel={false}
            showPrevious={false}
            showNext={false}
        >
            <ViewModelForm
                onValidationChange={onValidate}
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                model={submissionVM}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
};

PaymentPage.propTypes = wizardProps;
export default PaymentPage;
