import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    WizardContext,
    pageTemplateProps
} from 'gw-portals-wizard-react';
import {
    NfumLoaderButton
} from 'nfum-components-platform-react';
import MarketingSidebar from '../components/MarketingSidebar/MarketingSidebar';
import styles from './NfumDigitalWizardPageTemplate.module.scss';
import messages from './NfumDigitalWizardPageTemplate.messages';

function DigitalWizardPageTemplate(props) { /* NOSONAR: pure declarative usage */
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const wizardContext = useContext(WizardContext);
    const isPhone = breakpoint === 'phone';
    const isphoneWide = breakpoint === 'phoneWide';
    const Istablet = breakpoint === 'tablet';
    const [showSidebar, setShowSidebar] = useState(false);
    const { currentStep } = wizardContext;
    const [showPaymentSideBar, setShowPaymentSideBar] = useState(false);
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        showEmailQuote,
        onEmailQuote,
        disableEmailQuote,
        emailQuoteLabel,
        showCancelTransaction,
        onCancelTransaction,
        disableCancelTransaction,
        cancelTransactionLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        showSessionTimedOut,
        renderSessionTimedOut,
        hideSidebar
    } = props;

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
                setShowSidebar(false);
                setShowPaymentSideBar(false);
                break;
            default:
                setShowSidebar(true);
                setShowPaymentSideBar(false);
                break;
        }
    }, [breakpoint, currentStep.path]);

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar || isphoneWide || Istablet
    });

    const handleOnNext = useCallback(() => {
        return onNext();
    }, [onNext]);

    const nextButton = useMemo(() => (
        <NfumLoaderButton
            id="gw-wizard-Next"
            className={styles.nextButton}
            disabled={disableNext || isPerformingTransiton}
            onClick={handleOnNext}
            icon={isLoadingNext ? '' : 'gw-keyboard-arrow-right'}
            iconPosition="right"
            isLoading={isLoadingNext}
        >
            {translator(nextLabel)}
        </NfumLoaderButton>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, handleOnNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isPhone
    });

    return (
        <div className={styles.digitalWizard}>
            {showContextComponent && contextComponent()}
            <div className={wizardClassName}>
                <div className={styles.digitalContentContainer}>
                    <div className={styles.wizardContent}>
                        {showWizardHeader && contextComponent()}
                        {showSessionTimedOut && renderSessionTimedOut()}
                        {content}
                        <div className={classNames({ [styles.navButtons]: shouldShowButtons })}>
                            {showCancelTransaction && (
                                <Button
                                    id="gw-wizard-cancel-transaction"
                                    className={styles.cancelTransactionButton}
                                    disabled={disableCancelTransaction || isPerformingTransiton}
                                    type="text"
                                    onClick={onCancelTransaction}
                                >
                                    { translator(cancelTransactionLabel)
                                        || translator(messages.cancelTransaction) }
                                </Button>
                            )}
                            {showCancel && (
                                <NfumLoaderButton
                                    id="gw-wizard-cancel"
                                    className={styles.cancelButton}
                                    disabled={disableCancel || isPerformingTransiton}
                                    type="text"
                                    onClick={onCancel}
                                    isLoading={isLoadingCancel}
                                >
                                    {translator(cancelLabel)}
                                </NfumLoaderButton>
                            )}
                            {showEmailQuote && (
                                <Button
                                    id="gw-wizard-email"
                                    className={styles.emailButton}
                                    disabled={disableEmailQuote || isPerformingTransiton}
                                    type="text"
                                    icon="gw-email"
                                    iconPosition="right"
                                    onClick={onEmailQuote}
                                >
                                    {translator(emailQuoteLabel) || translator(messages.emailQuote)}
                                </Button>
                            )}
                            <div className={buttonContainerClassName}>
                                {showPrevious && (
                                    <NfumLoaderButton
                                        id="gw-wizard-previous"
                                        className={styles.previousButton}
                                        disabled={disablePrevious || isPerformingTransiton}
                                        type="outlined"
                                        onClick={onPrevious}
                                        icon={isLoadingPrevious ? '' : 'gw-keyboard-arrow-left'}
                                        iconPosition="left"
                                        isLoading={isLoadingPrevious}
                                    >
                                        {translator(previousLabel)}
                                    </NfumLoaderButton>
                                )}
                                {showNext && renderNextButton}
                            </div>
                        </div>
                    </div>
                    {!hideSidebar && (
                        <div className={classNames({ [styles.wizardNavigation]: !isPhone })}>
                            <MarketingSidebar
                                pageId={currentStep.path.replace('/', '')}
                            />
                            {!isPhone || showSidebar ? (
                                <div className={styles.wizardWidgets}>
                                    {renderWidgets(wizardData)}
                                </div>
                            ) : null}
                        </div>
                    )}
                    {showPaymentSideBar && (
                        <div className={styles.paymentSideBar}>
                            <div className="nfumMarginTop6">
                                <MarketingSidebar
                                    pageId={currentStep.path.replace('/', '')}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

DigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    showSessionTimedOut: PropTypes.bool,
    nextButtonTooltip: PropTypes.string,
    showEmailQuote: PropTypes.bool,
    disableEmailQuote: PropTypes.bool,
    onEmailQuote: PropTypes.func,
    emailQuoteLabel: PropTypes.string,
    showCancelTransaction: PropTypes.bool,
    disableCancelTransaction: PropTypes.bool,
    onCancelTransaction: PropTypes.func,
    cancelTransactionLabel: PropTypes.string,
};

DigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: false,
    showWizardHeader: false,
    showSessionTimedOut: false,
    nextButtonTooltip: '',
    showEmailQuote: false,
    disableEmailQuote: false,
    onEmailQuote: () => null,
    emailQuoteLabel: '',
    showCancelTransaction: false,
    disableCancelTransaction: false,
    onCancelTransaction: () => null,
    cancelTransactionLabel: ''
};

export default DigitalWizardPageTemplate;
