import { defineMessages } from 'react-intl';

export default defineMessages({
    WithNFUMutualOnlineAccountYouCan: {
        id: 'quoteandbind.ngh.views.success.registered.WithNFUMutualOnlineAccountYouCan',
        defaultMessage: 'In your NFU Mutual online account, you can:'
    },
    accountText: {
        id: 'quoteandbind.ngh.views.success.registered.accountText',
        defaultMessage: "Digital versions of your documents, as well as other useful information and features are waiting for you in your online account. Here's some of the things you can access there:"
    },
    loginButton: {
        id: 'quoteandbind.ngh.views.success.registered.loginButton',
        defaultMessage: 'Log in '
    }
});
