import { setComponentMapOverrides } from '@jutro/uiconfig';

import QuoteWizardPageTemplate from './components/QuoteWizardPageTemplate';
import LineOfBusiness from './components/LineOfBusiness/LineOfBusiness';
import RetrieveQuote from './components/RetrieveQuote/RetrieveQuote';
import FineArtSummary from './components/FineArtSummary/FineArtsCollectionsSummary'; // nfum custom
import BespokeValuables from './components/BespokeValuables/BespokeValuables'; // nfum custom

export { default as commonMessages } from './quoteandbind.messages';
export { default as commonQuoteStyles } from './quoteandbind-common-styles.module.scss';
export { default as FineArtsCollectionsMessages } from './components/FineArtSummary/FineArtsCollectionsSummary.messages'; // nfum custom
export { default as FineArtsCollectionsStyles } from './components/FineArtSummary/FineArtsCollectionsSummary.module.scss'; // nfum custom
export { default as ValuablesSummary } from './components/BespokeValuables/BespokeValuables.messages'; // nfum custom
export { default as ValuablesStyles } from './components/BespokeValuables/BespokeValuables.module.scss'; // nfum custom
export { default as PaymentDetailsPage } from './pages/PaymentDetails/PaymentDetailsPage';
export { default as QualificationPage } from './pages/Qualification/QualificationPage';
export { default as QuoteInfo } from './components/CommonQuoteInfo/CommonQuoteInfo';
export { default as QuickQuoteWizardPageTemplate } from './components/QuickQuoteWizardPageTemplate';
export { QuoteInfoComponentContext, withQuoteInfoComponent } from './components/QuoteInfoComponentContext';
export { default as WizardSidebarButtons } from './components/WizardSidebarButtons/WizardSidebarButtons'; // nfum custom
export { QuoteWizardPageTemplate };
export { FineArtSummary }; // nfum custom
export { BespokeValuables }; // nfum custom

setComponentMapOverrides(
    {
        QuoteWizardPageTemplate: { component: 'QuoteWizardPageTemplate' },
        LineOfBusiness: { component: 'LineOfBusiness' },
        RetrieveQuote: { component: 'RetrieveQuote' },
        FineArtSummary: { component: 'FineArtSummary' }, // nfum custom
        BespokeValuables: { component: 'BespokeValuables' }, // nfum custom
    },
    {
        QuoteWizardPageTemplate,
        LineOfBusiness,
        RetrieveQuote,
        FineArtSummary, // nfum custom
        BespokeValuables // nfum custom
    }
);
