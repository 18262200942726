import React, { useCallback } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { useCurrency } from '../../nfum-portals-utils-react';
import metadata from './NfumCostItem.metadata.json5';
import styles from './NfumCostItem.module.scss';

function NfumCostItem(props) {
    const {
        costInfo
    } = props;
    const currencyFormatter = useCurrency();

    const getAmount = useCallback(() => {
        const amount = currencyFormatter.formatCurrency(`${_.get(costInfo, 'grossAnnualPremium')}`, true);
        return amount.includes('NaN') ? _.get(costInfo, 'grossAnnualPremium') : amount;
    }, [currencyFormatter, costInfo]);

    const overrideProps = {
        costInfoCoverNameColumn: {
            content: costInfo.displayName
        },
        costInfoAmountColumn: {
            content: getAmount()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={costInfo}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default NfumCostItem;
