/* eslint-disable */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CheckboxField, Icon, Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import classNames from 'classnames';

import messages from './NfumCheckboxField.messages';
import styles from './NfumCheckboxField.module.scss';

function NfumCheckboxField(props) {
    const translator = useContext(TranslatorContext);

    const { value, tooltip } = props;
    const [tooltipHidden, updateTooltipHidden] = useState(true);

    const checkboxStyles = classNames(styles.nfumCheckbox, {
        [styles.nfumCheckboxSelected]: value
    });

    const toggleTooltip = (event) => {
        if (event.type === "keydown" && event.keyCode !== 13) {
            return;
        }
        updateTooltipHidden(!tooltipHidden);
    }

    const renderTooltipIcon = () => {
        return (
            <button
                type="button"
                className={styles.tooltipIcon}
                tabindex="-1"
            >
                <Icon
                    icon="gw-info-outline"
                    aria-hidden="true"
                    onClick={toggleTooltip}
                    onKeyDown={toggleTooltip}
                    tabindex="0"
                    className={classNames({[styles.tooltipVisible]: !tooltipHidden})}
                />
            </button>
        );
    }

    const tooltipIcon = tooltip !== undefined && renderTooltipIcon();

    return (
        <React.Fragment>
            <div className={styles.nfumCheckboxContainer}>
                <CheckboxField
                    {...props}
                    tooltip={null}
                    className={checkboxStyles}
                />
                {tooltipIcon}
            </div>
            <div className={classNames(styles.tooltipContainer, {[styles.tooltipHidden]: tooltipHidden})} >
                <div className={styles.tooltipText}>
                    {tooltip !== undefined && translator(tooltip.text)}
                </div>
                <Button
                    type="text"
                    icon="gw-close"
                    iconPosition="right"
                    onClick={toggleTooltip}
                    tabindex="-1"
                >
                    {translator(messages.close)}
                </Button>
            </div>
        </React.Fragment>
    );
}

NfumCheckboxField.propTypes = {
    value: PropTypes.bool
};

NfumCheckboxField.defaultProps = {
    value: false
};

export default NfumCheckboxField;
