import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionTimeout: {
        id: 'inactiveModal.sessionTimeout',
        defaultMessage: 'Session Timeout'
    },
    inactiveModalInfo: {
        id: 'inactiveModal.inactiveModalInfo',
        defaultMessage: 'Your session is being timed out due to inactivity'
    },
    inactiveModalDescription: {
        id: 'inactiveModal.inactiveModalDescription',
        defaultMessage: 'Please choose to either continue with your quote or quit without saving.'
    },
    continueSessionButton: {
        id: 'inactiveModal.continueSessionButton',
        defaultMessage: 'Continue session'
    }
});
