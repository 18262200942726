import { defineMessages } from 'react-intl';

export default defineMessages({
    remove: {
        id: 'quoteandbind.ngh.components.addOtherOccupation.remove',
        defaultMessage: 'Remove'
    },
    addAnother: {
        id: 'quoteandbind.ngh.components.addOtherOccupation.addAnother',
        defaultMessage: 'Add another'
    }
});
