import React, { useContext } from 'react';
import { WizardContext } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import styles from './WizardHeader.module.scss';
import messages from './WizardHeader.messages';

const WizardHeader = (props) => {
    const { stepsConfig } = props;
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const {
        currentStep
    } = wizardContext;

    const renderCurrentTitle = () => {
        let title = '';
        stepsConfig.forEach((step) => {
            if (step.relatedWizardPaths.includes(currentStep.path)) {
                title = step.title;
            }
        });
        return translator(title);
    };

    const { wizardData } = wizardContext;
    return (
        <div className={styles.container}>
            <div className={styles.seperator} />
            <nav className={styles.progressBarContainer}>
                <div className={styles.progressBarHeader}>
                    <div className={styles.progressBarTitle}>
                        {renderCurrentTitle()}
                    </div>
                    { wizardData.value.policyNumber && (
                        <div className={styles.policyNumberReference}>
                            <span>
                                {translator(messages.policyNumber)}
                                {wizardData.value.policyNumber}
                            </span>
                        </div>
                    )}
                    { wizardData.value.quoteID && (
                        <div className={styles.reference}>
                            <span>
                                {translator(messages.transactionReference)}
                                {wizardData.value.quoteID}
                            </span>
                        </div>
                    )}
                </div>
            </nav>
        </div>
    );
};

WizardHeader.propTypes = {
    stepsConfig: PropTypes.shape({})
};
WizardHeader.defaultProps = {
    stepsConfig: undefined
};
export default WizardHeader;
