import { defineMessages } from 'react-intl';

export default defineMessages({
    isAnyClaim: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.isAnyClaim',
        defaultMessage: 'In the past five years, have you or anyone else in your household made a home insurance claim, had a loss not covered by insurance or had a claim made against them?'
    },
    typeOfClaim: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.typeOfClaim',
        defaultMessage: 'Type of claim'
    },
    typeOfLoss: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.typeOfLoss',
        defaultMessage: 'What was the claim for?'
    },
    totalIncurred: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.totalIncurred',
        defaultMessage: 'Claim amount'
    },
    lossDate: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.lossDate',
        defaultMessage: 'Claim date'
    },
    status: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.status',
        defaultMessage: 'What is the current status of the claim?'
    },
    isClaimAndInsuredLocationTheSame: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.isClaimAndInsuredLocationTheSame',
        defaultMessage: 'Was the claim at the property you wish to insure?'
    },
    remove: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.remove',
        defaultMessage: 'Remove'
    },
    addAnother: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.addAnother',
        defaultMessage: 'Add another'
    },
    yes: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.no',
        defaultMessage: 'No'
    },
    open: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.open',
        defaultMessage: 'Open'
    },
    closed: {
        id: 'quoteandbind.ngh.views.your-details.claims-history.closed',
        defaultMessage: 'Closed'
    }
});
