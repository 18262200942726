import { defineMessages } from 'react-intl';

export default defineMessages({
    weCanCallYou: {
        id: 'quoteandbind.pages.serviceUnavailablePage.weCanCallYou',
        defaultMessage: 'We can call you at a time that\'s convenient for you'
    },
    bookACallBack: {
        id: 'quoteandbind.pages.serviceUnavailablePage.bookACallBack',
        defaultMessage: 'Book a call back'
    },
    alternativelyYouCan: {
        id: 'quoteandbind.pages.serviceUnavailablePage.alternativelyYouCan',
        defaultMessage: 'We\'ve saved your progress and will be able to pick up with you where you left off. Alternatively you can '
    },
    or: {
        id: 'quoteandbind.pages.serviceUnavailablePage.or',
        defaultMessage: ' or '
    },
    sendUsAnEmail: {
        id: 'quoteandbind.pages.serviceUnavailablePage.sendUsAnEmail',
        defaultMessage: 'send us an email.'
    },
    quoteNumber: {
        id: 'quoteandbind.pages.serviceUnavailablePage.quoteNumber',
        defaultMessage: 'Quote number'
    },
    close: {
        id: 'quoteandbind.pages.serviceUnavailablePage.close',
        defaultMessage: 'Close'
    },
    title: {
        id: 'quoteandbind.pages.serviceUnavailablePage.title',
        defaultMessage: 'Sorry, we are experiencing some technical problems at the moment.'
    },
    description: {
        id: 'quoteandbind.pages.serviceUnavailablePage.description',
        defaultMessage: 'We are aware of the issues and are working to solve it urgently so please try again a little later.'
    },
    description2: {
        id: 'quoteandbind.pages.serviceUnavailablePage.description2',
        defaultMessage: "We've saved your progress and will be able to pick up with you where you left off."
    },
    description3: {
        id: 'quoteandbind.pages.serviceUnavailablePage.description3',
        defaultMessage: 'We are aware of the issues and are working to solve it urgently.'
    },
    description4: {
        id: 'quoteandbind.pages.serviceUnavailablePage.description4',
        defaultMessage: 'Your progress has been saved, in order to ensure your payment information is captured correctly you will need to contact us to finalise your payment. Please note your policy will not be in force until you have contacted us.'
    },
    descriptionExtended: {
        id: 'quoteandbind.pages.serviceUnavailablePage.descriptionExtended',
        defaultMessage: 'You can also call our UK-based team, or arrange a call back for a time that suits you.'
    },
    agencyDescriptionExtended: {
        id: 'quoteandbind.pages.serviceUnavailablePage.agencyDescriptionExtended',
        defaultMessage: 'You can also contact your local Agency at a time that suits you.'
    },
    pageTitle: {
        id: 'quoteandbind.pages.serviceUnavailablePage.pageTitle',
        defaultMessage: 'Service currently unavailable'
    },
});
