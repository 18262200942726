import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './AnimalSummary.messages';
import metadata from './AnimalSummary.metadata.json5';
import styles from './AnimalSummary.module.scss';

function AnimalSummary(props) {
    const { value: animalData } = props;
    const translator = useContext(TranslatorContext);
    const catDogIds = Object.freeze({
        ANM_VETS_FEES_COV: 'ANMVetsFeesCov',
        ANM_VETS_FEES_COV_VFCOVER_TYPE: 'ANMVetsFeesCovVFCoverType',
        ANM_VETS_FEES_COV_LIMIT: 'ANMVetsFeesCovLimit',
        ANM_VETS_FEES_COV_LIMIT_BASIS: 'ANMVetsFeesCovLimitBasis',
        ANM_VETS_FEES_COV_XS_TOTAL: 'ANMVetsFeesCovXSTotal',
        ANM_LOSS_OF_ANIMAL_COV: 'ANMLossOfAnimalCov',
        ANM_LOSS_OF_ANIMAL_COV_SUMINSURED: 'ANMLossOfAnimalCovLoASumInsured',
        ANM_LOSS_OF_ANIMAL_COV_PRICEPAID: 'ANMLossOfAnimalCovLoAPricePaid',
        ANM_LOSS_OF_USE_COV: 'ANMLossOfUseCov',
        ANM_LOSS_OF_USE_COV_SUMINSURED: 'ANMLossOfUseCovLoUSumInsured',
        ANM_LOSS_OF_ANIMAL_COV_COVERTYPE: 'ANMLossOfAnimalCovLoACoverType',
        ANM_OWNERS_LIABILITY_COV: 'ANMOwnersLiabilityCov',
        ANM_OWNERS_LIABILITY_COV_LIMIT: 'ANMOwnersLiabilityCovPLIndemnityLimit'
    });

    const getAnimalCov = useCallback((anmCov, covId) => {
        return (anmCov.coverages.coverages)
            .find((cov) => cov.publicID === covId)?.selected;
    }, []);

    const resolveAnimalCovTermsValue = useCallback((anmCovTerms, covId, termId) => {
        const anvCovTermValue = (anmCovTerms.coverages.coverages)
            .find((cov) => cov.publicID === covId)
            ?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
        return anvCovTermValue || translator(messages.notSelected);
    }, [translator]);

    const resolveAnimalCovTermsSum = useCallback((anmCovTerms, covId, termId) => {
        const anvCovTermValue = (anmCovTerms.coverages.coverages)
            .find((cov) => cov.publicID === covId)
            ?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
        return anvCovTermValue ? `£${anvCovTermValue}` : translator(messages.notSelected);
    }, [translator]);

    const anmGetAnimalTitle = useCallback((animalType, animalName) => {
        const anmTitle = translator({
            id: `typekey.ANMAnimalType_NFUM.${animalType}`,
            defaultMessage: animalType
        });
        return `${anmTitle}: ${animalName}`;
    }, [translator]);

    const generateDogAndCatData = useCallback(() => { /* NOSONAR: GW Jutro specific convention */
        const catDogOverrides = animalData ? animalData.map((animal, index) => {
            const anmLossOfAnimalCov = getAnimalCov(animal, catDogIds.ANM_LOSS_OF_ANIMAL_COV);
            const anmLossOfUseCov = getAnimalCov(animal, catDogIds.ANM_LOSS_OF_USE_COV);
            const anmVetsFeesCov = getAnimalCov(animal, catDogIds.ANM_VETS_FEES_COV);
            const isDog = animal.animalType !== 'cat';
            return {
                [`dogAndCatTitle${index}`]: {
                    content: anmGetAnimalTitle(animal.animalType, animal.animalName)
                },
                [`workingDog${index}`]: {
                    value: animal.isWorkingDog ? translator(messages.yes) : translator(messages.no),
                    visible: isDog
                },
                [`breed${index}`]: {
                    value: animal.breedType
                },
                [`animalPHC${index}`]: {
                    value: animal.isPermanentHealthExtReqd
                        ? translator(messages.yes)
                        : translator(messages.notSelected)
                },
                [`pricePaid${index}`]: {
                    value: resolveAnimalCovTermsSum(animal,
                        catDogIds.ANM_LOSS_OF_ANIMAL_COV,
                        catDogIds.ANM_LOSS_OF_ANIMAL_COV_PRICEPAID),
                    visible: !animal.isWorkingDog
                },
                [`lossOfAnimalCover${index}`]: {
                    value: anmLossOfAnimalCov
                        ? translator(messages.yes)
                        : translator(messages.notSelected),
                    visible: isDog
                },
                [`lossOfAnimalSumInsured${index}`]: {
                    value: resolveAnimalCovTermsSum(animal,
                        catDogIds.ANM_LOSS_OF_ANIMAL_COV,
                        catDogIds.ANM_LOSS_OF_ANIMAL_COV_SUMINSURED),
                    visible: isDog && anmLossOfAnimalCov
                },
                [`lossOfAnimalCoverType${index}`]: {
                    value: resolveAnimalCovTermsValue(animal,
                        catDogIds.ANM_LOSS_OF_ANIMAL_COV,
                        catDogIds.ANM_LOSS_OF_ANIMAL_COV_COVERTYPE),
                    visible: isDog && anmLossOfAnimalCov
                },
                [`lossOfUseCover${index}`]: {
                    value: anmLossOfUseCov
                        ? translator(messages.yes)
                        : translator(messages.notSelected),
                    visible: isDog && animal.isWorkingDog
                },
                [`lossOfUseSumInsured${index}`]: {
                    value: resolveAnimalCovTermsSum(animal,
                        catDogIds.ANM_LOSS_OF_USE_COV,
                        catDogIds.ANM_LOSS_OF_USE_COV_SUMINSURED),
                    visible: isDog
                            && animal.isWorkingDog
                            && anmLossOfUseCov
                },
                [`vetFeesCover${index}`]: {
                    value: anmVetsFeesCov
                        ? translator(messages.yes)
                        : translator(messages.notSelected),
                },
                [`vetFeesLimit${index}`]: {
                    value: resolveAnimalCovTermsSum(animal,
                        catDogIds.ANM_VETS_FEES_COV,
                        catDogIds.ANM_VETS_FEES_COV_LIMIT),
                    visible: anmVetsFeesCov
                },
                [`vetFeesLimitsBasis${index}`]: {
                    value: resolveAnimalCovTermsValue(animal,
                        catDogIds.ANM_VETS_FEES_COV,
                        catDogIds.ANM_VETS_FEES_COV_LIMIT_BASIS),
                    visible: anmVetsFeesCov
                },
                [`vetFeesCoverType${index}`]: {
                    value: resolveAnimalCovTermsValue(animal,
                        catDogIds.ANM_VETS_FEES_COV,
                        catDogIds.ANM_VETS_FEES_COV_VFCOVER_TYPE),
                    visible: anmVetsFeesCov
                },
                [`vetFeesExcess${index}`]: {
                    value: resolveAnimalCovTermsSum(animal,
                        catDogIds.ANM_VETS_FEES_COV,
                        catDogIds.ANM_VETS_FEES_COV_XS_TOTAL),
                    visible: anmVetsFeesCov
                },
                [`ownersLiabilityCover${index}`]: {
                    value: resolveAnimalCovTermsSum(animal,
                        catDogIds.ANM_OWNERS_LIABILITY_COV,
                        catDogIds.ANM_OWNERS_LIABILITY_COV_LIMIT),
                    visible: isDog && !animal.isWorkingDog
                }
            };
        }) : [];
        return Object.assign({}, ...catDogOverrides);
    }, [animalData,
        translator,
        catDogIds,
        resolveAnimalCovTermsValue,
        resolveAnimalCovTermsSum,
        anmGetAnimalTitle,
        getAnimalCov
    ]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        dogAndCat: {
            data: animalData
        },
        ...generateDogAndCatData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default AnimalSummary;
