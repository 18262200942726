import { defineMessages } from 'react-intl';

export default defineMessages({
    emailQuote: {
        id: 'nfum.digital.wizard.emailQuote',
        defaultMessage: 'Email quote',
    },
    cancelTransaction: {
        id: 'nfum.digital.wizard.cancelTransaction',
        defaultMessage: 'Cancel transaction',
    }
});
