import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AccessListItems.metadata.json5';
import styles from './AccessListItems.module.scss';

function AccessListItems() {
    const overrideProps = {
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default AccessListItems;
