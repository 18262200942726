/* eslint-disable function-paren-newline*/
import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Prompt } from 'react-router-dom';
import _ from 'lodash';

import { PromptService } from '@jutro/router';
import { useModal } from '@jutro/components';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import { messages as commonMessages } from 'gw-platform-translations';
import { wizardStepProps } from './prop-types/wizardPropTypes';
import useWizardSteps from './hooks/useWizardSteps';
import useWizardData from './hooks/useWizardData';
import useWizardErrors from './hooks/useWizardErrors';
import { WizardContext } from './WizardContext';
import WizardRoutes from './WizardRoutes';

const checkIsDataValid = (data, extractVMObject) => {
    let submissionVM = data;
    if (!ViewModelUtil.isViewModelNode(submissionVM)) {
        submissionVM = extractVMObject(submissionVM);
    }
    return (submissionVM && submissionVM.aspects.subtreeValid && submissionVM.aspects.valid);
};

const checkDataValidityDuringTransition = (wizardData, wizardSnapshot, modalMessageProps,
    // eslint-disable-next-line max-len
    cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm) => { /* NOSONAR: GW OOTB internal mechanism */
    return checkValidity(wizardData, extractVMObject)
        ? wizardData
        : showConfirm(modalMessageProps).then(
            (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.stubFalse();
                }
                updateWizardData(cloneData(wizardSnapshot));
                return wizardSnapshot;
            }, _.stubFalse
        );
};

const checkContinue = (result) => {
    if (result === false) {
        return Promise.reject(new Error(false));
    }
    return Promise.resolve(result);
};

const handleOnPrevious = (wizardProps) => {
    const {
        wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm
    } = wizardProps;
    return checkDataValidityDuringTransition(
        wizardData,
        wizardSnapshot,
        onPreviousModalProps,
        cloneData,
        updateWizardData,
        extractVMObject,
        checkValidity,
        showConfirm
    );
};

/**
 * Use this component to render a wizard.
 * This component is the main wizard container.
 * It renders routes for the specified steps. Each step should reference a component that either
 * wraps or extends `<WizardPage>`. Each step component will receive metadata and navigation paths
 * to be used in page rendering. See `<WizardPage>` documentation for more details.
 *
 * @function Wizard
 * @memberof module:gw-portals-wizard-react
 *
 * @override
 *
 * @prop {String|Label} [wizardTitle] - the title for this wizard
 * @prop {Array.<WizardStepProps>} initialSteps -
 *      the steps that will compose this wizard
 *      (once initialized, they cannot be changed from the props)
 * @prop {Object} [initialData] -
 *      the data to pass to the wizard
 *      (once initialized, it cannot be changed from props)
 * @prop {{pathname: String, state: Object}} location - the React Router location
 * @prop {Object} history - the React Router history
 * @prop {{url: String}} match - The React Router match
 * @prop {Object} [wizardStepToFieldMapping] - Steps to match errors to the
 * specific wizard step (e.g. backend validation)
 * @prop {Boolean} [skipCompletedSteps] -
 *      whether the wizard should begin while skipping the completed steps
 *      (once initialized, this cannot be changed from the props)
 * @prop {Function} [onFinish] -
 *      Callback that will be called when the wizard is finished.
 *      This will be called with one parameter containing all the wizard data
 * @prop {Function} [onCancel] -
 *      Callback that will be called when the wizard is canceled.
 *      This will be called with one parameter containing all the wizard data
 * @prop {Function} [onPrevious] - Callback that will be called when the previous button is pushed
 * @prop {
 *      {
 *          title: Label,
 *          message: Label,
 *          status: String,
 *          icon: String,
 *          confirmButtonText: String,
 *          cancelButtonText: String
 *      }
 * } [onPreviousModalProps] - Properties to be passed to the previous modal
 * @prop {Function} [onKnockOut] -
 *      Callback that will be called when the wizard is interrupted because of a knock-out error.
 *      This will be called with one parameter containing all the wizard data
 * @prop {String} [basePath] -
 *      Where this wizard is based. If not passed the current path of the page will be used.
 *      This is particularly useful when nesting one wizard inside another
 * @prop {Function} [extractVMObject] -
 *      Function to find and extract the VM object from inside a normal object
 *      This will be passed when we the data is sent as a VM object enclosed inside
 *      a normal object (like in Policy Change)
 *      {
 *          submissionVM: initialSubmission,
 *          selectedPages: {
 *              address: false,
 *              vehicles: false,
 *              drivers: false,
 *              coverages: false
 *          }
 *      }
 * @prop {Function} [checkValidity] -
 *      Function to check the validity of the VM object when moving to the previous screen.
 *      This has a default implementation, but can also be passed to the
 *      Wizard for custom implementations
 *
 * @example
 *  const steps = [
 *      {
 *          title: 'one',
 *          path: 'step1',
 *          component: PageOne
 *      },
 *      {
 *          title: 'two',
 *          path: 'step2',
 *          component: PageTwo
 *      },
 *      {
 *          title: 'three',
 *          path: 'step3',
 *          component: PageThree
 *      }
 *  ];
 *
 *  <Wizard
 *      location={}
 *      history={}
 *      match={}
 *      basePath="/wizard/90210"
 *      initialSteps={steps}
 *  />
 *
 * @returns {ReactElement}
 */
function Wizard(props) { /* NOSONAR: pure declarative usage */
    const {
        wizardTitle,
        initialSteps,
        initialData,
        skipCompletedSteps,
        onFinish,
        onCancel,
        onPrevious,
        onPreviousModalProps,
        onKnockOut,
        wizardStepToFieldMapping,
        location,
        match,
        basePath = match.url,
        extractVMObject,
        checkValidity
    } = props;

    const {
        steps,
        currentStepIndex,
        currentStep,
        goNext,
        goPrevious,
        jumpTo,
        markStepSubmitted,
        changeNextSteps,
        isSkipping,
        stopSkipping,
        clearVisitedStepsAfterCurrent
    } = useWizardSteps(initialSteps, skipCompletedSteps);

    const { showConfirm } = useModal();

    const {
        data, updateData, snapshot, updateSnapshot, cloneData
    } = useWizardData(initialData);
    const submittedStepIds = _.filter(steps, 'submitted').map((step) => step.id);
    const {
        acknowledgeError,
        reportErrors,
        stepsWithErrors,
        hasNewErrors,
        underwritingIssues,
        knockOutErrors
    } = useWizardErrors(wizardStepToFieldMapping, submittedStepIds);

    const [toggle, flip] = useState(false);
    const [isUwIssuesInitialized, updateIsUwIssuesInitialized] = useState(false);

    useEffect(() => {
        if (!isUwIssuesInitialized) {
            // errorsAndWarnings is not set because updateWizardData
            //  is NOT called right after retrieving data from the API
            if (underwritingIssues.length === 0) {
                const errorsPath = ViewModelUtil.isViewModelNode(data)
                    ? 'errorsAndWarnings.value'
                    : 'errorsAndWarnings';
                const errorsAndWarnings = _.get(data, errorsPath);
                reportErrors(errorsAndWarnings);
            }
            updateIsUwIssuesInitialized(true);
        }
    }, [data, isUwIssuesInitialized, reportErrors, underwritingIssues.length]);

    const errorsForStep = stepsWithErrors[currentStep.id] || [];

    const updateWizardData = useCallback(
        (newData) => {
            clearVisitedStepsAfterCurrent();
            const errorsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'errorsAndWarnings.value'
                : 'errorsAndWarnings';
            const errorsAndWarnings = _.get(newData, errorsPath);
            flip(!toggle);
            updateData(newData);
            return reportErrors(errorsAndWarnings);
        },
        [toggle, updateData, clearVisitedStepsAfterCurrent, reportErrors]
    );

    const updateWizardSnapshot = useCallback(
        (newData) => {
            updateSnapshot(cloneData(newData));
            return updateWizardData(newData);
        },
        [cloneData, updateSnapshot, updateWizardData]
    );

    const finishCallback = useCallback(
        (params) => onFinish({
            steps,
            currentStepIndex,
            wizardData: data,
            params
        }),
        [steps, currentStepIndex, data, onFinish]
    );

    const handleError = (error) => { // nfum custom
        if (_.get(error, 'message') === 'false') {
            // do nothing as we don't want to proceed
            return;
        }
        throw error;
    };

    const previousCallback = useCallback(
        (param) => {
            return Promise.resolve(
                onPrevious({
                    wizardSnapshot: snapshot,
                    wizardData: data,
                    onPreviousModalProps,
                    param,
                    cloneData,
                    updateWizardData,
                    extractVMObject,
                    checkValidity,
                    showConfirm
                })
            )
                .then(checkContinue)
                .then(goPrevious)
                .catch(handleError); // nfum custom
        },
        [onPrevious, snapshot, data, onPreviousModalProps, cloneData,
            updateWizardData, extractVMObject, checkValidity, showConfirm, goPrevious]
    );

    const onPageJump = useCallback(
        ({
            wizardData, wizardSnapshot, modalMessages, index
        }) => {
            const pageTransitionPromise = Promise.resolve(
                checkDataValidityDuringTransition(
                    wizardData,
                    wizardSnapshot,
                    modalMessages,
                    cloneData,
                    updateWizardData,
                    extractVMObject,
                    checkValidity,
                    showConfirm
                )
            );

            pageTransitionPromise
                .then((result) => {
                    if (result !== false) {
                        jumpTo(index);
                    }
                })
                .catch(handleError); // nfum custom
        },
        [checkValidity, cloneData, extractVMObject, jumpTo, showConfirm, updateWizardData]
    );

    const cancelCallback = useCallback(
        (param) => onCancel({
            steps,
            currentStepIndex,
            wizardSnapshot: snapshot,
            wizardData: data,
            param
        }),
        [onCancel, steps, currentStepIndex, snapshot, data]
    );

    const handlePromptMessage = useCallback(
        ({ pathname }) => {
            const isWizardPath = pathname.startsWith(basePath);

            // if triggered by the wizard, we ignore it
            if (isWizardPath) {
                return null;
            }

            const actionName = 'onCancel';

            // register the onCancel action with Jutro
            PromptService.pushPrompt(actionName, { trigger: cancelCallback.bind(null, pathname) });

            return actionName;
        },
        [basePath, cancelCallback]
    );

    if (hasNewErrors) {
        const errorStepIds = Object.keys(stepsWithErrors);
        const stepIndex = _.findIndex(steps, (step) => errorStepIds.includes(step.id));
        if (stepIndex !== -1 && stepIndex < currentStepIndex) {
            jumpTo(stepIndex);
        }
    }

    if (!_.isEmpty(knockOutErrors)) {
        onKnockOut({
            steps,
            knockOutErrors,
            wizardData: data
        });
    }

    /*
     * current step has authority over the URL.
     *
     * If current step is not matching the URL,
     * we adjust by sending a redirect to the step URL
     */
    const expectedPath = `${basePath}${currentStep.path}`;
    if (!location.pathname.startsWith(expectedPath)) {
        const nextLocation = {
            pathname: expectedPath,
            state: location.state
        };
        return <Redirect to={nextLocation} />;
    }

    const wizardProps = {
        // wizard details
        wizardTitle,
        // steps props
        steps,
        changeNextSteps,
        currentStepIndex,
        currentStep,
        // transitions props
        isSkipping,
        stopSkipping,
        goNext,
        goPrevious: previousCallback,
        markStepSubmitted,
        jumpTo,
        finish: finishCallback,
        cancel: cancelCallback,
        onPageJump,
        // wizard data props
        wizardData: data,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot: snapshot,
        // errors props
        hasNewErrors,
        errorsForStep,
        stepsWithErrors,
        underwritingIssues,
        acknowledgeError,
        reportErrors,
        extractVMObject,
        checkValidity,
        showConfirm
    };

    // we pass the props to both the component
    // and the context to allow nested components to reuse them
    // without prop-drilling
    return (
        <WizardContext.Provider value={wizardProps}>
            <Prompt message={handlePromptMessage} />
            <WizardRoutes basePath={basePath} {...wizardProps} />
        </WizardContext.Provider>
    );
}

Wizard.propTypes = {
    /** the title for this wizard */
    wizardTitle: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        PropTypes.string
    ]),
    /**
     * the steps that will compose this wizard
     * (once initialized, they cannot be changed from the props)
     */
    initialSteps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired,
    /**
     * the data to pass to the wizard
     * (once initialized, it cannot be changed from props)
     */
    initialData: PropTypes.shape({}),
    /**
     * the React Router location
     */
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.shape({})
    }).isRequired,
    /**
     * the React Router history
     */
    history: PropTypes.shape({}).isRequired,
    /**
     * The React Router match
     */
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    /**
     * Steps to match errors to the specific wizard step (e.g. backend validation)
     */
    wizardStepToFieldMapping: PropTypes.shape({}),
    /**
     * whether the wizard should begin while skipping the completed steps
     * (once initialized, this cannot be changed from the props)
     */
    skipCompletedSteps: PropTypes.bool,
    /**
     * Callback that will be called when the wizard is finished.
     * This will be called with one parameter containing all the wizard data
     */
    onFinish: PropTypes.func,
    /**
     * Callback that will be called when the wizard is canceled.
     * This will be called with one parameter containing all the wizard data
     */
    onCancel: PropTypes.func,
    onPrevious: PropTypes.func,
    onPreviousModalProps: PropTypes.shape({
        title: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        message: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        status: PropTypes.string,
        icon: PropTypes.string,
        confirmButtonText: PropTypes.shape({}),
        cancelButtonText: PropTypes.shape({})
    }),
    /**
     * Callback that will be called when the wizard is interrupted because of
     * a knock-out error.
     * This will be called with one parameter containing all the wizard data
     */
    onKnockOut: PropTypes.func,
    /**
     * Where this wizard is based
     * If not passed the current path of the page will be used.
     * This is particularly useful when nesting one wizard inside another
     */
    basePath: PropTypes.string,
    /**
     * Function to find and extract the VM object from inside a normal object
     * This will be passed when we the data is sent as a VM object enclosed
     * inside a normal object (like in Policy Change)
     *  {
     *      submissionVM: initialSubmission,
     *      selectedPages: {
     *          address: false,
     *          vehicles: false,
     *          drivers: false,
     *          coverages: false
     *      }
     *  }
     */
    extractVMObject: PropTypes.func,
    /**
     * Function to check the validity of the VM object when moving to the previous screen
     * This has a default implementation, but can also be passed to
     * the Wizard for custom implementations
     */
    checkValidity: PropTypes.func
};

Wizard.defaultProps = {
    wizardTitle: null,
    initialData: null,
    skipCompletedSteps: false,
    onCancel: _.noop,
    onPrevious: handleOnPrevious,
    onPreviousModalProps: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'gw-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    onFinish: _.noop,
    wizardStepToFieldMapping: {},
    basePath: undefined,
    onKnockOut: _.noop,
    extractVMObject: _.noop,
    checkValidity: checkIsDataValid
};

export default withRouter(Wizard);
