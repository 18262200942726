import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.no',
        defaultMessage: 'No'
    },
    insideHome: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.insideHome',
        defaultMessage: 'Inside the home'
    },
    insideOutsideHome: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)'
    },
    keptInBank: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.keptInBank',
        defaultMessage: 'Kept in bank?'
    },
    coverType: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.coverType',
        defaultMessage: 'Kept in bank?'
    },
    replacementCost: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    category: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.category',
        defaultMessage: 'Category'
    },
    valuables: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.valuables',
        defaultMessage: 'Valuables'
    },
    notSelected: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.notSelected',
        defaultMessage: 'Not Selected'
    },
    specifiedValuable: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.specifiedValuable',
        defaultMessage: 'Specified valuables'
    },
    unSpecifiedValuable: {
        id: 'quoteandbind.bespoke.views.policy-summary.valuables.unSpecifiedValuable',
        defaultMessage: 'Unspecified valuables'
    },
    jewelleryReplacementCost: {
        id: 'endorsement.ngh.views.bespokeValuables.jewelleryReplacementCost',
        defaultMessage: 'Jewellery, gemstones and watches'
    },
    fursReplacementCost: {
        id: 'endorsement.ngh.views.bespokeValuables.fursReplacementCost',
        defaultMessage: 'Furs'
    },
    gunsReplacementCost: {
        id: 'endorsement.ngh.views.bespokeValuables.gunsReplacementCost',
        defaultMessage: 'Guns'
    },
    type: {
        id: 'endorsement.ngh.views.bespokeValuables.type',
        defaultMessage: 'Type'
    },
});
