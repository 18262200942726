/* eslint-disable */
import React from 'react';
import { Grid } from '@jutro/layout';
import { RadioButtonField } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';

function NfumRadioButtonField(props) {

    const hasTwoOptions = props.availableValues.length === 2;
    const className = classNames({ ["yesNoRadioButton"]: hasTwoOptions })
    return (
        <div>
            <NfumFieldLabel
                {...props}
            />
            <div>
                <Grid
                    hgap="none"
                    className={className}
                >
                    <RadioButtonField
                        {...props}
                        hideLabel="true"
                    />
                </Grid>
            </div>
        </div>
    );
}

export default NfumRadioButtonField;
