import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import useTagManager from '../../hooks/useTagManager';
import metadata from './SystemDownMessage.metadata.json5';
import styles from './SystemDownMessage.module.scss';
import messages from './SystemDownMessage.messages';

function SystemDownMessage({ systemErrorMessage }) {
    const { pushLinkClickInfo } = useTagManager();
    const translator = useContext(TranslatorContext);

    const onRequestCallback = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/contact-us/call-back/';
        pushLinkClickInfo(translator(messages.callback), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        problemGeneratingQuoteText: {
            content: systemErrorMessage,
        },
        arrangeCallbackButton: {
            onClick: onRequestCallback
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default SystemDownMessage;
