/* eslint-disable jsx-a11y/anchor-is-valid */
import { TranslatorContext } from '@jutro/locale';
import React, { useContext } from 'react';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { Link } from '@jutro/components';
import styles from './Footer.module.scss';
import messages from './Footer.messages';

const urls = {
    privacyPolicy: 'https://www.nfumutual.co.uk/legal-information/privacy-policy/',
    legalPolicy: 'https://www.nfumutual.co.uk/legal-information/legal-policy/',
    cookiePolicy: 'https://www.nfumutual.co.uk/legal-information/cookie-policy/',
    termsAndConditions: 'https://www.nfumutual.co.uk/legal-information/our-terms-and-conditions/',
    slaveryEnslavement: 'https://www.nfumutual.co.uk/legal-information/slavery-and-human-trafficking-statement/',
    islandsInsurance: 'https://www.islands.insure/',
    withProfits: 'https://www.nfumutual.co.uk/investments/with-profits-funds/',
    accesibility: 'https://www.nfumutual.co.uk/legal-information/accessibility/',
    complaints: 'https://www.nfumutual.co.uk/complaints/',
    contactUs: 'https://www.nfumutual.co.uk/contact-us/',
    mediaCenter: 'https://www.nfumutual.co.uk/media-centre/',
    careers: 'https://www.nfumutual.co.uk/careers/',
};

const Footer = () => {
    const translator = useContext(TranslatorContext);

    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const onFooterLinkClicked = (name) => {
        const url = urls[name];
        pushLinkClickInfo(name, url);
        window.open(url, '_blank');
    };
    const onLinkClicked = () => {
        const url = 'https://www.nfumutual.co.uk/insurance/home-insurance/';
        pushLinkClickInfo(translator(messages.nfum), url);
        window.open(url, '_blank');
    };



    return (
        <div className={styles.footerWrapper}>
            <footer className={styles.footer}>
                <div className={styles.footerInfoWrapper}>
                    <div className={styles.footerInfo}>
                        <div className={styles.footerInfoGrid}>
                            <div className={styles.footerInfoMessage} role="paragraph">
                                <strong>{ translator(messages.nfum) }</strong>
                                { translator(messages.footerInfoContent) }
                            </div>
                            <div className={styles.footerInfoLinks}>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('privacyPolicy')} role="link" aria-label="Privacy Policy">Privacy Policy</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('legalPolicy')} role="link" aria-label="Legal Policy">Legal Policy</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('cookiePolicy')} role="link" aria-label="Cookie Policy">Cookie Policy</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('termsAndConditions')} role="link" aria-label="Our terms and condidtions">Our terms and conditions</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('slaveryEnslavement')} role="link" aria-label="Modern slavery statement">Modern slavery statement</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('islandsInsurance')} role="link" aria-label="Islands Insurance">Islands Insurance</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('withProfits')} role="link" aria-label="With-Profits">With-Profits</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('accesibility')} role="link" aria-label="Accessibility">Accessibility</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('complaints')} role="link" aria-label="Complaints">Complaints</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('contactUs')} role="link" aria-label="Contact Us">Contact us</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('mediaCenter')} role="link" aria-label="Media Centre">Media Centre</Link></div>
                                <div className={styles.link}><Link onClick={() => onFooterLinkClicked('careers')} role="link" aria-label="Careers">Careers</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerBarWrapper}>
                    <div className={styles.footerBar}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={() => onLinkClicked()} target="_blank" rel="noreferrer" role="link" aria-label="NFUM logo" className={styles.footerBarImageAnchor}>
                            <div className={styles.footerBarImage} alt="NFU Mutual Logo" />
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};

Footer.defaultProps = {
};
Footer.propTypes = {
};
export default Footer;
