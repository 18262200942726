import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteDetails: {
        id: 'quoteandbind.components.saveNewQuote.quoteDetails',
        defaultMessage: 'Quote details',
    },
    chooseNewStartDate: {
        id: 'quoteandbind.components.saveNewQuote.chooseNewStartDate',
        defaultMessage: 'Please choose a new start date',
    },
    newPolicyStartDateText: {
        id: 'quoteandbind.components.saveNewQuote.newPolicyStartDateText',
        defaultMessage: 'The cover start date can not be more than 30 days in the future from the current date. Please choose a new start day below to retrieve your quote.',
    },
    effectiveDate: {
        id: 'quoteandbind.components.saveNewQuote.effectiveDate',
        defaultMessage: 'When would you like the cover/policy to start?',
    },
    effectiveDateTooltip: {
        id: 'quoteandbind.components.saveNewQuote.effectiveDateTooltip',
        defaultMessage: 'Effective Date can not be a date in the past or more than 30 days in the future from current date',
    },
    startNewQuote: {
        id: 'quoteandbind.components.saveNewQuote.startNewQuote',
        defaultMessage: 'Start a new quote',
    },
    returnToDashboard: {
        id: 'quoteandbind.components.saveNewQuote.returnToDashboard',
        defaultMessage: 'Return to dashboard',
    },
    retrieveQuote: {
        id: 'quoteandbind.components.saveNewQuote.retrieveQuote',
        defaultMessage: 'Retrieve quote',
    }
});
