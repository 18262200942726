import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './OnlineAccountStatusCanRegister.metadata.json5';
import styles from './OnlineAccountStatusCanRegister.module.scss';
import AccessListItems from '../AccessListItems/AccessListItems';

function OnlineAccountStatusCanRegister() {
    const overrideProps = {
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            accessListItems: AccessListItems
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default OnlineAccountStatusCanRegister;
