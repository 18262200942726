import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './SessionTimeOut.module.scss';
import metadata from './SessionTimeOut.metadata.json5';

const SessionTimeOut = () => {
    const refreshPage = () => {
        const baseUrl = window.location.origin;
        window.location.href = baseUrl;
    };
    const overrideProps = {
        refreshLink: {
            onClick: refreshPage
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            className={styles.modalWrapper}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

SessionTimeOut.propTypes = {
};
SessionTimeOut.defaultProps = {
};

export default SessionTimeOut;
