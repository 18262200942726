import React, { useCallback, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import useCurrency from '../../../nfum-capability-quoteandbind-ngh-react/hooks/useCurrency';
import metadata from './FineArtsCollectionsItem.metadata.json5';
import messages from './FineArtsCollectionsItem.messages';
import styles from './FineArtsCollectionsItem.module.scss';

function FineArtsCollectionsItem(props) {
    const {
        fineArtCollections
    } = props;
    const translator = useContext(TranslatorContext);
    const currencyFormatter = useCurrency();

    const category = Object.freeze({
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });

    const types = Object.freeze({
        CIGERETTE_CARDS: 'CigaretteCards',
        BANK_NOTES: 'BankNotes',
        VINTAGE_FOUNTAIN_PENS: 'VintageFountainPens',
        POLICE_IN_SIGNIA: 'PoliceInsignia',
        TIN_SOLDIERS: 'TinSoldiers'
    });

    const getHeaderDescription = useCallback((itemType) => {
        let description = '';
        if (itemType === category.WINES) {
            description = translator(messages.wineCollection);
        } else if (itemType === category.STAMPS_MEDALS_COINS) {
            description = translator(messages.stampMedalCoinsCollection);
        } else {
            description = translator(messages.otherCollection);
        }
        return description;
    }, [category, translator]);

    const renderCellContent = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        const { collectionType } = data;
        if (path === 'collectionType') {
            let type = _.get(data, path);
            if (type === undefined) {
                type = translator(messages.wineCollection);
            }
            if (collectionType === types.CIGERETTE_CARDS) {
                type = translator(messages.cigaretteCards);
            } else if (collectionType === types.BANK_NOTES) {
                type = translator(messages.bankNotes);
            } else if (collectionType === types.VINTAGE_FOUNTAIN_PENS) {
                type = translator(messages.vintageFountainPens);
            } else if (collectionType === types.POLICE_IN_SIGNIA) {
                type = translator(messages.policeInsignia);
            } else if (collectionType === types.TIN_SOLDIERS) {
                type = translator(messages.tinSoldiers);
            }
            return type;
        }
        return currencyFormatter.formatCurrency(`${_.get(data, path)}`, true);
    }, [types, currencyFormatter, translator]);

    const overrideProps = {
        category: {
            content: getHeaderDescription(fineArtCollections.name)
        },
        collectionList: {
            data: fineArtCollections.valuables,
            columnsProportion: [2, 2, 1]
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRenderCell: renderCellContent
        }
    };

    return (
        <ViewModelForm
            model={fineArtCollections}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

FineArtsCollectionsItem.propTypes = wizardProps;
export default FineArtsCollectionsItem;
