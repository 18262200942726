import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PaymentPremiumInfoBox.metadata.json5';
import styles from './PaymentPremiumInfoBox.module.scss';

function PaymentPremiumInfoBox(props) {
    const {
        titleText,
        increaseDecreaseText,
        costForRestMonthsText,
        isPerYear,
        premiumText,
        topText,
        extraText
    } = props;

    const overrideProps = {
        topText: {
            visible: topText !== null,
            content: topText
        },
        title: {
            content: titleText
        },
        increaseDecreaseMessageContainer: {
            visible: increaseDecreaseText !== null,
        },
        increaseDecreaseText: {
            content: increaseDecreaseText
        },
        costForRestMonthsContainer: {
            visible: costForRestMonthsText !== null
        },
        costForRestMonthsText: {
            content: costForRestMonthsText
        },
        costPerMonth: {
            visible: false
        },
        perMonthAsterisk: {
            visible: isPerYear === false
        },
        perYearAsterisk: {
            visible: isPerYear === true
        },
        perMonthDot: {
            visible: isPerYear === false
        },
        perYearDot: {
            visible: isPerYear === true
        },
        amount: {
            content: premiumText
        },
        extraTextContainer: {
            visible: extraText !== null
        },
        extraText: {
            content: extraText
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PaymentPremiumInfoBox.propTypes = {
    isPerYear: PropTypes.bool,
    topText: PropTypes.string,
    costForRestMonthsText: PropTypes.string,
    increaseDecreaseText: PropTypes.string,
    extraText: PropTypes.string
};
PaymentPremiumInfoBox.defaultProps = {
    isPerYear: null,
    topText: null,
    costForRestMonthsText: null,
    increaseDecreaseText: null,
    extraText: null
};

export default PaymentPremiumInfoBox;
