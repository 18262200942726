import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { TranslatorContext } from '@jutro/locale';
import { MarketingSidebar } from 'nfum-portals-wizard-react';
import {
    useErrorHandler,
    QUOTE_RETRIEVAL_STEPS,
    AppContext,
    VALIDATION_ERROR_CODE
} from 'nfum-portals-utils-react';
import { useTagManager } from 'nfum-capability-quoteandbind-ngh-react';
import { useQuoteCms } from 'nfum-cms-react';
import styles from './RetrieveQuotePage.module.scss';
import metadata from './RetrieveQuotePage.metadata.json5';
import messages from './RetrieveQuotePage.messages';

const RetrieveQuotePage = (props) => {
    const history = useHistory();
    const translator = useContext(TranslatorContext);
    const {
        match: { params: { quoteNumber } }
    } = props;
    const { handleError, getErrorCode } = useErrorHandler();
    const modes = Object.freeze({
        RETRIEVE: 'retrieve',
        UPDATE_DATE: 'updateDate'
    });
    const [mode, setMode] = useState(modes.RETRIEVE);
    const [retrievedSubmission, setRetrievedSubmission] = useState();
    const { pushFormStepInfo } = useTagManager();
    const {
        setIsQuoteRetrieval,
        setMarketingInfo,
        marketingInfo,
        setIsRetrievedAsQuoted
    } = useContext(AppContext);
    const RETRIEVE_QUOTE_PAGE_ID = 'retrieve-quote';
    setIsQuoteRetrieval(true);
    const { getCmsContentForQuoteJourney } = useQuoteCms(marketingInfo, setMarketingInfo);
    const productCodes = Object.freeze({
        BESPOKE: 'bespoke',
        NGH: 'ngh',
        COMMON: 'common'
    });

    useEffect(() => { // nfum custom
        getCmsContentForQuoteJourney(productCodes.COMMON);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        pushFormStepInfo(null, QUOTE_RETRIEVAL_STEPS.RETRIEVE_YOUR_QUOTE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRedirectionLink = (submission) => {
        const { quoteID } = submission;
        const NGH_QUOTED_WIZARD_PATH = `/quote/${quoteID}/quoted`;
        const NGH_WIZARD_PATH = '/quote-ngh';

        if (submission.baseData.periodStatus === 'Quoted') {
            setIsRetrievedAsQuoted(true);
            return NGH_QUOTED_WIZARD_PATH;
        }

        setIsRetrievedAsQuoted(false);
        return NGH_WIZARD_PATH;
    };

    const validatePeriodStartDateAndRedirect = useCallback((submission) => {
        setRetrievedSubmission(submission);
        const redirectionLink = getRedirectionLink(submission);
        const { periodStartDate, minDate_NFUM: minDate } = submission.baseData;
        const dateConversion = (date) => new Date(date.year, date.month, date.day);
        const minDateValidation = dateConversion(periodStartDate)
            >= dateConversion(minDate);
        if (submission.baseData.periodStatus === 'Quoted' && !minDateValidation) {
            setMode(modes.UPDATE_DATE);
        } else {
            history.push(redirectionLink, { submission });
        }
    }, [history, modes]);

    const retrieveQuote = useCallback(async (retrievalDataVM) => {
        return LoadSaveService.retrieveSubmission(retrievalDataVM.value)
            .then((submission) => {
                validatePeriodStartDateAndRedirect(submission);
            })
            .catch((err) => {
                if (getErrorCode(err) === VALIDATION_ERROR_CODE) {
                    throw new Error('quote not found');
                } else {
                    handleError(err);
                }
            });
    }, [
        validatePeriodStartDateAndRedirect,
        handleError,
        getErrorCode
    ]);

    const updateSubmission = useCallback(async (updatedSubmissionData) => {
        const newSubmissionToSave = {
            ...retrievedSubmission,
            baseData: {
                ...retrievedSubmission.baseData,
                periodStartDate: updatedSubmissionData.periodStartDate,
            }
        };
        LoadSaveService.updateQuotedSubmission(newSubmissionToSave)
            .then((submission) => {
                validatePeriodStartDateAndRedirect(submission);
            })
            .catch((err) => {
                handleError(err);
            });
    }, [
        validatePeriodStartDateAndRedirect,
        handleError,
        retrievedSubmission
    ]);

    const overrideProps = {
        nghRetrieveQuoteContainer: {
            visible: mode === modes.RETRIEVE
        },
        marketingSidebar: {
            visible: mode === modes.RETRIEVE,
            pageId: RETRIEVE_QUOTE_PAGE_ID
        },
        nghRetrieveQuote: {
            quoteID: quoteNumber,
            onRetrieveQuote: retrieveQuote
        },
        nghSaveNewQuoteContainer: {
            visible: mode === modes.UPDATE_DATE
        },
        nghSaveNewQuote: {
            onSaveNewQuote: updateSubmission,
            submission: retrievedSubmission
        },
        quoteReference: {
            content: quoteNumber ? `${translator(messages.changeQuoteNumber)}: ${quoteNumber}` : ''
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            marketingSidebar: MarketingSidebar
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

export default RetrieveQuotePage;
