import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
    useMemo
} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides, MetadataContent } from '@jutro/uiconfig';
import { Main, useModal, ModalNextEmitter } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { routeConfirmationModal } from '@jutro/router';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import vmTranslator, { messages as platformMessages } from 'gw-platform-translations';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { brandingData, setBranding } from 'gw-portals-branding-js';
import { RoutingTracking } from 'gw-portals-tracking-react';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import 'gw-capability-quoteandbind-react';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';
import {
    segmentationMap,
    segmentationComponentMap,
    Segmentation,
    SegmentationOption
} from 'gw-portals-segmentation-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent,
} from 'gw-components-platform-react';
import {
    platformComponents as customPlatformComponents,
    platformComponentMap as customPlatformComponentMap
} from 'nfum-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { GooglePlacesLookupComponentMap, GooglePlacesLookupComponents } from 'gw-capability-googleplaces-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { LoadSaveService, CustomQuoteService } from 'gw-capability-quoteandbind';
import { PolicyService } from 'nfum-capability-policy';
import { PropertyCodeLookupService } from 'gw-capability-propertycode';
import { BopCoverablesService } from 'gw-capability-policyjob-bop';
import {
    NGHWizard,
    NGHQuotedWizard
} from 'nfum-capability-quoteandbind-ngh-react';
import {
    useQuoteCms
} from 'nfum-cms-react';
import { AppContext, useErrorHandler } from 'nfum-portals-utils-react';
import CustomLandingPage from '../pages/CustomLandingPage/CustomLandingPage';
import ServiceUnavailablePage from '../pages/ServiceUnavailablePage/ServiceUnavailablePage';
import RetrieveQuotePage from '../pages/RetrieveQuotePage/RetrieveQuotePage';
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';
import SecurityCheckPage from '../pages/SecurityCheckPage/SecurityCheckPage';
import metadata from './App.metadata.json5';
import Footer from '../components/Footer/Footer';
import CallUs from '../components/CallUs/CallUs';
import QuoteLoadingView from '../components/QuoteLoadingView/QuoteLoadingView';
import InactiveModal from '../components/InactiveModal/InactiveModal';
import './App.messages';

const MONITORED_EVENTS = ['click', 'mousemove', 'scroll', 'keydown'];

setComponentMapOverrides({
    ...platformComponentMap,
    ...customPlatformComponentMap,
    ...AddressLookupComponentMap,
    ...VehicleInfoLookupComponentMap,
    ...GooglePlacesLookupComponentMap,
    ...segmentationMap,
    ...policyJobComponentMap,
    // replace the native IMG component with a proxied version
    img: { component: 'img' },
    footerWithBottomBar: { component: 'footerWithBottomBar' }
}, {
    ...platformComponents,
    ...customPlatformComponents,
    ...AddressLookupComponents,
    ...VehicleInfoLookupComponents,
    ...GooglePlacesLookupComponents,
    ...segmentationComponentMap,
    ...policyJobComponents,
    img: ImageComponent,
    footerWithBottomBar: Footer
});

const modalEmitter = new ModalNextEmitter();

export default function App() {
    const {
        showModal
    } = useModal();

    const localeService = ServiceManager.getService('locale-service');
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);

    /* nfum custom */
    const isPhone = breakpoint === 'phone';
    const [isQuoteLoadingView, setIsQuoteLoadingView] = useState(false);
    const [isMarketingQuoteLoadingView, setIsMarketingQuoteLoadingView] = useState(false);
    const { handleError: customHandleError } = useErrorHandler();
    const [isQuoteRetrieval, setIsQuoteRetrieval] = useState(true);
    const [isRetrievedAsQuoted, setIsRetrievedAsQuoted] = useState(undefined);
    const [marketingInfo, setMarketingInfo] = useState({});
    const { getCmsContentForQuoteJourney } = useQuoteCms(marketingInfo, setMarketingInfo);
    const [isUserActive, setIsUserActive] = useState(true);
    const productCodes = Object.freeze({
        BESPOKE: 'bespoke',
        NGH: 'ngh',
        COMMON: 'common'
    });

    const CHOICE_CONTINUE = 'choice-continue';
    const CHOICE_TIMEOUT = 'choice-timeout';
    const {
        inactivityIntervalMins,
        logoutConfirmationIntervalMins
    } = appConfig.sessionConfig;

    const [userInactive, setUserInactive] = useState(false);

    const onActivityChange = useCallback(({ isActive }) => {
        setIsUserActive(isActive);
    }, []);

    useEffect(() => {
        const isSessionInValid = window.localStorage.getItem('sessionTimeOut') === 'true';
        if (!isUserActive && !isSessionInValid) {
            setUserInactive(true);
        }
    }, [isUserActive]);

    const tokenDuration = useMemo(() => {
        return inactivityIntervalMins * 60;
    }, [inactivityIntervalMins]);

    const sessionTimeOut = useCallback(() => {
        window.localStorage.setItem('sessionTimeOut', true);
        window.location.replace('quote-ngh/prequalification');
    }, []);

    useEffect(() => {
        if (userInactive) {
            showModal(
                <InactiveModal
                    isOpen
                    logoutConfirmationInterval={logoutConfirmationIntervalMins}
                />
            ).then((choice) => {
                if (choice.type === CHOICE_CONTINUE) {
                    setUserInactive(false);
                } else if (choice.type === CHOICE_TIMEOUT) {
                    sessionTimeOut();
                }
            }, _.noop);
        }
    }, [logoutConfirmationIntervalMins,
        userInactive,
        sessionTimeOut,
        showModal
    ]);
    /* end: nfum custom */

    const togglePhoneMenu = useCallback(() => {
        setShowPhoneMenu(!showPhoneMenu);
    }, [showPhoneMenu]);

    const shouldTogglePhoneMenu = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            togglePhoneMenu();
        }
    }, [phoneMenuRef, togglePhoneMenu]);

    useEffect(() => { // nfum custom
        getCmsContentForQuoteJourney(productCodes.COMMON);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickOnPhoneNumber = useCallback((event) => {
        event.preventDefault();
        window.location = `tel:${appConfig.phoneNumber}`;
    }, []);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(
                    result,
                    translatorFn
                )
            );
        });

        if (brandingData.BRANDING) {
            setBranding();
        }

        if (breakpoint === 'phone') {
            // a listener so we to the phone menu so we can
            // close the menu if there's a click outside the menu
            document.addEventListener('mousedown', shouldTogglePhoneMenu, false);
            // If a function is returned from a useEffect,
            // it will be used to clean up when component is unmounted
            return () => document.removeEventListener('mousedown', shouldTogglePhoneMenu, false);
        }
    }, [breakpoint, shouldTogglePhoneMenu, togglePhoneMenu, translator]);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    const metadataToRender = useMemo(() => {
        if (breakpoint === 'phone') {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneHeader');
        }
        return metadata.pageContent.content.filter((item) => item.id === 'desktopHeader');
    }, [breakpoint]);

    const overrideProps = {
        callUsMobile: { // nfum custom
            visible: isPhone
        },
        callUsDesktop: { // nfum custom
            visible: !isPhone
        },
        globalizationChooserField: {
            onLanguageValueChange: localeService.saveLanguage,
            languageValue: localeService.getStoredLanguage()
        },
        phoneMenu: {
            ref: getMenuRef,
            className: classNames('gw-phone-header', {
                'gw-phone-header-open': showPhoneMenu
            })
        },
        mobilePhoneNumber: {
            content: appConfig.phoneNumber
        },
        headerLinkItems: { // nfum custom
            visible: false
        },
        phoneHeaderGridItem2: { // nfum custom
            visible: false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            togglePhoneMenu: togglePhoneMenu,
            clickOnPhoneNumber: clickOnPhoneNumber
        },
        resolveComponentMap: { // nfum custom
            callUs: CallUs
        }
    };

    const footerMetadata = metadata.pageContent.content.filter((item) => item.id === 'footer');
    const mainContentClassNames = classNames('qnbMain', { // nfum custom
        hidden: isQuoteLoadingView || isMarketingQuoteLoadingView
    });
    const loadingViewClassNames = classNames('loadingViewContainer', { // nfum custom
        hidden: !isQuoteLoadingView && !isMarketingQuoteLoadingView
    });

    // nfum custom
    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                PolicyService,
                LoadSaveService,
                CustomQuoteService,
                PropertyCodeLookupService,
                BopCoverablesService
            }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <AppContext.Provider
                        value={{
                            isQuoteLoadingView,
                            setIsQuoteLoadingView,
                            isMarketingQuoteLoadingView,
                            setIsMarketingQuoteLoadingView,
                            marketingInfo,
                            setMarketingInfo,
                            isQuoteRetrieval,
                            setIsQuoteRetrieval,
                            isRetrievedAsQuoted,
                            setIsRetrievedAsQuoted
                        }}
                    >
                        <Segmentation type="query-params">
                            <SegmentationOption defaultOption>
                                <Router
                                    basename="/quote-and-buy"
                                    getUserConfirmation={
                                        (message, callback) => routeConfirmationModal(
                                            message,
                                            callback,
                                            modalEmitter
                                        )
                                    }
                                >
                                    <div className={loadingViewClassNames}>
                                        <QuoteLoadingView
                                            isMarketingInfo={isMarketingQuoteLoadingView}
                                        />
                                    </div>
                                    <Main className={mainContentClassNames} contentClassName="qnbMainContent" fluid>
                                        <MetadataContent
                                            uiProps={metadataToRender}
                                            overrideProps={overrideProps}
                                            {...resolvers}
                                        />
                                        <RoutingTracking />
                                        <div className="qnbContent">
                                            <ErrorBoundary onError={customHandleError}>
                                                <Switch>
                                                    <Route path="/security-check" component={SecurityCheckPage} />
                                                    <Route path="/contact-us" component={ContactUsPage} />
                                                    <Route path="/service-unavailable" component={ServiceUnavailablePage} />
                                                    <Route exact path="/" component={RetrieveQuotePage} />
                                                    <Route exact path="/quote/:quoteNumber" component={RetrieveQuotePage} />
                                                    <Route path="/quote-ngh" component={NGHWizard} />
                                                    <Route path="/quote/:quoteNumber/quoted" component={NGHQuotedWizard} />
                                                    <Route render={() => <Redirect to="/" />} />
                                                </Switch>
                                            </ErrorBoundary>
                                        </div>
                                        <MetadataContent uiProps={footerMetadata} />
                                    </Main>
                                </Router>
                            </SegmentationOption>
                            <SegmentationOption is={[{ LP: '1' }, { LP: '2' }]}>
                                <CustomLandingPage />
                            </SegmentationOption>
                        </Segmentation>
                        <InactivityTracker
                            onActivityChange={onActivityChange}
                            inactivityAllowedSecs={tokenDuration}
                        />
                    </AppContext.Provider>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

/* nfum custom */
function useTracking({ events, trackingFunction }) {
    const eventsArr = _.castArray(events);

    useEffect(() => {
        eventsArr.forEach((event) => {
            document.addEventListener(event, trackingFunction);
        });
        return () => {
            eventsArr.forEach((event) => {
                document.removeEventListener(event, trackingFunction);
            });
        };
    }, [trackingFunction, eventsArr]);
}

function InactivityTracker({ onActivityChange, inactivityAllowedSecs }) {
    const [lastInteraction, setLastInteraction] = useState(Date.now());
    const [isActive, setIsActive] = useState(true);

    const trackInteraction = useCallback(_.throttle(() => {
        setLastInteraction(Date.now());
    }, 500), []);

    useTracking({
        events: MONITORED_EVENTS,
        trackingFunction: trackInteraction,
    });

    useEffect(() => {
        onActivityChange({ isActive });
    }, [isActive, onActivityChange]);

    const setUserInactive = useCallback(() => {
        setIsActive(false);
    }, []);

    useEffect(() => {
        setIsActive(true);
        // every time we have an interaction we register the user is active
    }, [lastInteraction]);

    useEffect(() => {
        const userActivityTimeout = setTimeout(setUserInactive, inactivityAllowedSecs * 1000);
        return () => {
            clearTimeout(userActivityTimeout);
        };
    }, [
        inactivityAllowedSecs,
        // every time we have an interaction we register the user is active
        lastInteraction,
        setUserInactive
    ]);

    return null;
}

InactivityTracker.defaultProps = {
    inactivityAllowedSecs: 60 * 60 // 1 hour
};
/* end: nfum custom */
