import React, { useCallback } from 'react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PaymentUnsuccessfulPage.metadata.json5';
import messages from './PaymentUnsuccessfulPage.messages';
import SectionComponent from '../../components/SectionComponent/SectionComponent';

const PaymentUnsuccessfulPage = (props) => {
    const { wizardData: submissionVM, jumpTo, steps } = props;

    const onPrevious = useCallback(() => {
        const index = steps.findIndex((step) => step.id === 'PaymentPage');
        jumpTo(index);
    }, [steps, jumpTo]);

    const resolvers = {
        resolveComponentMap: {
            sectionComponent: SectionComponent,
        }
    };

    return (
        <WizardPage
            showNext={false}
            showCancel
            showPrevious
            onPrevious={onPrevious}
            previousLabel={messages.update}
            cancelLabel={messages.cancel}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                componentMap={resolvers.resolveComponentMap}
                model={submissionVM}
            />
        </WizardPage>
    );
};

PaymentUnsuccessfulPage.propTypes = wizardProps;
export default PaymentUnsuccessfulPage;
