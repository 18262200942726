import { defineMessages } from 'react-intl';

export default defineMessages({
    startNewQuote: {
        id: 'quoteandbind.components.retrieveQuote.startNewQuote',
        defaultMessage: 'Start a new quote'
    },
    returnToDashboard: {
        id: 'quoteandbind.components.retrieveQuote.returnToDashboard',
        defaultMessage: 'Return to dashboard'
    },
    retrieveQuote: {
        id: 'quoteandbind.components.retrieveQuote.retrieveQuote',
        defaultMessage: 'Retrieve quote'
    },
    quoteNotFoundError: {
        id: 'quoteandbind.components.retrieveQuote.quoteNotFoundError',
        defaultMessage: 'Quote not found, please try again or start a new quote'
    },
    quoteDetails: {
        id: 'quoteandbind.components.retrieveQuote.quoteDetails',
        defaultMessage: 'Quote details',
    },
    postcode: {
        id: 'quoteandbind.components.retrieveQuote.postcode',
        defaultMessage: 'Postcode',
    },
    quoteReferenceNumber: {
        id: 'quoteandbind.components.retrieveQuote.quoteReferenceNumber',
        defaultMessage: 'Your quote reference number',
    },
    dateOfBirth: {
        id: 'quoteandbind.components.retrieveQuote.dateOfBirth',
        defaultMessage: 'Date of birth'
    }
});
