import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import {
    useErrorHandler,
    AppContext,
    QB_STEPS,
    VALIDATION_ERROR_CODE,
    BUILDINGS_ONLY,
    CONTENTS_ONLY,
    BUILDINGS_AND_CONTENTS
} from 'nfum-portals-utils-react';
import {
    useHistory
} from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import NGHContext from '../../NGHContext';
import commonMessages from '../../NGHWizard.messages';

import metadata from './ValuablesPage.metadata.json5';
import messages from './ValuablesPage.messages';
import styles from './ValuablesPage.module.scss';

import ValuablesCard from './ValuablesCard/ValuablesCard';
import ValuablesAwayCard from './ValuablesAwayCard/ValuablesAwayCard';
import ValuablesInfoCard from './ValuablesInfoCard/ValuablesInfoCard';
import SystemDownMessage from '../../components/SystemDownMessages/SystemDownMessage';
import ProtectingYourValuables from './ProtectingYourValuables/ProtectingYourValuables';
import useTagManager from '../../hooks/useTagManager';

const generateLineCoveragesPath = (submissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(submissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.publicID === coverage
                || lobCoverage.displayName === coverage
                || lobCoverage.name === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const generateHighValueItemsPath = (submissionVM) => {
    const lobSchedulesPath = 'lobData.homeLine.lineCoverages.schedules.value';
    const lobScheduleItemsPath = generateLineCoveragesPath(submissionVM)(lobSchedulesPath)('ScheduleItems');
    return lobScheduleItemsPath;
};

const generateReplacementCostPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsCov');
    const lobAccidentalCoverPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsCovValuablesReplacmntCostLvlLimit');
    return lobAccidentalCoverPath;
};

const generateDoesReplacementCostExceedLimitPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsCov');
    const lobDoesReplacementCostExceedLimitPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsCovReplacementCostExceedLimit');
    return lobDoesReplacementCostExceedLimitPath;
};

const generateUnspecifiedValuablesPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const unspecifiedBelongingsName = 'HOMContentsAwayCovUnspecifiedBelongingsReplCost';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsAwayCov');
    return generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)(unspecifiedBelongingsName);
};

function ValuablesPage(props) {
    const { wizardData: submissionVM, updateWizardData, goNext } = props;
    const history = useHistory();
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { handleError, handleUWError } = useErrorHandler();
    const { setIsMarketingQuoteLoadingView } = useContext(AppContext);
    const translator = useContext(TranslatorContext);
    const {
        initialValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('ValuablesPage');
    const MARKETING_LOADING_VIEW_DISPLAY_TIME_MS = 5000;
    const RATING_LOADING_TIME_MS = _.get(submissionVM.value, 'baseData.ratingServiceTimeOut_NFUM') * 1000;
    const MAX_PREMIUM_ALLOWED = _.get(submissionVM.value, 'baseData.maxPremiumAllowedToProceed_NFUM');
    const coverageValue = _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables');
    const { pushFormStepInfo, pushFormStepErrorInfo } = useTagManager();
    const [isLimitsValidationError, setIsLimitsValidationError] = useState(false);
    const { setIsNavigationDisabled } = useContext(NGHContext);
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    const setLimitsValidationError = useCallback((isError) => {
        setIsLimitsValidationError(isError);
        setIsNavigationDisabled(isError);
    }, [setIsLimitsValidationError, setIsNavigationDisabled]);

    useEffect(() => {
        const { isSkipping } = props;
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, QB_STEPS.VALUABLES);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, QB_STEPS.VALUABLES);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSubmissionQuotedOrBuildingsOnly = useCallback(() => {
        return ['Quoted', 'Bound'].includes(_.get(submissionVM.value, 'baseData.periodStatus'))
            || coverageValue === BUILDINGS_ONLY;
    }, [submissionVM, coverageValue]);

    useEffect(() => {
        registerInitialComponentValidation(isSubmissionQuotedOrBuildingsOnly);
    }, [isSubmissionQuotedOrBuildingsOnly, registerInitialComponentValidation]);

    useEffect(() => {
        if (coverageValue === BUILDINGS_ONLY) {
            goNext();
        }
    }, [submissionVM, goNext, coverageValue]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            _.set(submissionVM.value, changedPath, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const highValueItemsPath = generateHighValueItemsPath(submissionVM);
    const replacementCostPath = generateReplacementCostPath(submissionVM);
    const doesReplacementCostExceedLimitPath = generateDoesReplacementCostExceedLimitPath(
        submissionVM
    );
    const unspecifiedValuablesPath = generateUnspecifiedValuablesPath(submissionVM);
    const highValueBicyclesPath = generateHighValueItemsPath(submissionVM);

    const paths = {
        highValueItemsPath,
        replacementCostPath,
        doesReplacementCostExceedLimitPath,
        unspecifiedValuablesPath,
        highValueBicyclesPath
    };

    const getReferralMessageHeader = () => {
        return translator(messages.getInTouch);
    };

    const getReferralMessageParagraph1 = () => {
        if (isLimitsValidationError === true) {
            return translator(messages.limitsValidationErrorTextParagraph1);
        }
        return '';
    };

    const getReferralMessageParagraph2 = () => {
        if (isLimitsValidationError === true) {
            return translator(messages.limitsValidationErrorTextParagraph2);
        }
        return '';
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            valuablesCard: (valuablesCardProps) => (
                <ValuablesCard
                    {...valuablesCardProps}
                    wizardData={submissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                />
            ),
            valuablesAwayCard: (valuablesAwayCardProps) => (
                <ValuablesAwayCard
                    {...valuablesAwayCardProps}
                    wizardData={submissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                />
            ),
            systemDownErrorMessage: SystemDownMessage,
            protectingYourValuables: ProtectingYourValuables,
            valuablesInfoCard: ValuablesInfoCard
        }
    };
    /* US382672 Added promise with timeout for Quote Submission*/
    const promiseWithTimeout = useCallback((promise) => {
        let timeoutId;
        const timeoutPromise = new Promise((reject) => {
            timeoutId = setTimeout(() => {
                reject(new Error(translator(messages.ratingServiceTimedOut)));
            }, RATING_LOADING_TIME_MS);
        });
        return {
            promiseOrTimeout: Promise.race([promise, timeoutPromise]),
            timeoutId,
        };
    }, [RATING_LOADING_TIME_MS, translator]);

    const isPriorClaimsValid = useCallback(() => {
        const cover = _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables');
        const priorClaims = _.get(submissionVM, 'lobData.value.homeLine.priorClaims_NFUM');
        if (priorClaims.length > 0
            && (cover === CONTENTS_ONLY || cover === BUILDINGS_AND_CONTENTS)) {
            return false;
        }
        return true;
    }, [submissionVM]);

    const onNext = useCallback(async () => {
        const isFrauded = _.get(submissionVM, 'baseData.value.sirafraudCheckStatus_NFUM');
        if (isFrauded === true) {
            pushFormStepErrorInfo(submissionVM, QB_STEPS.VALUABLES);
            return history.push('/security-check');
        }
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const saveAndQuotePromise = LoadSaveService.saveAndQuoteSubmission(
            newSubmissionVM.value,
            authHeader
        );
        const { promiseOrTimeout, timeoutId } = promiseWithTimeout(saveAndQuotePromise);
        try {
            setIsMarketingQuoteLoadingView(true);
            const someSecondsDelayPromise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, MARKETING_LOADING_VIEW_DISPLAY_TIME_MS);
            });
            const results = await Promise.all([promiseOrTimeout, someSecondsDelayPromise]);
            setIsMarketingQuoteLoadingView(false);
            if (isPriorClaimsValid() === false) {
                pushFormStepErrorInfo(submissionVM, QB_STEPS.VALUABLES);
                handleUWError({}, newSubmissionVM.value.quoteID);
            }
            if (results[0].message === translator(messages.ratingServiceTimedOut)) {
                pushFormStepErrorInfo(submissionVM, QB_STEPS.VALUABLES);
                return history.push('/service-unavailable');
            }
            const [submissionVMResult] = results;
            newSubmissionVM.value = submissionVMResult;
            const calculatedPremium = _.get(newSubmissionVM.value, 'quoteData.offeredQuotes[0].premium.total.amount');
            if (calculatedPremium > MAX_PREMIUM_ALLOWED) {
                pushFormStepErrorInfo(submissionVM, QB_STEPS.VALUABLES);
                return history.push('/security-check');
            }
            return newSubmissionVM;
        } catch (error) {
            setIsMarketingQuoteLoadingView(false);
            if (error?.appErrorCode === VALIDATION_ERROR_CODE) {
                pushFormStepErrorInfo(submissionVM, QB_STEPS.VALUABLES, error);
                setLimitsValidationError(true);
                submissionVM.value = error.appData;
                updateWizardData(submissionVM);
            } else {
                pushFormStepErrorInfo(submissionVM, QB_STEPS.VALUABLES, error);
                handleError(error, submissionVM.value.quoteID);
            }
            return false;
        } finally {
            clearTimeout(timeoutId);
        }
    }, [
        history,
        LoadSaveService,
        authHeader,
        submissionVM,
        viewModelService,
        handleError,
        setIsMarketingQuoteLoadingView,
        pushFormStepErrorInfo,
        updateWizardData,
        setLimitsValidationError,
        MAX_PREMIUM_ALLOWED,
        promiseWithTimeout,
        translator,
        isPriorClaimsValid,
        handleUWError,
    ]);

    const isProtectingYourValuablesVisible = _.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.securityQuestions')
        && (_.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.securityQuestions.isMinSecurityQuestionExists.value') === true
        || _.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.securityQuestions.isAlarmSecurityQuestionExists.value') === true
        || _.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.securityQuestions.isSafeConditionExists.value') === true);

    const overrideProps = {
        valuablesInfoCard: {
            data: submissionVM
        },
        referralMessageContainer: {
            visible: isLimitsValidationError === true
        },
        referralMessage: {
            title: getReferralMessageHeader(),
            paragraph1: getReferralMessageParagraph1(),
            paragraph2: getReferralMessageParagraph2(),
            isUnderlineTheme: true,
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        protectingYourValuablesCardWrapper: {
            visible: isProtectingYourValuablesVisible === true
        },
        protectingYourValuables: {
            onValueChange: handleValueChange,
            isDisabled: isLimitsValidationError
        }
    };

    return (
        <WizardPage
            showCancel={false}
            onNext={onNext}
            skipWhen={initialValidation}
            nextLabel={commonMessages.continue}
            previousLabel={commonMessages.back}
            showPrevious
            disablePrevious={isLimitsValidationError === true}
            showNext
            disableNext={isLimitsValidationError === true}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                overrideProps={overrideProps}
            />
        </WizardPage>
    );
}

ValuablesPage.propTypes = wizardProps;
export default ValuablesPage;
