import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { WizardContext } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from 'gw-platform-translations';
import { Icon } from '@jutro/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import stepsConfig from './config/steps-config';
import styles from './ProgressBar.module.scss';
import messages from './ProgressBar.messages';

const ProgressBar = (props) => {
    const wizardContext = useContext(WizardContext);
    const { wizardData } = wizardContext;
    const translator = useContext(TranslatorContext);
    const {
        steps: wizardSteps, currentStepIndex
    } = wizardContext;
    const { customMessageModal, isNavigationDisabled } = props;
    const [cursor, setCursor] = useState(0);

    const jumpRenderModal = (index, isJumpPossible) => {
        if (!isJumpPossible) {
            return;
        }
        const modalMessages = {
            title: !_.isEmpty(customMessageModal)
                ? customMessageModal.title
                : commonMessages.wantToJump,
            message: !_.isEmpty(customMessageModal)
                ? customMessageModal.message
                : commonMessages.wantToJumpMessage,
            messageProps: {
                ok: commonMessages.yes,
                close: commonMessages.close
            }
        };
        const { wizardSnapshot, onPageJump } = wizardContext;

        onPageJump({
            wizardData,
            wizardSnapshot,
            modalMessages,
            index
        });
    };

    const resolveIfStepVisited = (processedStep) => {
        let visited = false;
        processedStep.relatedWizardStepIds.forEach((stepId) => {
            if (wizardSteps[stepId]?.visited) {
                visited = true;
            }
        });
        return visited;
    };
    const handleDisable = (stepId) => {
          if (currentStepIndex === 6 && stepId < 6) {
            return true;
          }
          return isNavigationDisabled;
      };
    const handleStyleActive = (stepId, isActiveStep) => {
        if (currentStepIndex === 6 && stepId < 6) {
          return true;
           }
          return isActiveStep;
      };
    const handleStyleNotVisited = (stepId, isVisitedStep) => {
          if (currentStepIndex === 6 && stepId < 6) {
            return true;
          }
          return !isVisitedStep;
      };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft' && cursor > 0) {
            setCursor(cursor - 1);
        } else if (e.key === 'ArrowRight' && cursor < stepsConfig.length - 1) {
            setCursor(cursor + 1);
        } else if (e.key === 'Tab') {
            setCursor(0);
        }
    };

    const renderSteps = () => {
        return stepsConfig.map((step, index) => {
            if (step.progressInfoId) return null;
            const isActiveStep = step.relatedWizardStepIds.includes(currentStepIndex);
            const isVisitedStep = resolveIfStepVisited(step);
            const isVisitedAndNotActiveStep = isVisitedStep && !isActiveStep;
            const navStepClassName = classNames(styles.navigationButton, {
              [styles.active]: handleStyleActive(step.stepId, isActiveStep),
              [styles.notVisited]: handleStyleNotVisited(step.stepId, isVisitedStep),
              [styles.disabled]: handleDisable(step.stepId),
            });
            const firstStepOfRelatedOnes = step.relatedWizardStepIds[0];
            return (
                <button
                    className={cursor === index ? styles.tabKeyArrows : navStepClassName}
                    type="button"
                    disabled={handleDisable(step.stepId)}
                    onKeyDown={handleKeyDown}
                    tabIndex={isActiveStep ? '0' : '-1'}
                    key={step.stepId}
                    aria-labelledby={`${step.title.defaultMessage.replace(/\s+/g, '-')}`}
                    onClick={() => jumpRenderModal(
                        firstStepOfRelatedOnes,
                        isVisitedAndNotActiveStep
                    )}
                >
                    <div className={styles.stepIcon}>
                        { isVisitedAndNotActiveStep && <Icon icon="gw-check" /> }
                        { (!isVisitedStep || isActiveStep) && <span>{step.stepId}</span> }
                    </div>
                    <div className={styles.stepTitle}>
                        {translator(step.title)}
                    </div>
                </button>
            );
        });
    };

    const renderCurrentTitle = () => {
        let title = '';
        stepsConfig.forEach((step) => {
            if (step.relatedWizardStepIds.includes(currentStepIndex)) {
                title = step.title;
            }
        });
        return translator(title);
    };

    const getCurrentStepId = () => {
        let title = '';
        stepsConfig.forEach((step) => {
            if (step.relatedWizardStepIds.includes(currentStepIndex)) {
                title = step.stepId;
            }
        });
        return title;
    };

    const getCurrentProgressInfoId = () => {
        let progressInfoId;
        stepsConfig.forEach((step) => {
            if (step.relatedWizardStepIds.includes(currentStepIndex)) {
                progressInfoId = step.progressInfoId;
            }
        });
        return progressInfoId;
    };

    const numberOfSteps = stepsConfig.length;
    return (
        <div className={styles.container}>
            <nav className={styles.progressBarContainer}>
                <div className={styles.progressBarHeader}>
                    <div className={styles.progressBarTitle}>
                        <h2>{renderCurrentTitle()}</h2>
                    </div>
                    { wizardData.value.quoteID && (
                        <div className={styles.quoteReference}>
                            <span>
                                {translator(messages.quoteReference)}
                                {wizardData.value.quoteID}
                            </span>
                        </div>
                    )}
                </div>
                { !getCurrentProgressInfoId() && (
                    <div>
                        <div className={styles.progressBar}>
                            {renderSteps()}
                        </div>
                        <div className={styles.progressInfo}>
                            <div className={styles.stepInfoIcon}>
                                <span>{getCurrentStepId()}</span>
                            </div>
                            <span className={styles.stepInfoText}>
                                {translator(messages.step)}
                                {getCurrentStepId()}
                                {translator(messages.stepOf)}
                                {numberOfSteps}
                            </span>
                        </div>
                    </div>
                )}
            </nav>
        </div>
    );
};

ProgressBar.propTypes = {
    customMessageModal: PropTypes.shape({})
};
ProgressBar.defaultProps = {
    customMessageModal: undefined
};
export default ProgressBar;
