import { defineMessages } from 'react-intl';

export default defineMessages({
    singleArticleLimitValidationMessage: {
        id: 'quoteandbind.ngh.views.valuables.singleArticleLimitValidationMessage',
        defaultMessage: 'Only items valued more than { limit } need to be declared'
    },
    valuablesModalTitle: {
        id: 'quoteandbind.ngh.views.valuables.valuablesModalTitle',
        defaultMessage: 'Specified valuables'
    },
    valuablesModalTerms: {
        id: 'quoteandbind.ngh.views.valuables.valuablesModalTerms',
        defaultMessage: "We use the term 'valuables' to cover jewellery, medals, coins and works of art as valuables * (Full list here). Think about what's in your drawers and cupboards then estimate how much it would to cost replace all these types of items. We're interested in items worth more than £x,000."
    },
    valuableType: {
        id: 'quoteandbind.ngh.views.valuables.valuableType',
        defaultMessage: 'Valuable type'
    },
    valuableTypeTooltip: {
        id: 'quoteandbind.ngh.views.valuables.valuableType.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    valuableDescription: {
        id: 'quoteandbind.ngh.views.valuables.valuableDescription',
        defaultMessage: 'Description of the item'
    },
    valuableDescriptionTooltip: {
        id: 'quoteandbind.ngh.views.valuables.valuableDescription.tooltip',
        defaultMessage: 'Please provide as much detail as possible here including the make and model of the item you wish to insure. An accurate and specific physical description helps us deal with claims promptly'
    },
    valuableReplacementCost: {
        id: 'quoteandbind.ngh.views.valuables.valuableReplacementCost',
        defaultMessage: 'Replacement cost'
    },
    valuableStorageTypeTooltip: {
        id: 'quoteandbind.ngh.views.valuables.valuableStorageTypeTooltip',
        defaultMessage: 'We need to know if the valuable is held securely in a bank vault or security deposit box'
    },
    valuableReplacementCostTootltip: {
        id: 'quoteandbind.ngh.views.valuables.valuableReplacementCost.tooltip',
        defaultMessage: 'Remember this is the cost to replace the item new rather than its current value'
    },
    valuableCoverType: {
        id: 'quoteandbind.ngh.views.valuables.valuableCoverType',
        defaultMessage: 'Select cover type'
    },
    valuableCoverTypeTooltip: {
        id: 'quoteandbind.ngh.views.valuables.valuableCoverType.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    insideHomeOnly: {
        id: 'quoteandbind.ngh.views.valuables.valuableCoverType.insideHomeOnly',
        defaultMessage: 'Inside the home only'
    },
    bothInsideOutsideHome: {
        id: 'quoteandbind.ngh.views.valuables.valuableCoverType.bothInsideOutsideHome',
        defaultMessage: 'Both inside and outside the home'
    },
    fullListHere: {
        id: 'quoteandbind.ngh.views.valuables.fullListHere',
        defaultMessage: 'Full list here'
    }
});
