import { TranslatorContext } from '@jutro/locale';
import React, { useContext } from 'react';
import { AppContext } from 'nfum-portals-utils-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { BridgeLoadingBox, cmsComponents } from 'nfum-cms-react';
import { NfumLoader } from 'nfum-components-platform-react';
import styles from './QuoteLoadingView.module.scss';
import messages from './QuoteLoadingView.messages';

const QuoteLoadingView = (props) => {
    const translator = useContext(TranslatorContext);
    const { env } = appConfig;
    const { isMarketingInfo } = props;
    const { marketingInfo } = useContext(AppContext);
    const QUOTE_PAGE_ID = 'quote';

    const bridgeLoadingBoxes = marketingInfo && marketingInfo[QUOTE_PAGE_ID]?.pageBlocks
        ?.filter((block) => block.contentType.includes(cmsComponents.LOADING_BOX));

    return (
        <div className={styles.backgroundContainer}>
            <div className={styles.infoContainer}>
                <h2>{translator(messages.gatheringQuoteDetails)}</h2>
                <NfumLoader />
                { !isMarketingInfo
                    && (
                        <div className={styles.infoBox}>
                            <h5>{translator(messages.updatingPrices)}</h5>
                        </div>
                    )
                }
                { isMarketingInfo && marketingInfo && bridgeLoadingBoxes
                    && bridgeLoadingBoxes.map((imageLink, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className={styles.marketingInfoBox} key={index}>
                                <BridgeLoadingBox
                                    imageUrl={imageLink.image?.url ? `${env.NFUM_CMS_URL}${imageLink.image?.url}` : ''}
                                    headerText={imageLink.headerText}
                                    text={imageLink.text}
                                    imageAlt={imageLink.imageAlt}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default QuoteLoadingView;
