import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { SimpleDateField, Icon } from '@jutro/components';
import NfumFieldLabel from '../NfumFieldLabel/NfumFieldLabel';
import styles from './NfumSimpleDateField.module.scss';

function NfumSimpleDateField(props) {
    const {
        value,
        success,
        error,
        onValidationChange,
        className,
        onBlur
    } = props;
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);
    const simpleDateFieldContainerRef = useRef();
    const showError = error || !currentValidation;
    let icon;

    if (showError) {
        icon = 'gw-close';
    } else if (success || value) {
        icon = 'gw-check';
    } else {
        icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });

    const iconComponent = <Icon icon={icon} className={iconClass} />;
    const inputClass = classNames(
        styles.nfumInputField,
        className,
        showState && showError && styles.errored
    );

    /* nfum patch - placeholder attribute is not working in GW SimpleDateField component */
    useEffect(() => {
        const monthInput = simpleDateFieldContainerRef.current.querySelector('[placeholder="MM"]');
        monthInput.setAttribute('placeholder', 'mm');
    }, []);

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const handleBlur = (event, data) => {
        if (onBlur) {
            onBlur(data.value, data.model);
        }
        if (data.value) {
            updateShowState(true);
        }
    };

    return (
        <div className={styles.nfumInputContainer}>
            <NfumFieldLabel
                {...props}
            />
            <div className={styles.nfumInput} ref={simpleDateFieldContainerRef}>
                <SimpleDateField
                    {...props}
                    className={inputClass}
                    onValidationChange={onValidate}
                    onBlur={handleBlur}
                    hideLabel="true"
                />
                {(showState) && iconComponent}
            </div>
        </div>
    );
}

export default NfumSimpleDateField;
