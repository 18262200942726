import { defineMessages } from 'react-intl';

export default defineMessages({
    transactionReference: {
        id: 'wizardHeader.transactionReference',
        defaultMessage: 'Transaction reference: '
    },
    policyNumber: {
        id: 'wizardHeader.policyNumber',
        defaultMessage: 'Policy number: '
    }
});
