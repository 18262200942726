import { defineMessages } from 'react-intl';

export default defineMessages({
    frequentlyAskedQuestions: {
        id: 'portal.index.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ',
    },
    checkAnswers: {
        id: 'portal.index.Check Answers',
        defaultMessage: 'Check Answers',
    },
    needAssistance: {
        id: 'portal.index.Need Assistance?',
        defaultMessage: 'Need Assistance?',
    },
    liveChatNow: {
        id: 'portal.index.Live Chat Now',
        defaultMessage: 'Live Chat Now',
    },
    furtherQuestions: {
        id: 'portal.index.Further Questions?',
        defaultMessage: 'Further Questions?',
    },
    privacyPolicy: {
        id: 'footer.left_items.Privacy Policy',
        defaultMessage: 'Privacy Policy',
    },
    legalNotes: {
        id: 'footer.left_items.Legal Notes',
        defaultMessage: 'Legal Notes',
    },
    copyRight: {
        id: 'footer.right_items.Copyright &copy; 2001-2019 Guidewire Software',
        defaultMessage: 'Copyright © 2001-2019 Guidewire Software'
    },
    home: {
        id: 'portal.index.Home',
        defaultMessage: 'Home'
    },
    callUs: {
        id: 'nfum.portal.index.Need help with this form? Call us',
        defaultMessage: 'Need help with this form? Call us'
    }
});
