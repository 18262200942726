import { defineMessages } from 'react-intl';

export default defineMessages({
    aboutYourHome: {
        id: 'quoteandbind.ngh.views.property-details.aboutTheBuilding',
        defaultMessage: 'About the building',
    },
    insideYourHome: {
        id: 'quoteandbind.ngh.views.property-details.aboutYourHome',
        defaultMessage: 'About your home',
    },
    aboutOutsideYourHome: {
        id: 'quoteandbind.ngh.views.property-details.aboutOutsideYourHome',
        defaultMessage: 'About the outside of your home',
    },
    homeType: {
        id: 'quoteandbind.ngh.views.property-details.home-type',
        defaultMessage: 'What type of property is your home?',
    },
    homeTypeTooltip: {
        id: 'quoteandbind.ngh.views.property-details.homeTypeTooltip',
        defaultMessage: 'We are unable to insure estates and farmer\'s huts under this Home Insurance Policy'
    },
    propertyType: {
        id: 'quoteandbind.ngh.views.property-details.property-type',
        defaultMessage: 'What best describes the property?',
    },
    bedrooms: {
        id: 'quoteandbind.ngh.views.property-details.bedrooms',
        defaultMessage: 'How many bedrooms does your home have?',
    },
    bedroomsRemember: {
        id: 'quoteandbind.ngh.views.property-details.bedroomsRemember',
        defaultMessage: 'Remember to include all bedrooms even those that are used as an office, study or home gym',
    },
    rooms: {
        id: 'quoteandbind.ngh.views.property-details.rooms',
        defaultMessage: 'How many rooms, not including your bedrooms, does your home have? E.g. bathrooms, living rooms, kitchen ',
    },
    yearBuilt: {
        id: 'quoteandbind.ngh.views.property-details.year-built',
        defaultMessage: 'Approximately what year was your home built?',
    },
    yearBuiltDeeds: {
        id: 'quoteandbind.ngh.views.property-details.year-built-deeds',
        defaultMessage: 'This can be found on the property deeds or your previous insurance documents',
    },
    yearBuiltTooltip: {
        id: 'quoteandbind.ngh.views.property-details.yearBuiltTooltip',
        defaultMessage: 'When telling us when your home was built, it\'s important that you are as accurate as you can be. You should be able to find the date your home was built on your property deeds or estate agent particulars. If you don\'t have these to hand, please estimate as best you can'
    },
    propertyListedTooltip: {
        id: 'quoteandbind.ngh.views.property-details.propertyListedTooltip',
        defaultMessage: 'A listed building is a building of particular historical or architectural significance which has been registered as a listed building by the appropriate local authority'
    },
    HomeOccupancyTooltip: {
        id: 'quoteandbind.ngh.views.property-details.HomeOccupancyTooltip',
        defaultMessage: 'By this we mean more than 50% in the average week. By occupied we mean any member of the household being at home'
    },
    PropertyListed: {
        id: 'quoteandbind.ngh.views.property-details.listed-building',
        defaultMessage: 'Is the property a listed building?',
    },
    HomeOccupancy: {
        id: 'quoteandbind.ngh.views.property-details.home-occupied',
        defaultMessage: 'When is your home usually occupied?',
    },
    IConfirm: {
        id: 'quoteandbind.ngh.views.property-details.iConfirm',
        defaultMessage: 'I confirm',
    },
    IDoNotConfirm: {
        id: 'quoteandbind.ngh.views.property-details.iDoNotConfirm',
        defaultMessage: 'I do not confirm',
    },
    ConfirmCancelMessage: {
        id: 'quoteandbind.ngh.views.property-details.confirmCancelMessage',
        defaultMessage: 'The garden or land I  own is not:',
    },
    referralTitle: {
        id: 'quoteandbind.ngh.views.property-details.referralTitle',
        defaultMessage: 'We think you would benefit from something more tailored',
    },
    referralDescription: {
        id: 'quoteandbind.ngh.views.property-details.referralDescription',
        defaultMessage: 'Sorry, based on the information you have given us, we are unable to provide a quote online. Please contact us using one of the following options and we can support you with your Home Insurance quote.',
    },
    ConstructionMaterials: {
        id: 'quoteandbind.views.ngh.property-details.constructionMaterials',
        defaultMessage: 'Construction materials',
    },
    WallMaterial: {
        id: 'quoteandbind.views.ngh.property-details.wallMaterial',
        defaultMessage: 'What material are the walls mostly made of?',
    },
    wallMaterialTooltip: {
        id: 'quoteandbind.views.ngh.property-details.wallMaterialTooltip',
        defaultMessage: 'This is the majority of your walls - i.e. at least 50% of your home'
    },
    RoofMaterial: {
        id: 'quoteandbind.views.ngh.property-details.roofMaterial',
        defaultMessage: 'What material is the roof mostly made of?',
    },
    RoofMaterialTooltip: {
        id: 'quoteandbind.views.ngh.property-details.RoofMaterialTooltip',
        defaultMessage: 'This is the majority of your roof - i.e. at least 50% of your home'
    },
    WallRoofType: {
        id: 'quoteandbind.views.ngh.property-details.wallRoofType',
        defaultMessage: 'We’re assuming your home has brick walls and a tile roof. Is this correct?'
    },
    wallRoofTypeTooltip: {
        id: 'quoteandbind.views.ngh.property-details.wallRoofTypeTooltip',
        defaultMessage: 'This is the majority of your walls and roof - i.e at least 50% of your home'
    },
    BasementOrCellar: {
        id: 'quoteandbind.ngh.views.property-details.basementOrCellar',
        defaultMessage: 'Does your home have a basement or cellar?',
    },
    SampleToolTip: {
        id: 'quoteandbind.ngh.views.property-details.sampleTooltip',
        defaultMessage: 'This is an informational tooltip',
    },
    RuralPursuitInfo1: {
        id: 'quoteandbind.ngh.views.property-details.ruralPursuitInfo1',
        defaultMessage: 'More than 1 acre in size',
    },
    RuralPursuitInfo2: {
        id: 'quoteandbind.ngh.views.property-details.ruralPursuitInfo2',
        defaultMessage: 'Let to another individual or organisation',
    },
    RuralPursuitInfo3: {
        id: 'quoteandbind.ngh.views.property-details.ruralPursuitInfo3',
        defaultMessage: 'Land located away from my home (e.g a parcel of land over the road or elsewhere)',
    }
});
