import _ from 'lodash';

function isEmpty(value) {
    return _.isNil(value) || ((_.isArray(value) || _.isObject(value)) && _.isEmpty(value));
}

/**
 * Checks if the segmentation option matches the chosen variant
 *
 * @param {*} optionValue
 * @param {*} variant
 *
 * @returns {Boolean}
 */
export default (optionValue, variant) => {
    if (isEmpty(optionValue) || isEmpty(variant)) {
        return false;
    }
    if (_.isString(variant)) {
        if (_.isArray(optionValue)) {
            return optionValue.some((option) => {
                return _.isEqual(option, variant);
            });
        }
        return _.isEqual(optionValue, variant);
    }
    if (_.isNumber(variant)) {
        if (_.isArray(optionValue)) {
            return optionValue.some((option) => {
                return _.isEqual(parseInt(option, 10), variant);
            });
        }
        return _.isEqual(_.toInteger(optionValue), variant);
    }
    if (_.isObject(variant)) {
        if (_.isArray(optionValue)) {
            return optionValue.some((option) => {
                return _.isMatch(option, variant);
            });
        }
        // all option property values must match variant object properties
        return _.isMatch(variant, optionValue);
    }

    if (_.isArray(variant) && _.isArray(optionValue)) {
        return optionValue.every((option) => variant.indexOf(option) !== -1);
    }

    return false;
};
