import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './ContentsSummary.messages';
import metadata from './ContentsSummary.metadata.json5';
import styles from './ContentsSummary.module.scss';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import useLobs from '../../../hooks/useLobs';

function ContentsSummary(props) { /* NOSONAR: pure declarative usage */
    const {
        homeLine,
        onClickSeeDocuments,
        submissionVM,
    } = props;
    const translator = useContext(TranslatorContext);
    const { isBespokeJourney } = useLobs(submissionVM);
    const { getPropertyTitle } = usePropertyTitle();
    const contentsIds = Object.freeze({
        HOM_BUILDINGS_COV: 'HOMBuildingsCov',
        HOM_CONTENTS_COV: 'HOMContentsCov',
        HOM_CONTENTS_COV_ACCIDENTAL_DAMAGE: 'HOMContentsCovAccidentalDamageCover',
        HOM_CONTENTS_COV_VOLUNTARY_EXCESS: 'HOMContentsCovVoluntaryExcess',
        HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT: 'HOMContentsCovContentsLevelLimit',
        HOM_CONTENTS_COV_TENANTS_INTERIOR_DECORATION: 'HOMContentsCovTenantsInterior',
        HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_TOTAL: 'HOMContentsCovContentsEscapeOfWaterExcessTot',
        HOM_CONTENTS_COV_SUBSIDENCE_EXCESS: 'HOMContentsCovSubsidenceExcess',
        HOM_CONTENTS_COV_FLOOD_TOTAL_EXCESS: 'HOMContentsCovFloodTotalExcess',
        HOM_CONTENTS_AWAY_COV: 'HOMContentsAwayCov',
        HOM_CONTENTS_AWAY_COV_UNSPC_BELONGINGS_REPL_COST: 'HOMContentsAwayCovUnspecifiedBelongingsReplCost',
        HOM_CONTENTS_AWAY_COV_UNSPC_PEDAL_CYCLES_LIMIT: 'HOMContentsAwayCovUnspecifiedPedalCyclesLimit',
        HOM_CONTENTS_AWAY_COV_UNSPC_BELONGINGS: 'HOMContentsAwayCoverReqForUnspecBelongings',
        HOM_CONTENTS_COV_ALL_OTHER_CLAIMS_EXCESS: 'HOMContentsCovAllOtherClaimsExcess',
        HOM_CONTENTS_COV_FLOOD_EXCESS_ADJUSTMENT: 'HOMContentsCovFloodExcessAdjustment',
        HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_ADJ: 'HOMContentsCovContentsEscapeOfWaterExcessAdj',
        HOM_CONTENTS_COV_SUBSIDENCE_EXCESS_ADJ: 'HOMContentsCovSubsidenceExcessAdj',
        HOM_CONTENTS_COV_SPECIFIED_VALUABLES_EXCESS_ADJUST: 'HOMContentsCovSpecifiedValuablesExcessAdjust',
        HOM_CONTENTS_COV_STORM_EXCESS_ADJUSTMENT: 'HOMContentsCovStormExcessAdjustment',
        HOM_CONTENTS_COV_FIRE_EXCESS_ADJUSTMENT: 'HOMContentsCovFireExcessAdjustment',
        HOM_CONTENTS_COV_THEFT_EXCESS_ADJUSTMENT: 'HOMContentsCovTheftExcessAdjustment',
        HOM_CONTENTS_COV_LEAKING_OIL_EXC_ADJ: 'HOMContentsCovLeakingOilExcAdj',
        HOM_CONTENTS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ: 'HOMContentsCovFallingTreesAndBranchesExcAdj',
        HOM_CONTENTS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ: 'HOMContentsCovFallingAerialsTurbinesExcAdj',
        HOM_CONTENTS_COV_CONTENT_COLLISION_VEH_EXCESS_ADJ: 'HOMContentsCovContentCollisionVehExcessAdj',
        HOM_CONTENTS_COV_CONTENTS_VANDALS_EXCESS_ADJUST: 'HOMContentsCovContentsVandalsExcessAdjust',
        HOM_CONTENTS_COV_RIOT_EXCESS_ADJ: 'HOMContentsCovRiotExcessAdj',
        HOM_CONTENTS_COV_AIRCRAFT_EXCESS_ADJ: 'HOMContentsCovAircraftExcessAdj',
        HOM_CONTENTS_COV_REPLACEMENT_COST: 'HOMContentsCovContentsPersoBelongReplacementCost',
        HOM_CONTENTS_COV_INTERIOR_DECORATION_COST: 'HOMContentsCovTenantsInterior',
        HOME_CONTENT_COV_TENANTS_REPLACEMENT_COST: 'HOMContentsCovTenantsReplacementCost',
        HOMContentsCovAccidentalLossOrDamageExcess: 'HOMContentsCovAccidentalLossOrDamageExcess',
        HOMContentsCovOtherVoluntaryExcess: 'HOMContentsCovOtherVoluntaryExcess',
        HOMContentsCovSubsidenceTotalExcess: 'HOMContentsCovSubsidenceTotalExcess'
    });
    const YES_KEY = 'Yes';
    const LEVEL3_COVER = 'HOMContentsCovContentsLevelLimit150000gbp';

    const isBespokeSustainedDmg = submissionVM.lobData.homeLine.value.isBespokeSustainedDmg;
    const isBespokeFloodRisk =  submissionVM.lobData.homeLine.value.isBespokeFloodRisk;

    const getTermValue = useCallback((cov, termId) => {
        return cov?.terms
            ?.find((term) => term.publicID === termId)
            ?.chosenTermValue;
    }, []);

    const getChosenTerm = useCallback((cov, termId) => {
        return cov?.terms
            ?.find((term) => term.publicID === termId)
            ?.chosenTerm;
    }, []);

    const getTermLimitValue = useCallback((cov, termPublicID, currSymbol = true) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        return currSymbol ? `£${chosenTermValue}` : chosenTermValue;
    }, []);

    const contentsCov = homeLine?.lineCoverages?.coverages
        ?.find(((cov) => cov.publicID === contentsIds.HOM_CONTENTS_COV));

    const isAnyAdjustomentsCovTermValue = useCallback(() => {
        const arr = [
            contentsIds.HOM_CONTENTS_COV_FLOOD_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_SUBSIDENCE_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_SPECIFIED_VALUABLES_EXCESS_ADJUST,
            contentsIds.HOM_CONTENTS_COV_STORM_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_FIRE_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_THEFT_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_LEAKING_OIL_EXC_ADJ,
            contentsIds.HOM_CONTENTS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ,
            contentsIds.HOM_CONTENTS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ,
            contentsIds.HOM_CONTENTS_COV_CONTENT_COLLISION_VEH_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_CONTENTS_VANDALS_EXCESS_ADJUST,
            contentsIds.HOM_CONTENTS_COV_RIOT_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_AIRCRAFT_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_REPLACEMENT_COST,
            contentsIds.HOME_CONTENT_COV_TENANTS_REPLACEMENT_COST,
            contentsIds.HOMContentsCovAccidentalLossOrDamageExcess,
            contentsIds.HOMContentsCovOtherVoluntaryExcess,
            contentsIds.HOMContentsCovSubsidenceTotalExcess
        ];

        return arr.reduce((acc, currentCovTerm) => {
            const result = getTermLimitValue(
                contentsCov,
                currentCovTerm,
                false
            );
            return result && +result.replace(/,/g, '') > 0 ? true : acc;
        }, false);
    }, [contentsCov,
        contentsIds.HOMContentsCovAccidentalLossOrDamageExcess,
        contentsIds.HOMContentsCovOtherVoluntaryExcess,
        contentsIds.HOMContentsCovSubsidenceTotalExcess,
        contentsIds.HOME_CONTENT_COV_TENANTS_REPLACEMENT_COST,
        contentsIds.HOM_CONTENTS_COV_AIRCRAFT_EXCESS_ADJ,
        contentsIds.HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_ADJ,
        contentsIds.HOM_CONTENTS_COV_CONTENTS_VANDALS_EXCESS_ADJUST,
        contentsIds.HOM_CONTENTS_COV_CONTENT_COLLISION_VEH_EXCESS_ADJ,
        contentsIds.HOM_CONTENTS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ,
        contentsIds.HOM_CONTENTS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ,
        contentsIds.HOM_CONTENTS_COV_FIRE_EXCESS_ADJUSTMENT,
        contentsIds.HOM_CONTENTS_COV_FLOOD_EXCESS_ADJUSTMENT,
        contentsIds.HOM_CONTENTS_COV_LEAKING_OIL_EXC_ADJ,
        contentsIds.HOM_CONTENTS_COV_REPLACEMENT_COST,
        contentsIds.HOM_CONTENTS_COV_RIOT_EXCESS_ADJ,
        contentsIds.HOM_CONTENTS_COV_SPECIFIED_VALUABLES_EXCESS_ADJUST,
        contentsIds.HOM_CONTENTS_COV_STORM_EXCESS_ADJUSTMENT,
        contentsIds.HOM_CONTENTS_COV_SUBSIDENCE_EXCESS_ADJ,
        contentsIds.HOM_CONTENTS_COV_THEFT_EXCESS_ADJUSTMENT, getTermLimitValue]);

    const homeProperty = homeLine?.coverables?.homhomeProperty;
    const buildingsCov = homeLine?.lineCoverages?.coverages
        ?.find(((cov) => cov.publicID === contentsIds.HOM_BUILDINGS_COV));
    const isBuildingsCov = !!buildingsCov;
    const isContentsCov = !!contentsCov;
    const contentsAwayCov = homeLine?.lineCoverages?.coverages
        ?.find(((cov) => cov.publicID === contentsIds.HOM_CONTENTS_AWAY_COV));
    const isContentsAwayUnspcBelongings = contentsAwayCov ? getTermValue(contentsAwayCov,
        contentsIds.HOM_CONTENTS_AWAY_COV_UNSPC_BELONGINGS) === YES_KEY : false;
    const isContentsInteriorDecorationCost = contentsCov ? getTermValue(contentsCov,
        contentsIds.HOM_CONTENTS_COV_INTERIOR_DECORATION_COST) === YES_KEY : false;
    const accidental = contentsCov.terms.find((cov) => cov.publicID === contentsIds
        .HOMContentsCovAccidentalLossOrDamageExcess);
    const contentsInteriorDecorationCost = getTermLimitValue(
        contentsCov,
        contentsIds.HOME_CONTENT_COV_TENANTS_REPLACEMENT_COST,
        false
    );
    const handleSelectMessage = () => {
        return accidental ? translator(messages.included) : translator(messages.notSelected);
    };

    const contentsCovValue = useCallback(() => {
        if (isBespokeJourney) {
            return handleSelectMessage();
        }
        if (getTermValue(contentsCov, contentsIds.HOM_CONTENTS_COV_ACCIDENTAL_DAMAGE) === YES_KEY
        && getChosenTerm(contentsCov,
            contentsIds.HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT) === LEVEL3_COVER) {
            return translator(messages.includedAsStandard);
        }
        if (getTermValue(contentsCov, contentsIds.HOM_CONTENTS_COV_ACCIDENTAL_DAMAGE) === YES_KEY) {
            return translator(messages.added);
        }
        return translator(messages.notSelected);
    }, []);

    const handleConfirmedContainerVisibility = useCallback(() => {
        if (isBespokeJourney) {
            if (!isBuildingsCov) return true;
            return false;
        }
        return isContentsCov && !isBuildingsCov && homeLine?.isPropertyNotForBusinessUse
        && homeLine?.isPropertyNotImpactedByFloodAndSubsidence;
    }, [isBespokeJourney, isBuildingsCov, isContentsCov]);

    const displayConfirmationText = useCallback((confirmationTextNo, isConfirmed) => {
        if (isBespokeJourney) {
            if (isConfirmed) {
                return translator(messages[`${confirmationTextNo}Yes`]);
            }
            return translator(messages[`${confirmationTextNo}No`]);
        }
        return translator(messages[`${confirmationTextNo}No`]);
    }, [isBespokeJourney, translator]);

    const displayVoluntary = () => {
        const termValue = getTermValue(
            contentsCov,
            contentsIds.HOM_CONTENTS_COV_VOLUNTARY_EXCESS
        );
        if (termValue === 'Other') {
            return getTermLimitValue(
                contentsCov,
                contentsIds.HOMContentsCovOtherVoluntaryExcess
            );
        } else {
            return getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_VOLUNTARY_EXCESS
            )
        }
    };

    const displayNumberOfBedrooms = () => {
        if (homeProperty.homhomePropertyDetails.numberOfBedrooms === 'MoreThan8') {
            return translator(messages.moreThanEight);
        } else {
           return homeProperty.homhomePropertyDetails.numberOfBedrooms;
        }
    };

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        contentsHomePropertyTitle: {
            content: getPropertyTitle(homeProperty, !isBuildingsCov)
        },
        noOfBedrooms: {
            visible: !isBuildingsCov,
            value: isBespokeJourney ? displayNumberOfBedrooms()
                : homeProperty.homhomePropertyDetails.numberOfBedrooms
        },
        contentsLevelLimit: {
            visible: !isBespokeJourney,
            value: getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT
            )
        },
        contentsReplacementCost: {
            visible: isBespokeJourney,
            value: getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_REPLACEMENT_COST
            )
        },
        contentsInteriorDecorationCost: {
            visible: !!contentsInteriorDecorationCost,
            value: `£${contentsInteriorDecorationCost}`,
        },
        contentsInteriorDecorationSelected: {
            visible: isContentsInteriorDecorationCost && !contentsInteriorDecorationCost,
            value: translator(messages.added),
        },
        contentsAccidentalDamage: {
            value: contentsCovValue()
        },
        contentsAwayLimit: {
            visible: !isBespokeJourney,
            value: isContentsAwayUnspcBelongings ? getTermLimitValue(
                contentsAwayCov,
                contentsIds.HOM_CONTENTS_AWAY_COV_UNSPC_BELONGINGS_REPL_COST
            ) : translator(messages.notSelected)
        },
        unspecifiedBicyclesLimit: {
            visible: !isBespokeJourney,
            value: isContentsAwayUnspcBelongings ? getTermLimitValue(
                contentsAwayCov,
                contentsIds.HOM_CONTENTS_AWAY_COV_UNSPC_PEDAL_CYCLES_LIMIT
            ) : translator(messages.notSelected)
        },
        confirmedContainer: {
            visible: handleConfirmedContainerVisibility(),
        },
        confirmationText2: {
            content: displayConfirmationText('confirmationText2', isBespokeSustainedDmg),
        },
        confirmationText3: {
            content: displayConfirmationText('confirmationText3', isBespokeFloodRisk),
        },
        confirmationText4: {
            visible: !isBespokeJourney,
        },
        confirmationText5: {
            visible: !isBespokeJourney,
        },
        leakingAndFrozenWater: {
            value: getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_TOTAL
            )
        },
        subsidence: {
            value: getTermLimitValue(
                contentsCov,
                contentsIds.HOMContentsCovSubsidenceTotalExcess
            )
        },
        flood: {
            value: getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_FLOOD_TOTAL_EXCESS
            )
        },
        voluntary: {
            value: isBespokeJourney ? displayVoluntary() : getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_VOLUNTARY_EXCESS
            )
        },
        compulsaryAllClaims: {
            value: getTermLimitValue(
                contentsCov,
                contentsIds.HOM_CONTENTS_COV_ALL_OTHER_CLAIMS_EXCESS
            ),
            visible: !isAnyAdjustomentsCovTermValue(contentsCov)
        },
        compulsaryAllClaimsWithSeeDocuments: {
            value: translator(messages.seeDocuments),
            visible: isAnyAdjustomentsCovTermValue(contentsCov)
        },
        seeDocuments: {
            onClick: onClickSeeDocuments
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default ContentsSummary;
